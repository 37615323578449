.justui_input-radioButton-container {
  margin: 0;
  min-height: 21px;
  min-width: 14px;

  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;


  --bright-color: var(--primary);
  --border-color: var(--grey-500);
  --shadow-dark-color: var(--primary);
  --text-color: #000;

  &.dark {
    --text-color: #fff;
    &:hover input ~ .justui_input-radioButton {
      box-shadow: 0px 0px 0 3px var(--shadow-dark-color);
    }

    &.label input:not(:checked) ~ .justui_input-radioButton{
      --border-color: #CFD8DC;
    }
  }

  .justui_input-radioButton-label {
    position: relative;
    left: 24px;
    line-height: 21px;
    color: var(--text-color);
  }

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .justui_input-radioButton {
    position: absolute;
    top: 3px;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: transparent;
    border-radius: 50%;

    border: 1px solid var(--border-color);
    transition: 300ms;
    will-change: background, border, box-shadow;
    &:after {
      transition: 300ms;
      will-change: background;
      background-color: transparent;
    }


  }


  .justui_input-radioButton:after {
    content: "";
    position: absolute;
    display: block;
    background-color: transparent;
  }

  & input:checked ~ .justui_input-radioButton {
    border: 1px solid var(--bright-color);
    &:after {
      background-color: var(--bright-color);
    }
  }

  & input:focus ~ .justui_input-radioButton {
    box-shadow: 0px 0px 0 1px var(--bright-color);
  }

  & .justui_input-radioButton:after {
    top: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  &.disabled {
    pointer-events: none;
    --bright-color: var(--grey-300);
    --border-color: var(--grey-300);
    --text-color: var(--grey-300);
    .justui_input-radioButton {
      background-color: var(--grey-100);
    }
    &.dark{
      --bright-color: #666666;
      --text-color: #666666;
      --border-color: #666666;

      &.label {
        --border-color: #CFD8DC;
        --bright-color: #83CCE7;
        &:checked{
          --border-color: #83CCE7;
        }
      }
    }

  }
}
