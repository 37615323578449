@import '../../../../scss/custom/mixins';
.public-voice {
  background-color: #fff;
  flex: 1;

  @include md-screen-down {
    padding-bottom: 7rem;
    &__back-link {
      padding: 1.5rem;
      background-color: white;
      z-index: 3;
      display: flex;
      align-items: center;
      a {
        flex: 1;
      }
      &::after {
        content: '';
        flex: 1;
      }
      @include phone-only {
        p {
          display: none;
        }
      }
    }
  }

  &__wrapper {
    margin: 0 auto;
    max-width: 1672px;
    padding: 0 2.275rem 8rem 2.25rem;
    display: flex;
    gap: 4rem;

    @include md-screen-down {
      flex-direction: column;
      padding: 0;
      gap: 0;
    }
    @include phone-only {
      gap: 0.5rem;
    }
    &.public-voice__versions {
      @include md-screen-down {
        padding-top: 0;
        margin-top: -80px;
      }
      @include md-screen-up {
        .voice-page__published {
          padding: 0 130px;
        }
      }
      @include tablet-down {
        margin-top: -56px;
        gap: 0.5rem;
      }
    }
  }

  &__column {
    height: 100%;
    &:nth-child(1) {
      max-width: 400px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      transition: opacity 0.2s;
      .voice-placeholder {
        background-color: white;
      }
      @include md-screen-down {
        max-width: 100%;
      }
    }
    &:last-of-type {
      flex: 0 1 65%;
      background-color: transparent;
      @include md-screen-down {
        padding: 1rem 2.25rem 0 2.25rem;
      }

      @include phone-only {
        padding: 0 1rem;
      }
    }
    @include phone-only {
      h3 {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }
  }

  &__voice {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background: var(--gray-100);
    margin-top: -1px;
    border-radius: 2rem;
    @include md-screen-down {
      padding: 2rem 36px;
    }
    @include tablet-down {
      padding: 2rem 24px 2rem 24px;
    }
    @include phone-only {
      padding: 2rem 1rem 2rem 1rem;

      .public-voice__popup {
        flex-direction: column;
        align-items: flex-start;
        bottom: -123px;
        .public-voice__token {
          width: 100%;
        }
      }
    }
  }

  &__photo {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    position: relative;
    img {
      width: 10.625rem;
      height: 10.625rem;
      border-radius: 50%;
    }
    @include phone-only {
      .voice-placeholder--main {
        width: 170px;
        height: 170px;
      }
    }
  }

  &__name {
    text-align: center;
    margin-bottom: 1rem;
  }
  &__price {
    display: flex;
    align-items: flex-end;
    gap: 6px;
    color: var(--gray-600);
    font-size: 1rem;
    h3 {
      color: black;
      line-height: 28px;
    }
    p {
      height: 100%;
      font-size: 1rem;
      line-height: 24px;
    }
  }
  &__token-container > div > button,
  &__project-button {
    padding: 1.5rem 2rem;
    width: 100%;
    background-color: rgba(255, 117, 58, 0.08);
    text-align: start;
    border-radius: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.2s;
    border: none !important;

    > div {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    p:first-of-type {
      text-align: start;
      font-size: 1rem;
      line-height: 1.5rem;
      color: var(--gray-950);
      font-weight: 600 !important;
    }

    &:focus {
      box-shadow: none;
    }
    &:hover {
      background-color: rgba(255, 117, 58, 0.16) !important;
      color: currentColor !important;
    }

    &:not(:hover) {
      background-color: rgba(255, 117, 58, 0.08) !important;
      color: currentColor !important;
    }

    svg {
      width: 1.5rem !important;
      height: 1.5rem !important;
    }

    @include md-screen-down {
      border-radius: 1rem;
    }

    @include phone-only {
      p:first-of-type {
        font-size: 14px;
      }
      p:last-of-type {
        font-size: 12px;
      }
      svg {
        width: 16px !important;
        height: 16px !important;
      }
    }
  }
  &__token-container {
    position: relative;
    width: 100%;
    > div {
      width: 100%;
    }

    @include md-screen-down {
      padding: 0 1.5rem;
    }
    @include phone-only {
      padding: 0 1rem;
    }
  }
  &__popup {
    position: absolute;
    z-index: 10001;
    bottom: -146px;
    left: 0;
    width: 100%;
    padding: 1rem 1.5rem 1.5rem;
    background-color: white;
    box-shadow: 0px 1px 20px rgba(44, 46, 113, 0.12);
    border-radius: 1.5rem;
    p:first-child {
      margin-bottom: 0.5rem;
    }
    &-doc {
      cursor: pointer;
      margin-top: 0.5rem !important;
      color: var(--gray-600) !important;
      text-decoration: underline !important;
    }

    @include md-screen-down {
      display: flex;
      padding: 1rem 1.5rem;
      border-radius: 1.5rem;
      align-items: baseline;
      bottom: -116px;
      width: calc(100% - 3rem);
      left: 1.5rem;

      p:first-child {
        margin-bottom: 0;
        margin-right: 2.5rem;
      }
    }

    @include phone-only {
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem;
      width: calc(100% - 2rem);
      left: 1rem;
      bottom: -148px;

      p:first-child {
        margin-right: 0;
        margin-bottom: 0.75rem;
      }
    }
  }
  &__token {
    display: flex;
    gap: 0.5rem;
    > button {
      padding-left: 15px !important;
      padding-right: 15px !important;
      border-radius: 0.25rem;
      &:focus {
        box-shadow: none;
      }

      svg {
        display: flex;
        width: 100%;
        height: 100%;
      }
    }
    input:focus {
      box-shadow: none;
    }
    input:read-only {
      background-color: white;
    }

    @include md-screen-down {
      &-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
      }
      flex: 1;
    }
  }

  &__project-button {
    width: auto;
    @include md-screen-down {
      margin: 0 1.5rem;
    }
    @include phone-only {
      margin: 0 1rem;
    }
  }
  &__connected {
    display: flex;
    gap: 10px;
    max-height: 0;
    opacity: 0;
    padding: 0rem 18px;
    visibility: hidden;
    align-items: center;
    background: var(--gray-100);
    border-radius: 1rem;
    transition: max-height 0.25s, padding 0.25s, visibility 0.25s;
    transition-timing-function: ease-in;
    &.open {
      max-height: 53px;
      padding: 1rem 18px;
      opacity: 1;
      visibility: visible;
    }
  }

  &__actual {
    margin-top: 3.25rem;
    display: flex;
    align-items: baseline;
    a {
      margin-left: auto;
      padding-top: 3px;
      color: var(--gray-600);
    }
  }
  &__actual-version {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    p {
      color: var(--gray-600);
    }

    @include phone-only {
      flex-direction: column;
      align-items: flex-start;
      p {
        white-space: nowrap;
      }
    }
  }

  &__actual-connected {
    padding: 2px 0.5rem;
    background-color: var(--primary-100);
    border-radius: 0.5rem;
  }

  &__desc {
    margin-top: 1rem;
    h3 {
      margin-bottom: 1rem;
    }
    p {
      margin-bottom: 1.5rem;
    }
    @include md-screen-down {
      margin-top: 2rem;
    }
  }
  &__atributes {
    display: flex;
    align-items: center;
    gap: 35px;
  }
  &__supervoice {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    > div {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #3c54d1;
    }
    p {
      margin: 0;
    }
  }
  &__gender,
  &__language {
    display: flex;
    align-items: center;
    gap: 0.65rem;
    span.justui__icon-wrapper {
      display: flex;
    }
  }
  &__synth-area {
    margin-top: 38px;
    width: 100%;
    .header {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      margin-bottom: 22px;
    }
    .synth-restr-info {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      padding: 2px 8px;
      background-color: rgba(var(--primary-red-rgb), 0.16);
      border-radius: 7px;
      margin-top: 2px;
    }
  }
  &__player {
    margin: 0;
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: 7px;
    right: 8px;
    .voice-card__player {
      margin: 0;
      .play-icon-container {
        span {
          width: 2.5rem;
          height: 2.5rem;
          background-color: var(--primary);
          margin: 0;
          svg.justui_icon {
            color: white;
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }

  &__mobile {
    position: sticky;
    transition: all 0.2s;
    left: 0;
    top: 0;
    background-color: white;
    padding: 1rem 35px 1rem 2.5rem;
    display: flex;
    align-items: center;
    box-shadow: 0px 1px 20px rgba(44, 46, 113, 0.12);
    margin-bottom: -88px;
    z-index: 10;
    @include tablet-down {
      padding: 1rem 1.5rem;
    }

    @include phone-only {
      margin-bottom: -72px;
      padding: 0.75rem;
    }

    &-avatar {
      margin-right: 1.5rem;
      width: 56px;
      height: 56px;
      .voice-placeholder {
        min-width: 100%;
        height: 100%;
        margin-right: 0.5rem;
        margin-bottom: 0;
        background-color: var(--gray-100);
      }
      img {
        min-width: 100%;
        height: 100%;
        border-radius: 50%;
      }
      @include phone-only {
        width: 40px;
        height: 40px;
        margin-right: 0.5rem;
      }
    }
    &-price {
      display: flex;
      align-items: baseline;
      gap: 6px;
      color: var(--gray-600);
      white-space: nowrap;
      h3 {
        color: black;
      }
      @include phone-only {
        gap: 3px;
      }
    }
    &-token {
      margin-left: 77px;
      position: relative;
      width: 100%;

      > div {
        width: 100%;
        button {
          width: 100%;
          padding-top: 14px;
          padding-bottom: 14px;
          border-radius: 12px;
          position: relative;
          &:focus {
            box-shadow: none;
          }
          svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1.5rem;
            @include phone-only {
              display: none;
            }
          }

          @include phone-only {
            font-size: 13px;
          }
        }
        .public-voice__popup {
          width: 100%;
          button {
            flex-basis: 3rem;
            svg {
              right: unset;
              display: block;
            }
          }
          @include tablet-down {
            bottom: -85px;
            width: calc(100% - 3rem);
            margin: 0 1.5rem;
          }
          @include phone-only {
            flex-direction: column;
            align-items: flex-start;
            padding: 1rem;
            margin: 0 0.25rem;
            width: calc(100% - 0.5rem);
            bottom: -124px;
            p {
              margin-right: 0;
              margin-bottom: 0.75rem;
            }
          }
        }
      }
      @include tablet-down {
        position: static;
      }
      @include phone-only {
        margin-left: 22px;
        > div {
          button {
            padding: 0.5rem 8px;
          }
        }
        .public-voice__token {
          width: 100%;
        }
      }
    }
  }
  .synth-area__textarea {
    box-shadow: 0px 1px 20px rgba(44, 46, 113, 0.12);
    border-radius: 16px;
    padding: 1.5rem;
    &.form-control:disabled {
      background-color: white;
      border-color: var(--grey-100);
      &:not(.is-invalid):not(.is-valid):not(.is-warning):hover {
        border-color: var(--grey-100);
      }
      &:not(.is-invalid):not(.is-valid):not(.is-warning):focus {
        border-color: var(--grey-600);
        box-shadow: none;
      }
      &.form-control:disabled {
        background-color: white;
        border-color: var(--grey-100);
      }
    }
  }
  .synth-area__textarea-controls {
    .play-icon-container span svg.justui_icon.fa-circle-notch {
      font-size: 16px;
      width: 16px;
      height: 16px;
    }
  }
}
