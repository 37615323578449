@import '../../../../../scss/custom/mixins';
.catalog-filter {
  .custom-modal {
    padding: 1.5rem;
    &__header {
      @include md-screen-down {
        align-items: center;
        margin-bottom: 0;
      }
      @include tablet-down {
        padding: 1.5rem 1.5rem 1.75rem 1.5rem;
      }
      @include phone-only {
        padding: 1.5rem 1rem 1.75rem 1rem;
      }
      .close {
        @include md-screen-down {
          position: initial;
        }
      }
    }
    &__footer {
      @include md-screen-down {
        padding: 1.5rem 2.25rem;
        gap: 1rem;
        button {
          border-radius: 1rem;
          padding-top: 14px;
          padding-bottom: 14px;
          margin: 0;
        }
      }
      @include tablet-down {
        padding: 1.5rem;
      }
      @include phone-only {
        padding: 0.5rem;
        gap: 0.5rem;
        button {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
      }
    }

    .mobile-filter {
      &__supervoice,
      &__gender,
      &__price,
      &__language {
        padding: 26px 2.25rem;
        border-top: 1px solid var(--gray-200);
        border-bottom: 1px solid var(--gray-200);
        margin-top: -1px;

        @include tablet-down {
          padding: 26px 1.5rem;
        }
        @include phone-only {
          padding: 26px 1rem;
        }
      }
    }

    &__catalog-filter {
      display: flex;
      gap: 0.5rem;
      align-items: center;

      .active-filters {
        width: 18px;
        height: 17px;
        background-color: var(--gray-900);
        color: white;
        border-radius: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        line-height: 1rem;
      }
    }
  }
}
