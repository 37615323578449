.custom-modal__body {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 0rem;
  max-height: 280px !important;
}
.preset-management {
  &__item {
    $item: &;

    &--initial {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        padding: 0.625rem 1rem;
      }

      &:hover {
        background-color: var(--gray-100);
        border-radius: 0.25rem;
        #{$item}-buttons {
          button:first-child {
            display: flex !important;
            pointer-events: initial !important;
          }
        }
      }

      #{$item}-buttons {
        padding-left: 0.5rem;

        button:first-child {
          display: none !important;
          pointer-events: none !important;
        }
      }
    }
    &-buttons {
      display: flex;
      gap: 0.5rem;
      .justui_icon-button {
        padding: 7px !important;
      }
    }

    &--editing {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding-right: 4.5rem;
      input {
        margin-bottom: 0 !important;
        padding: 9px 1rem !important;
        line-height: 1.25rem !important;
        height: auto;
      }

      #{$item}-buttons {
        button {
          &:nth-child(1) {
            svg {
              color: var(--green);
            }
          }
          &:nth-child(2) {
            svg {
              color: var(--red);
            }
          }
        }
      }
    }
  }
}
