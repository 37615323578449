@import '../../../../scss/custom/mixins';
.new-voice {
  &__wrapper {
    display: flex;
    flex-grow: 1;
    background-color: var(--gray-100);
    @include tablet-down {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin: 0;
    }
  }
  &__container {
    max-width: 1672px;
    width: 100%;
    padding: 1.5rem 2rem;
    padding-bottom: 5rem;
    margin: 0 auto;
    @include tablet-down {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin: 0;
      padding: 1.5rem;
      background-color: white;
    }
    @include phone-only {
      padding: 14px 1rem;
    }
  }
  &__back {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--gray-600);
    cursor: pointer;
    margin-bottom: 2.5rem;
  }
  &__create {
    h3 {
      font-size: 1rem;
      margin-bottom: 1.5rem;
      color: var(--gray-800);
    }
    &-wrapper {
      display: flex;
      justify-content: center;
    }
    @include tablet-down {
      width: 100%;
      display: flex;
      flex-grow: 1;
      &-wrapper {
        flex: 1;
      }
    }
  }
  &__back-container {
    @include tablet-down {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5.375rem;
      a {
        z-index: 1;
        > div {
          margin-bottom: 0;
        }
      }
      h4 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    @include phone-only {
      h4 {
        font-size: 1rem;
        line-height: 28px;
      }
    }
  }
  &__input {
    margin-bottom: 2.5rem;
  }
  &__form {
    background-color: white;
    padding: 3rem 3.5rem 3.5rem 3.5rem;
    border-radius: 1.5rem;

    input[type='text'],
    textarea {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid var(--gray-300);
      width: 426px;
      padding: 0;

      &:hover,
      &:focus {
        border: none;
        border-bottom: 1px solid var(--gray-600);
        outline: none;
        box-shadow: none;
      }

      @include tablet-down {
        width: 100%;
      }
    }

    input[type='text'] {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 29px;

      &::placeholder {
        color: var(--gray-500);
      }
      &:not(:placeholder-shown) {
        &:not(:focus) {
          border: none;
        }
      }

      &.is-invalid {
        border-color: red;
        background-image: none;
      }
      @include phone-only {
        font-size: 20px;
        line-height: 28px;
      }
    }

    button {
      font-size: 14px;
      font-weight: normal;
      padding: 14px 40px;
      font-weight: 500;
      line-height: 24px;
      @include tablet-down {
        margin-top: auto;
        width: 100%;
        border-radius: 20px;
      }
    }

    textarea {
      font-size: 14px;
      line-height: 1.5rem;
      margin-bottom: 2rem;
      resize: none;
      overflow: hidden;
      padding-bottom: 7px;
      &:not(:placeholder-shown) {
        &:not(:focus) {
          border: none;
        }
      }
      @include phone-only {
        &::placeholder {
          font-size: 11px;
        }
      }
    }

    @include tablet-down {
      padding: 0;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
  &__switch {
    width: 194px;
    min-height: 44px;
    background: var(--gray-100);
    border-radius: 18px;
    position: relative;
    display: flex;
    flex: 0;
    align-items: center;
    justify-content: space-around;
    padding: 0 18px;
    cursor: pointer;
    margin-bottom: 2rem;
    overflow: hidden;
    .switch__labels {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 13px;
      span {
        padding: 5px;
        color: var(--gray-600);
        &:hover {
          color: var(--gray-900);
        }
      }
    }

    .switch__slider {
      position: absolute;
      top: 2px;
      left: 2px;
      width: 93px;
      height: 40px;
      background-color: white;
      border-radius: 18px;
      box-shadow: 0px 1px 20px rgba(44, 46, 113, 0.12);
      transition: 0.4s;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--gray-900);
    }
    input {
      -webkit-appearance: none;
      border-radius: 0;
      &:checked + .switch__slider {
        transform: translateX(97px);
      }
    }
  }

  &__language {
    margin-bottom: 46px;
    .just-select-container {
      border: none;
      input {
        font-size: 14px;
        line-height: 1.5rem;
        font-weight: 400;

        &:not(:focus):hover {
          background-color: transparent;
        }
        &::placeholder {
          font-size: 14px;
          color: var(--gray-600);
        }
      }
      &.error input {
        border-color: red;
        background-image: none;
        background-color: rgba(var(--primary-red-rgb), 0.08);
        &:hover {
          background-color: rgba(var(--primary-red-rgb), 0.08);
        }
      }
    }
    .just-select-list {
      border-radius: 1rem;
      padding: 0.25rem;
    }
    .just-select-item {
      border-radius: 12px;
      font-size: 1rem;
      line-height: 1.5rem;
      &.focused.active,
      &.focused {
        color: var(--gray-900);
        background-color: var(--gray-100);
      }
      &.active {
        background-color: white;
        color: currentColor;
      }
    }

    .scrollbar-container {
      padding-right: 0;
    }
  }
}
