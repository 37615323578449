@import '../../../../../scss/custom/mixins';

.my-page__card {
  background-color: var(--gray-100);
  border-radius: 2rem;
  padding: 1.5rem;
  display: flex;
  gap: 1.5rem;
  align-items: center;

  &-photo {
    > div,
    img {
      height: 104px;
      width: 104px;
      border-radius: 50%;
    }
  }

  &-caption {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
  }
  &-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    h4 {
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    p:last-child {
      color: var(--gray-700);
    }
  }

  &-links {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-top: auto;
    color: var(--gray-950);

    a {
      position: relative;
      border-bottom: 1px solid var(--gray-300);
      transition: 0.2s;
      &:hover {
        color: currentColor;
        border-color: var(--gray-950);
      }
    }
    a:nth-child(2)::before,
    a:nth-child(2)::after {
      content: '\00B7';
      position: absolute;
      top: 0;
      left: -0.6rem;
      color: var(--gray-500);
      text-decoration: none;
    }

    a:nth-child(2)::after {
      left: unset;
      right: -0.7rem;
    }

    .dropdown {
      .justui_dropdown-toggle {
        display: flex;
        gap: 0.25rem;
        align-items: center;
        padding: 0;
        background-color: transparent !important;
        outline: none !important;
        border: unset !important;
        color: var(--gray-950);
        &:hover,
        &:active {
          color: var(--gray-600) !important;
        }
      }
      .justui_dropdown-menu {
        transform: translate3d(-152px, 16px, 0px) !important;
        a {
          text-decoration: none;
          &::before,
          &::after {
            content: none;
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    padding: 1rem;
    gap: 0.5rem;
  }

  @include tablet-down {
    gap: 1.5rem;
    padding: 1.5rem;
  }

  @include phone-only {
    padding: 1rem 1rem 1.25rem 1rem;
    gap: 0.75rem;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 1.5rem;
    &-photo {
      > div,
      img {
        height: 72px;
        width: 72px;
        border-radius: 50%;
      }
    }

    &-links {
      width: 100%;
      padding-left: 6px;
    }

    &-caption {
      height: auto;
    }

    h4 {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    .justui_dropdown-menu {
      transform: translate3d(-123px, 14px, 0px) !important;
    }
  }
}
