.player {
  &__container {
    display: flex;
    align-items: center;
    &--sample {
      .audio-progress-bar {
        background-color: var(--grey-200);
        &__progress {
          background-color: #9cd8b1;
        }
        &__point {
          background-color: var(--success);
        }
      }
    }
  }
  &__time-block {
    min-width: 100px;
    margin-left: 1rem;
  }
}

.voice-card__player .play-icon-container {
  svg.justui_icon {
    &.fa-play {
      margin-left: 2px;
    }
    &.fa-circle-notch {
      font-size: 14px;
      width: 14px;
      height: 14px;
    }
  }
}
