.loader {
  .catalog-page {
    display: flex;
    flex: 1;
    &__container {
      display: flex;
      flex: 1;
      flex-direction: column;
      max-width: 1672px;
      margin: 0 auto;
      margin-top: 0.75rem;
    }
    &__breadcrumbs {
      padding: 0 2rem;
      ol {
        padding: 0;
        margin: 0;
        margin-bottom: 0.5rem;
        color: var(--gray-600);
        a:hover {
          color: var(--gray-900);
        }
        a[href] {
          cursor: pointer;
        }
      }
    }
    &__header-wrapper {
      border-bottom: 1px solid var(--gray-200);
    }
    &__header {
      display: flex;
      justify-content: space-between;
      padding: 0 2rem;
      gap: 5rem;
      padding-bottom: 1.5rem;

      .justui_dropdown-toggle {
        padding: 0.5rem 0.75rem;
        border-radius: 1rem;
        border: 0;
        background-color: white;
        &:hover,
        &:active,
        &:focus {
          background-color: var(--gray-100);
        }
        svg {
          color: var(--gray-900);
        }
      }
    }

    &__header-input {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 39px;
      width: 100%;
      border-radius: 0.75rem;
      background-color: var(--gray-100);
    }

    &__catalog-container {
      display: flex;
      flex: 1;
    }
    &__catalog-column:first-child {
      width: 37%;
      border-right: 1px solid var(--gray-200);
    }
    &__catalog-column:last-child {
      width: 63%;
    }
    &__catalog-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 2rem;
      border-bottom: 1px solid var(--gray-200);
      .justui_tabs {
        gap: 1.5rem;
        li.active {
          border-color: var(--gray-900) !important;
        }
        a:not(.active) {
          span {
            color: var(--gray-500);
          }
        }
        a {
          padding: 1.2rem 0;
        }
      }
    }

    &__catalog-tab {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      span {
        color: var(--gray-500);
      }
    }

    &__catalog-buttons {
      margin-right: 0.75rem;
      display: flex;
      align-items: center;
      padding: 6px 0;
      button {
        height: 3rem;
        width: 3rem;
      }
    }

    &__catalog {
      padding: 1rem 0.8rem 0 1.5rem;
      width: 100%;
      margin-left: auto;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 0.5rem;

      &-row {
        padding: 0.75rem;
        padding-left: 0.6rem;
        display: flex;
        height: 48px;
        align-items: center;
        gap: 0.75rem;
        border-radius: 0.75rem;

        overflow: hidden;
        background-color: var(--gray-100);
      }
    }
    &__sample {
      padding-top: 11px;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    &__sample-header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: 1.75rem;
      gap: 1.5rem;
      height: 46px;
      > div {
        display: flex;
        align-items: center;
        gap: 1rem;
        width: 100%;
        > div {
          width: 100%;
          height: 38px;
          border-radius: 0.75rem;
          background-color: var(--gray-100);
        }
      }
    }

    &__sample-description {
      margin-bottom: 1rem;
      height: 33px;
      border-radius: 0.75rem;
      background-color: var(--gray-100);
    }

    &__sample-text {
      display: flex;
      flex: 1;
      min-height: 300px;
      background-color: var(--gray-100);
      border-radius: 1.5rem;
      margin-bottom: 4.5rem;
    }

    &__sample-record {
      display: flex;
      flex-direction: column;
      align-items: center;
      .catalog-page__header {
        border: 0;
      }
      .waveform-container {
        width: 100%;
        margin-bottom: 2.5rem;
      }
      p:first-child {
        margin-bottom: 2.5rem;
        color: var(--gray-600);
      }
      .justui_dropdown-toggle {
        display: flex;
        align-items: center;
        padding: 0.25rem 0.75rem;
        gap: 0.5rem;
        button {
          padding: 0;
        }
      }
      &__sample-media {
        padding-bottom: 0.75rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      &-start {
        display: flex;
        flex-direction: column;
        align-items: center;
        > button:first-child {
          outline: none;
          border: 0;
          background-color: transparent;
          > div {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: var(--orange);
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              color: white;
            }
          }
          width: 60px;
          height: 60px;
          border: 1px solid var(--orange);
          border-radius: 50%;
          padding: 0.25rem;
          display: flex;
          cursor: pointer;
          transition: all 0.2s;
          margin-bottom: 0.5rem;

          &:hover {
            padding: 0;
          }
        }
      }

      .justui_dropdown-menu {
        margin-left: 0;
      }

      &-microphone {
        padding: 0;
        width: 43.5%;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        button {
          border-radius: 0.75rem;
          padding: 0.5rem 1.5rem;
          svg {
            color: var(--gray-600);
          }
        }
      }
      &-duration {
        width: 43.5%;
        text-align: end;
      }
    }
  }

  &__glass {
    position: absolute;
    z-index: 100;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.3s;

    &.active {
      background-color: rgba(255, 255, 255, 0.32);
      pointer-events: all;
    }
  }
}
