.TextareaAutosize{
  &-shadow{
    position: absolute;
    visibility: hidden;
    overflow: hidden;

    height: 0;
    top: 0;
    left: 0;
    transform: translateZ(0);
  }
}
