@import '../../../../scss/custom/variables';
@import '../../../../scss/custom/mixins';

.header-navbar {
  .btn-none {
    color: var(--gray-950);
    &:hover {
      color: var(--gray-700);
    }
  }
  .logged-in,
  .not-logged {
    width: 100%;
    @include tablet-down {
      margin: 0;
      width: 0;
    }
  }

  &.project {
    .logged-in,
    .not-logged {
      margin-left: auto;
      width: unset;
    }
  }

  .justui_tabs.nav {
    margin-left: 2.5rem;
    margin-bottom: 0;
    border-bottom: none;
    flex: 1;
    justify-content: flex-end;
    display: flex;
    flex-wrap: nowrap;
    cursor: auto !important;
    .nav-item {
      display: flex;
    }
    .nav-item:not(.active) {
      border-bottom: 2px solid transparent;
    }
    .nav-item.active {
      border-color: transparent !important;
    }
    .nav-link {
      padding: 0;
    }
    .nav-link:hover,
    a:hover {
      color: var(--gray-600);
    }
    .nav-link {
      color: var(--grey-950) !important;
      font-size: 1rem;
      a,
      svg {
        transition: 0.2s;
      }
    }

    @include tablet-down {
      width: 0 !important;
    }
  }
  &.dark {
    .nav-item:hover {
      background-color: transparent !important;
    }
    .nav-link {
      a {
        color: white;
        &:hover {
          color: var(--gray-300);
        }
      }
    }
  }

  #tabs_nav_item_help {
    margin-right: 1.5rem;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  #tabs_nav_item_myPage {
    margin-right: 1.5rem;
  }
  #tabs_nav_item_catalog {
    margin-right: 2.5rem;
  }

  #tabs_nav_item_voice {
    margin-right: auto;
  }

  .logged-in {
    #tabs_nav_item_wallet {
      margin-right: 1rem;
    }

    #tabs_nav_item_help {
      margin-right: 26px;
    }

    #tabs_nav_item_catalog {
      margin-right: 35px;
    }

    #tabs_nav_item_library {
      margin-right: 2.5rem;
    }
  }
  .header-navbar__wallet {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    gap: 12px;
    background-color: var(--gray-100);
    border-radius: 15px;
    cursor: pointer;
    transition-duration: 0.2s;

    &:hover {
      background-color: var(--gray-200);
    }
    .justui__icon-wrapper {
      display: flex;
    }
    svg {
      width: 20px !important;
      height: 20px !important;
    }

    &-price {
      color: var(--gray-950);
      white-space: nowrap;
    }

    @include tablet-down {
      padding: 6px 10px;
      gap: 0.5rem;
      svg {
        width: 12px !important;
        height: 12px !important;
      }
    }
    &-link {
      margin-left: auto;
    }
  }
}

.header-dropdown__speaker {
  margin-left: auto;
  margin-right: 1.5rem;
  button {
    font-size: 1rem;
  }

  .justui_dropdown-toggle {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
}

.header__offline-block {
  margin-right: 2rem;
  min-width: 135px;
  display: flex;
  justify-content: flex-end;
  cursor: default;
  @include tablet-down {
    order: 3;
    margin-right: 0;
  }
  @include phone-only {
    min-width: 16px;
  }
  .loading-block {
    display: flex;
    align-items: center;
    svg.fa-color-secondary.justui_icon {
      color: var(--grey-500);
      margin-right: 0.5rem;
      @include phone-only {
        margin-right: 0;
      }
    }
  }
  > p {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .dirty-indicator {
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: var(--primary-red);
    margin-right: 4px;
    cursor: pointer;
  }
}

.offline-mode-tooltip {
  .arrow {
    display: none;
  }
  .tooltip {
    padding-top: 8px;
    &-inner {
      min-width: 270px;
      padding: 12px;
      padding-top: 8px;
    }
  }
}

#tabs_nav_item_offlineMode {
  cursor: default;
}
