@import '../../../../../scss/custom/mixins';
.replica-history__modal {
  max-width: 900px;
  min-height: 91%;
  .custom-modal {
    padding: 2.5rem;
    padding-right: 0.25rem;
    height: 100%;
  }
  .modal-body {
    padding-right: 1.75rem;
  }
  .modal-content {
    height: 91vh !important;
  }
  .modal-title {
    @include h3;
  }

  .replica-history {
    &__item {
      &-content {
        flex-direction: row;
        gap: 3rem;
      }
      &-text {
        &-container {
          width: 100%;
        }
        -webkit-line-clamp: unset;
        overflow: auto;
        max-height: none;
      }
      &-synth {
        width: fit-content;
        gap: 1rem;
      }
      &-date {
        margin-top: 1rem;
        &:first-of-type {
          margin-top: 0rem;
        }
      }
      &-synth-play {
        margin-right: 0;
      }
    }
  }
}
