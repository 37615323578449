@import '../../../../scss/custom/mixins';

.modal-dialog {
  @include phone-only {
    margin: 0;
  }
}

.custom-modal {
  position: relative;
  padding: 2rem;
  input[type='text'] {
    margin-bottom: 1.5rem;
    @include phone-only {
      margin-bottom: 1rem;
    }
  }

  @include tablet-down {
    padding: 1.5rem;
  }

  @include phone-only {
    padding: 1rem;
  }

  .modal-body {
    max-height: calc(100vh - 10rem);
    overflow-y: auto;
    @include scrollbar;
    @include phone-only {
      @include scrollbar(2px, 2px);
    }
  }

  .modal-link-wrapper {
    margin: 1.5rem auto 0;
    max-width: 230px;
    line-height: 1rem;
    a {
      text-decoration: underline;
      text-underline-offset: 3px;
      line-height: 1rem;
      &:hover {
        color: unset;
      }
    }
    @include phone-only {
      margin-top: 1rem;
    }
  }
  .request-modal {
    &.modal-body {
      margin-bottom: 0;
    }
    &.modal-header {
      margin-top: 0.75rem;
      margin-bottom: 2.75rem;
      @include phone-only {
        margin-top: 0;
        margin-bottom: 1.5rem;
      }
    }
    @include phone-only {
      h3 {
        font-size: 1rem;
        line-height: 1.5rem;
        margin-top: 0.5rem;
      }
    }
    ~ .custom-modal__footer {
      @include phone-only {
        button.btn.btn-xl {
          border: 0;
          padding: 1rem;
        }
      }
    }
  }
  .custom-modal__body {
    .input-wrapper {
      min-height: 5rem;
      @include phone-only {
        min-height: 4rem;
      }
    }
    input {
      @include tablet-down {
        border-radius: 18px;
        height: 48px;
      }
    }
  }

  &__container {
    .modal-content {
      border-radius: 2.5rem;
    }
  }
  &__header {
    display: flex;
    padding: 0;
    &:not(.hidden) {
      margin: 0 0 1.5rem 0;
      @include phone-only {
        margin-bottom: 1rem;
      }
    }
    .close {
      padding: 0;
      margin: 0;
      position: absolute;
      top: 17px;
      right: 11px;

      &:focus {
        outline: none;
      }
      @include phone-only {
        top: 16px;
        right: 16px;
      }
    }
    h5 {
      font-weight: 600;
    }
  }
  &--center {
    text-align: center;
    .custom-modal__header {
      justify-content: center;
    }
    .custom-modal__body {
      text-align: center;
    }
  }

  &__body {
    padding: 0;
    margin-bottom: 1.5rem;
    .justui_input-radioButton-container input:checked ~ .justui_input-radioButton {
      border-color: var(--gray-950);
      box-shadow: 0;
      &::after {
        background-color: var(--gray-950);
      }
    }
    .justui_input-radioButton-container:hover input ~ .justui_input-radioButton {
      box-shadow: none;
    }
  }

  &__footer {
    padding: 0;
    display: flex;
    gap: 1rem;
    &.modal-footer > * {
      margin: 0;
    }
    button {
      flex: 1;
    }
    @include tablet-down {
      flex-direction: column-reverse;
      gap: 0.5rem;

      button {
        width: 100%;
        margin: 0;
      }
    }
  }

  &__delete {
    display: flex;
    align-items: center;
    img {
      width: 3.125rem;
      height: 3.125rem;
      border-radius: 50%;
      margin-right: 1.25rem;
    }
    p {
      color: black;
      margin: 0;
    }
  }
  &__editing {
    margin-bottom: 2rem;
  }
  &__create-set {
    p {
      margin: 0;
    }
    p:last-of-type {
      color: var(--gray-600);
      margin-bottom: 0.5rem;
    }
  }

  &__delete,
  &__editing {
    &-user {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 20px;
      min-width: 50px;
      min-height: 50px;
      background-color: var(--info);
      border-radius: 50%;
      margin-right: 0.5rem;
      font-weight: 600;
      color: white !important;
      margin-right: 1rem !important;
    }
  }

  &__radiogroup {
    p {
      padding-left: 1.5rem;
      color: var(--secondary);
      margin-bottom: 1rem;
    }
    .justui_input-radioButton-label {
      font-weight: 600;
    }
  }
}

.fullscreen {
  position: static;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0;
  max-width: inherit;
  min-height: 100vh;
  height: fit-content;

  border-radius: 0;
  &::before {
    display: none;
  }
  .modal-content {
    margin-top: 1.5rem;
    border-radius: 2rem 2rem 0 0;
    @include phone-only {
      border-radius: 1.5rem 1.5rem 0 0;
    }
  }
  .custom-modal {
    padding-bottom: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
  }
  .modal-body {
    max-height: calc(100vh - 7.8rem);
  }
  .close {
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.just-ui-modal.custom-modal .modal-content .modal-header .modal-title {
  color: var(--grey-950);
}

.custom-modal__container:not(.fullscreen) {
  &.modal-card {
    .modal-content {
      margin-left: 2.5rem;
      margin-right: 2.5rem;
      @include phone-only {
        margin-left: calc(1.25rem - 1px);
        margin-right: calc(1.25rem - 1px);
      }
    }
  }
  .modal-content {
    height: fit-content;
    width: 100%;
  }
  .custom-modal__header {
    .close {
      padding: 8px;
      background: rgba(0, 0, 0, 0.32);
      border-radius: 12px;
      position: absolute;
      top: 12px;
      right: -44px;
      color: white;
      opacity: 1;
      span {
        display: flex;
      }
      @include phone-only {
        top: calc(100% + 12px);
        right: 0;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        width: 32px;
      }
    }
  }
}

.modal.fade .modal-dialog.fullscreen {
  transform: translate(0, 50px);
}

.modal.show .modal-dialog.fullscreen {
  transform: none;
}

.fullscreen .modal.fade {
  overflow-y: hidden;
}
