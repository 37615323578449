.custom-modal {
  &__no-money {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2rem;
    &__placeholder {
      width: 120px;
      height: 80px;
      background-color: var(--primary);
    }
  }
}
