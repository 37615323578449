.balance-history {
  max-width: 1672px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 19px 2rem 6.25rem 2rem;
  &__back {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--gray-600);
    cursor: pointer;
    margin-bottom: 2.5rem;

    &-container {
      padding-top: 0.5rem;
      width: 20%;
    }
  }
}
