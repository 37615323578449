@import '../../../../scss/custom/mixins';

.account-page {
  &__container {
    padding: 1.5rem 2.5rem;
    padding-right: 36px;
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    > div {
      flex: 1;
    }
    .request-block__wrapper {
      display: flex;
      flex-basis: 100%;
      gap: 12rem;

      margin-top: -1.5rem;
      > div {
        flex: 1;
      }
    }
    @include tablet-down {
      flex-direction: column;
      padding: 0 1rem;
      gap: 2.5rem;
      .request-block-placeholder {
        display: none;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    min-height: 480px;
    overflow: hidden;
    padding: 2.5rem;
    border-radius: 40px;
    background-color: var(--grey-100);
    background-size: 810px;
    background-repeat: no-repeat;
    @include tablet-down {
      max-width: none;
      padding: 1rem;
      border-radius: 2rem;
      min-height: 320px;
      background-size: 500px;
      background-repeat: no-repeat;
    }

    &.usd {
      background-image: url('../../../../img/dollar-bg-lg.png');
      background-position: right -250px top 45px;

      @include tablet-down {
        background-position: right -130px top 7px;
      }
    }

    &.rouble {
      background-position: right -250px top -25px;
      background-image: url('../../../../img/ruble-bg-lg.png');

      @include tablet-down {
        background-position: right -160px top -25px;
        background-image: url('../../../../img/ruble-bg-sm.png');
      }
    }
  }
  &__header {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey-300);
    padding-bottom: 2.625rem;
    &-wrapper {
      position: relative;
      height: 88px;
      width: 100%;
    }
    &-text {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    &-bg-img {
      position: absolute;
      width: 40px;
      height: 300px;
      bottom: -100%;
      left: 0;
      border: 37px solid rgba(243, 103, 56, 0.55);
      box-sizing: border-box;
      filter: blur(96px);
      transform: matrix(-0.62, -0.78, -0.78, 0.62, 0, 0);
    }
    @include tablet-down {
      padding-bottom: 1rem;
    }
  }
  &__logout-btn {
    & svg.btn-icon.fa-lg.justui_icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    flex: 1;
    span.wallet-icon {
      margin-top: auto;
      svg.fa-color-secondary.svg-inline--fa {
        color: var(--grey-400);
        @include tablet-down {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
    &__balance {
      margin-top: auto;
      h1,
      h2 {
        font-weight: normal;
        color: var(--primary);
        margin-top: 1rem;
        @include tablet-down {
          margin-top: 0;
        }
      }
    }
  }
  &__pending-block {
    padding-top: 1.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    svg {
      color: var(--grey-500);
    }
    @include tablet-down {
      gap: 0.5rem;
    }
  }
  &__plans {
    padding-top: 1.5rem;
    @include tablet-down {
      padding-top: 0;
    }
    > p {
      margin-bottom: 1.5rem;
    }
    .package-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.5rem 2rem;
      border: 1px solid var(--grey-300);
      border-radius: 2rem;
      cursor: pointer;
      @include tablet-down {
        padding: 1.125rem 1.5rem;
        border-radius: 1.5rem;
      }
      &:hover {
        border-color: var(--grey-900);
        p {
          color: var(--grey-900);
        }
      }
      p {
        font-size: 1.5rem;
        color: var(--grey-500);
        @include tablet-down {
          font-size: 1rem;
        }
      }
      svg {
        width: 1.5rem;
        height: 1.5rem;
        @include tablet-down {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
  &__packages-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
  }
}

.request-server-block {
  padding: 2rem;
  border: 1px solid var(--grey-200);
  border-radius: 2.5rem;
  cursor: pointer;
  background-image: url('../../../../img/request-server-bg.svg');
  background-repeat: no-repeat;
  background-position: 95% 100%;
  flex-basis: 50%;
  margin-left: auto;
  &:hover {
    border-color: var(--grey-950);
  }
  @include tablet-down {
    margin-bottom: 100px;
  }
  @include phone-only {
    background-size: 150px;
    background-position: 100% 115%;
    padding: 1.5rem;
    h4 {
      font-size: 16px;
      line-height: 24px;
    }
  }
  p.tp-3 {
    margin-top: 1rem;
    margin-bottom: 2.5rem;
    max-width: 75%;
    @include md-screen-down {
      max-width: 250px;
    }
    @include tablet-down {
      max-width: 75%;
    }
    @include phone-only {
      font-size: 14px;
      line-height: 20px;
      max-width: 100%;
    }
  }
}
