@import '../../../../scss/custom/mixins';
.voice-page__training {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @include md-screen-down {
    padding: 0 1.5rem;
  }
  @include phone-only {
    padding: 0;
  }
  .training {
    &__header {
      margin-bottom: 1.5rem;
      h3 {
        margin-bottom: 1.5rem;
      }
      p {
        margin: 0;
        max-width: 23rem;
      }
    }
    &__content {
      min-height: 100%;
      position: relative;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
    &__create-new {
      position: absolute;
      padding: 1.25rem 1.5rem 1.75rem 1.75rem;
      max-width: 20rem;
      left: 10rem;
      top: 1rem;
      border: 1px solid var(--gray-100);
      box-shadow: 0px 1px 1.25rem rgba(44, 46, 113, 0.12);
      border-radius: 1rem;
      .arrow-1-2 {
        position: absolute;
        top: -1rem;
        left: 8rem;
      }
      h4.number {
        color: var(--gray-300);
        font-weight: 500;
        font-size: 2rem;
        margin-bottom: 1rem;
      }
      h4:not(.number) {
        margin-bottom: 0.5rem;
      }
      p {
        margin-bottom: 2rem;
      }
      button {
        width: 100%;
      }
    }
    &__new-voice {
      .new-voice {
        &__go-back {
          display: inline-block;
          a {
            display: flex;
            align-items: baseline;
            gap: 0.5rem;
            &:hover {
              color: inherit;
            }
          }
          color: var(--gray-600);
          margin-bottom: 2rem;
          cursor: pointer;
          p {
            margin: 0;
          }
        }
        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 2rem;
          p {
            margin-top: 0.625rem;
          }
        }
        &__buttons {
          align-self: flex-start;
        }
        &__title {
          display: flex;
          gap: 0.5rem;
          h1 {
            margin-top: 3px;
          }
          h1:last-child {
            color: var(--gray-300);
          }
        }
        &__subtitle {
          color: var(--gray-600);
          margin-bottom: 1.5rem;
        }
        &__content-wrapper {
          background-color: var(--gray-100);
          padding: 1rem;
          border-radius: 0.5rem;
          margin-bottom: 1.5rem;
        }
        &__content {
          background-color: white;
          border-radius: 0.5rem;
          padding: 1.5rem;
          .content {
            &__title {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 2rem;
            }
            &__empty {
              position: relative;
              padding: 2.5rem 0;
              margin: auto;
              max-width: 22rem;
              text-align: center;

              .arrow {
                position: absolute;
                top: -2rem;
                right: -11rem;
              }
              h3 {
                margin-bottom: 1rem;
              }
            }
            &__table {
              .table {
                &__head {
                  padding-bottom: 0.75rem;
                  border-bottom: 1px solid var(--gray-200);
                  & .table__row {
                    padding-bottom: 0;
                    cursor: auto;

                    p:last-child {
                      flex-basis: 9rem;
                    }
                  }
                  & p {
                    margin-bottom: 0;
                    font-weight: 600;
                  }
                  & p:nth-child(2) {
                    flex-basis: 7rem;
                  }
                  & p:nth-child(3) {
                    text-align: right;
                    flex-basis: 6rem;
                    margin-right: 3rem;
                  }
                }
                &__body {
                  .table__row:hover {
                    background-color: var(--gray-100);
                    border-radius: 0.25rem;
                  }
                  & p {
                    margin-bottom: 0;
                  }
                  & p:first-child {
                    text-decoration: underline;
                    text-decoration-color: var(--gray-400);
                  }
                }
                &__row {
                  display: flex;
                  cursor: pointer;
                  position: relative;
                  gap: 3rem;
                  & p {
                    margin-bottom: 0;
                    flex-basis: 8rem;
                  }

                  & p:last-child {
                    flex-basis: 9rem;
                  }

                  & p:first-child {
                    position: relative;
                    flex: 0 0 16rem;
                    max-width: 16rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                  }
                  &-delete {
                    cursor: pointer;
                    min-width: 16px;
                    svg {
                      color: var(--gray-400);
                    }
                    &:hover {
                      svg {
                        color: var(--gray-700);
                      }
                    }
                  }
                  padding: 0.5rem;
                  .statuses-column {
                    min-width: 150px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .progress-bar {
                      position: relative;
                      min-width: 0.5rem;
                      overflow: unset;
                    }
                    .progress {
                      height: 3px;
                      overflow: unset;
                    }
                    .progress-bar-tooltip-target {
                      //'overflow: unset' above is set for this element to be able to be hovered
                      position: absolute;
                      width: 100%;
                      height: 1rem;
                      cursor: pointer;
                      z-index: 10;
                    }
                  }
                  .duration-column {
                    flex-basis: 5rem;
                    text-align: right;
                    margin-right: 3rem;
                  }
                  &.deleting {
                    pointer-events: none;
                    &::after {
                      content: '';
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      background-color: white;
                      opacity: 0.5;
                    }
                  }
                  &-wrapper {
                    position: relative;
                    .just-ui-spinner {
                      z-index: 20 !important;
                    }
                    svg {
                      width: 1rem !important;
                      height: 1rem !important;
                    }
                    .just-ui-spinner {
                      .justui__icon-wrapper {
                        display: flex;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &__accordeon {
          margin-top: 1rem;
          h3 {
            margin-bottom: 0.5rem;
          }
          p {
            margin: 0;
          }
          .accordeon {
            &__head {
              display: flex;
              justify-content: space-between;
              align-items: center;
              svg {
                cursor: pointer;
              }
              &.disabled {
                opacity: 0.6;
                svg {
                  pointer-events: none;
                }
              }
            }
            &__body {
              margin-top: 2rem;
            }
          }
        }
      }
    }
    &__card {
      position: relative;
      display: flex;
      padding: 2rem;
      border-radius: 2.5rem;
      margin-bottom: 1rem;
      gap: 1rem;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(
            119.14% 123.02% at 80.67% 120%,
            rgba(255, 245, 0, 0.16) 0%,
            rgba(242, 74, 144, 0.1152) 64.57%,
            rgba(255, 61, 0, 0.16) 100%
          ),
          #ffffff;
        opacity: 0.6;
        border-radius: 2.5rem;
      }

      h4 {
        margin-bottom: 10px;
      }
      .progress-bar {
        &__container {
          display: flex;
          flex-direction: column;
        }
        &__buttons {
          margin-top: 18px;
          display: flex;
          gap: 1rem;
          .justui_button {
            padding: 1rem 28px;
            border-radius: 18px;
          }
        }

        @include md-screen-down {
          &__buttons {
            justify-content: flex-end;
          }
        }

        @include tablet-down {
          &__buttons {
            margin-top: 1.5rem;
            a {
              width: 100%;
            }
            button {
              width: 100%;
            }
          }
        }
      }

      @include md-screen-down {
        border-radius: 2rem;
        &::before {
          border-radius: 2rem;
        }
      }

      @include tablet-down {
        flex-direction: column;
        gap: 0;
        .progress-bar {
          &__container {
            width: 100%;
          }
        }
      }

      @include phone-only {
        padding: 1.5rem;
      }
    }
    &__card-description {
      display: flex;
      flex-direction: column;
      flex: 1;
      z-index: 1;
      p {
        max-width: 25rem;
        white-space: pre-line;
      }
      button {
        color: black;
      }

      @include tablet-down {
        margin-bottom: 24px;
        p {
          max-width: 100%;
        }
      }
      @include phone-only {
        margin-bottom: 2rem;
        h4 {
          margin-bottom: 12px;
        }
      }
    }
    &__versions-container {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      background-color: var(--gray-100);
      padding: 1rem;
      border-radius: 2rem;
      p {
        margin: 0;
      }
    }
    &__version-card {
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: 1.5rem;
    }
    &__version-header {
      display: grid;
      grid-template-columns: minmax(max-content, 2fr) minmax(max-content, 1fr) minmax(12.5rem, 15.25rem) 12.5rem;
      width: 100%;
      padding: 1.5rem;
      border-radius: 0.5rem;
      gap: 2rem;
      align-items: center;

      h4 {
        white-space: nowrap;
      }

      button {
        margin-left: auto;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-left: 36px;
      }

      @include tablet-down {
        padding: 1rem 1rem 1.5rem 1rem;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, min-content);
        justify-content: space-between;
        gap: 0;
        h3,
        & > p {
          margin-bottom: 1rem;
        }
        & > p {
          color: var(--gray-700);
          text-align: end;
        }
        button {
          margin-top: 1.5rem;
          grid-column-start: 1;
          grid-column-end: 3;
          margin-left: 0;
        }
      }
    }
    &__version-progressBar {
      .progress {
        height: 3px;
        border-radius: 0.5rem;
      }
      div:first-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &--success {
        color: var(--success);
      }
      &--danger {
        color: var(--danger);
      }

      @include tablet-down {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
    &__success-note {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-top: -0.5rem;
      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    &__synth-area {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1.5rem;
      padding-top: 0;

      .synth-area-wrapper {
        width: 100%;
        border-radius: 0.24rem;
        border-width: 1px;
        border-color: var(--grey-600);

        .synth-area__textarea {
          border-radius: 20px !important;
        }
      }

      @include tablet-down {
        padding: 1rem;
        .synth-area__textarea .textarea-container {
          max-height: 80px;
        }
      }
    }
  }
}
