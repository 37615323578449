@import '../../../../../scss/custom/mixins';

.training {
  &_mobile-notification {
    position: sticky;
    bottom: 0rem;
    margin-right: 68px;
    margin-left: -1rem;
    background-color: var(--gray-700);
    padding: 1.5rem 2.5rem;
    color: white;
    border-radius: 2rem;
    display: flex;
    gap: 2rem;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    margin-bottom: -3rem;
    flex-grow: 0;

    .justui_icon:last-child {
      color: var(--gray-300);
    }
    > div {
      width: 100%;
      > p:last-child {
        color: var(--gray-200);
      }
    }

    @include tablet-down {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      gap: 0;
      > div {
        margin-left: 2rem;
      }
    }

    @include phone-only {
      margin-right: 78px;
      margin-left: 0.25rem;
      margin-bottom: -3.25rem;
      padding: 0.5rem 0.5rem 0.5rem 1rem;
      border-radius: 1.5rem;
      gap: 0;
      > div {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        margin-left: 1rem;

        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.25rem;
        }
      }
      p {
        line-height: 1rem;
      }
    }

    @media (min-width: 421px) and (max-width: 560px) {
      padding-top: 19px;
      padding-bottom: 19px;
    }
  }
}
