@import '../../../../../scss/custom/mixins';
.mobile-managers {
  .modal-title {
    width: 100%;
  }
  .custom-modal {
    &__header {
      padding: 1rem 1.5rem 2.5rem 1.5rem;
      margin-bottom: 0;
      p {
        max-width: 100%;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      @include phone-only {
        padding: 0.5rem 1rem;
        margin-bottom: 1.5rem;
      }
    }
    &__radiogroup {
      p {
        padding-left: 1.5rem;
        color: var(--secondary);
        margin-bottom: 1rem;
      }
      .justui_input-radioButton-label {
        font-weight: 600;
      }
    }
    &__body.modal-body {
      margin-bottom: 0 !important;
    }

    &__footer {
      padding: 1rem;
      flex-direction: column-reverse;
      gap: 0.5rem;
      button {
        margin: unset;

        border-radius: 18px;
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      @include phone-only {
        padding: 0.5rem;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
  }
  &__close {
    margin-right: 33px;
    display: flex;
    @include phone-only {
      margin-right: 1.5rem;
    }
  }
  &__voice-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    min-width: 40px;
    min-height: 40px;
    background-color: var(--info);
    border-radius: 50%;
    margin-right: 0.5rem;
    font-weight: 600;
    color: white !important;
    margin-right: 12px;
  }

  &__body {
    padding: 0 1.5rem;

    @include phone-only {
      padding: 0 11px 0 21px;
    }

    .justui_input-radioButton-label {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 600;
      margin-bottom: 0;
    }
    p {
      margin-bottom: 1.5rem;
    }
  }

  &__delete {
    .custom-modal {
      padding: 2rem 1.5rem 1.5rem 1.5rem;
      button {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      @include phone-only {
        padding: 34px 1rem 1rem 1rem;
      }
      &__header,
      &__footer {
        padding: 0;
      }
      &__header {
        margin-bottom: 2rem;
        .close {
          padding: 8px;
          background: rgba(0, 0, 0, 0.32);
          border-radius: 12px;
          position: absolute;
          top: 0;
          right: -39px;
          color: white;
          span {
            display: flex;
          }
        }
      }
    }
    .modal-content {
      border-radius: 2rem;
    }
  }

  .justui_input-radioButton-container input:checked ~ .justui_input-radioButton {
    border-color: var(--gray-950);
    &::after {
      background-color: var(--gray-950);
    }
  }
  .justui_input-radioButton-container:hover input ~ .justui_input-radioButton {
    box-shadow: none;
  }
}
