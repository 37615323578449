@import '../../../../../scss/custom/mixins';
.catalog-page__sample-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.5rem;
  gap: 1.5rem;
  h3 {
    font-weight: 600;
  }
  svg {
    outline: none;
  }
  > div {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    .catalog-page__header-input {
      justify-content: center;
      input {
        font-size: 1rem;
        line-height: 28px;
      }
      .catalog-page__header-buttons {
        top: -4px;
      }
    }
  }
  input {
    width: 100%;
    border: 0;
    transition: all 0.3s;
    height: 30px;
    border-bottom: 1px solid transparent;

    &:focus,
    &:active {
      outline: 0;
      border: 0;
      border-bottom: 1px solid var(--gray-600);
    }
  }
  #sampleDelete {
    button {
      padding-top: 10px;
      padding-bottom: 10px;
      svg {
        color: var(--gray-600);
      }
      &:disabled {
        svg {
          color: var(--gray-300) !important;
        }
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

.catalog-page__sample-description {
  margin-bottom: 0.25rem;
  position: relative;

  &:focus-within {
    .catalog-page__sample-description-buttons {
      display: flex;
    }
  }

  > div:first-child {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      min-width: 0;
      height: 100%;
      border-bottom: 1px solid var(--gray-600);
      transition: all 0.3s;
      pointer-events: none;
    }
    &:focus-within {
      &::after {
        min-width: 100%;
      }
    }
  }
  textarea {
    border: 0;
    font-size: 1rem;
    padding: 0;
    padding-right: 5.75rem;
    width: 100%;
    resize: none;
    overflow: hidden;
    &:active,
    &:focus {
      outline: 0;
      border: 0;
    }
    &::placeholder {
      color: var(--gray-600);
    }
  }

  &-buttons {
    display: none;
    gap: 0.25rem;
    position: absolute;
    bottom: 5px;
    right: 0;
    button {
      padding: 5px !important;
    }
  }
}

.catalog-page__sample-text {
  border: 1px solid transparent;
  position: relative;
  display: flex;
  min-height: 150px;
  max-height: 430px;
  background-color: var(--gray-100);
  border-radius: 1.5rem;
  padding: 0 0.25rem 0 1.5rem;
  margin-bottom: 2.5rem;
  font-size: 1rem;
  flex: 1;
  line-height: 28px;
  overflow-y: hidden;

  &:focus-within {
    background-color: white;
    border: 1px solid var(--gray-600);
    .catalog-page__sample-text-buttons:not(:focus-within) {
      display: flex;
    }
  }
  > div:first-child {
    padding-top: 1rem;
    max-width: 100%;
    width: 100%;
    font-size: 1rem;
    line-height: 28px;
    overflow: auto;

    @include scrollbar(4px, 4px, 1rem);
  }
  span {
    color: var(--red);
  }
  textarea {
    padding-top: 1rem;
    padding-left: 0;
    width: 100%;
    height: 100%;
    resize: none;
    font-size: 1rem;
    overflow: auto;
    line-height: 28px;
    border: 0;
    padding-right: 1.5rem;
    color: var(--gray-900);
    background-color: transparent;

    &:focus,
    &:active {
      height: calc(100% - 85px);
      outline: 0;
    }

    @include scrollbar(4px, 4px, 1rem);
  }

  &-buttons {
    position: absolute;
    left: 1.5rem;
    bottom: 22px;
    padding-top: 1.5rem;
    width: calc(100% - 3rem);

    display: none;
    gap: 0.5rem;
    button {
      border-radius: 0.75rem;
    }
  }
  &__sample-media {
    &:focus-within {
      opacity: 0.5;
    }
  }
}
.catalog-page__sample-input {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 39px;
  width: 100%;

  input {
    border: 0;
    padding: 0;
    font-size: 1rem;
    line-height: 28px;
    font-weight: 600;
    overflow: hidden;
    transition: all 0.3s;
    width: 100%;
    &:focus,
    :hover {
      border: 0;
      outline: 0;
    }
    border-bottom: 1px solid transparent;
    &:focus {
      border-bottom: 1px solid var(--gray-600);

      max-width: 100% !important;
      padding-right: 4.25rem;
      & + .catalog-page__header-buttons {
        display: flex;
      }
    }
  }
}
.catalog-page__header-input {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  z-index: 3;

  input {
    border: 0;
    padding: 0;
    font-size: 1rem;
    line-height: 28px;
    width: 100%;
    color: var(--gray-950);
    &:focus,
    :hover {
      border: 0;
      outline: 0;
    }
    border-bottom: 1px solid transparent;
    &:focus {
      border-bottom: 1px solid var(--gray-600);
      padding-right: 4.25rem;
    }
  }
  .catalog-page__header-buttons {
    display: flex;
  }
  &.error {
    height: 55px;
    small {
      color: red;
    }
    input {
      border-bottom: 1px solid red;
      width: 100% !important;
      & ~ .catalog-page__header-buttons {
        display: flex !important;
      }
    }
  }
}

.catalog-page__header {
  &-buttons {
    display: none;
    gap: 0.25rem;
    position: absolute;
    top: 3px;
    right: 0;
    button {
      padding: 5px !important;
    }
  }
}
