@import '../../../../scss/custom/mixins';
.projects-page__sidebar {
  border-radius: 24px;
  background-color: white;
  position: relative;
  max-height: 80vh;
  .nav-link {
    padding-bottom: 20px;
    min-width: 150px;
    justify-content: center;
  }

  .justui-range-container {
    margin-top: 6px;
    .justui-range-steps {
      z-index: 1;
      margin-top: 4px;
      align-items: flex-start;
      padding-left: 0;
      padding-right: 0;
      > div {
        width: 1rem;
      }
    }
    input[type='range'] {
      &::-webkit-slider-runnable-track {
        height: 2px;
      }
      &::-webkit-slider-thumb {
        margin-top: -7px;
      }
    }
    // input[type="range"] {
    //   &::-webkit-slider-runnable-track, &::-ms-track, &::-moz-range-track {
    //     height: 2px;
    //   }
    // }
  }

  .justui_tabs.nav {
    padding: 0 1rem;
    margin-top: 20px;
    margin-bottom: 0;
    display: flex;
    flex-wrap: nowrap;
    .nav-item {
      flex-grow: 1;
      a:hover {
        color: initial;
      }
    }
    .nav-item.active {
      border-bottom: 2px solid var(--grey-900) !important;
    }
  }
}

.projects-page__sidebar__body {
  padding: 14px 8px 14px 16px;
}
.project-page__setting-container {
  height: calc(100% - 68px);
  overflow-y: hidden;
  scrollbar-gutter: stable;
  &:hover {
    overflow-y: auto;
  }
  @include scrollbar;
}
