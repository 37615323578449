.catalog-page__sample-create-form {
  padding: 10px 2rem 0.75rem;
  height: 100%;
  button {
    width: 100%;
    border-radius: 1rem;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .catalog-page__sample-create {
    height: 100%;
    display: flex;
    flex-direction: column;
    &-title {
      width: 100%;
      display: flex;
      align-items: center;
      min-height: 39px;
      gap: 1rem;
      width: 100%;
      margin-bottom: 0.5rem;

      input {
        padding-bottom: 3px;

        font-size: 1rem;
        font-weight: 600;
        line-height: 28px;
        color: var(--gray-900);
        width: 100%;

        border: 0;
        border-bottom: 1px solid var(--gray-300);
        &:not(:placeholder-shown) {
          border: 0;
        }
        &:focus,
        &:active {
          border: 0;
          outline: 0;
          border-bottom: 1px solid var(--gray-600);
        }
        &::placeholder {
          color: var(--gray-300);
        }
      }
    }

    &-description {
      margin-bottom: 1.5rem;
      textarea {
        width: 100%;
        font-size: 1rem;
        line-height: 28px;
        color: var(--gray-600);
        padding: 0;
        resize: none;
        overflow: hidden;
        height: 32px;
        border: 0;
        border-bottom: 1px solid var(--gray-300);

        &:not(:placeholder-shown) {
          border: 0;
        }

        &:active,
        &:focus {
          outline: 0;
          border: 0;
          border-bottom: 1px solid var(--gray-600);
        }

        &::placeholder {
          color: var(--gray-500);
        }
      }
    }
    &-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    &-text {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-bottom: 4rem;
      p {
        font-size: 20px;
        line-height: 32px;
        font-weight: 600;
        color: var(--gray=900);
        margin-bottom: 0.75rem;
      }
      textarea {
        flex: 1;
        width: 100%;
        resize: none;
        overflow: hidden;
        border: 1px solid var(--gray-300);
        border-radius: 1.5rem;
        padding: 1rem 1.5rem;
        font-size: 1rem;
        line-height: 28px;
        color: var(--gray-900);
        min-height: 150px;

        &:focus,
        &:active {
          border-color: var(--gray-600);
          outline: 0;
        }
      }
      &.error,
      &.changeError {
        textarea {
          border-color: red;
        }
        span {
          color: red;
        }
      }
      small {
        display: block;
        color: red;
      }
    }
  }
}
