@import '../../../../../scss/custom/mixins';

.my-page__usage-placeholder {
  &.placeholder {
    padding: 5rem;
    border: 1px solid var(--gray-400);
    border-radius: 3.5rem;

    display: grid;
    grid-template-columns: repeat(4, minmax(0, 308px));
    justify-content: space-between;
    gap: 1.5rem;
    row-gap: 68px;
    h1 {
      padding: 2rem;
      grid-column-start: 1;
      grid-column-end: 3;
    }

    @include lg-screen-down {
      grid-template-columns: repeat(3, minmax(277px, 308px));
      padding-top: clamp(2.5rem, 5rem, 5rem);
      padding: 5rem 6.8125rem;
      h1 {
        padding: 2rem 0 0 0;
        grid-column-end: 2;
      }
    }
    @include md-screen-down {
      padding: 2.5rem 2.5rem 2rem 2.5rem;
      row-gap: 1.5rem;
      grid-template-columns: repeat(3, minmax(0, 277px));
      h1 {
        padding: 2.5rem 0 0 1rem;
        @include h2;
        line-height: 36px;
        max-width: 15rem;
      }
    }

    @include tablet-down {
      grid-template-columns: repeat(2, minmax(0, 277px));
      row-gap: 3rem;
      padding: 3.5rem;
      h1 {
        padding: 1.5rem 0 0 0;
        margin-bottom: 1.5rem;
      }
    }

    @include phone-only {
      gap: 0.5rem;
      border-radius: 2.5rem;
      padding: 0.5rem;
      h1 {
        grid-column-end: 3;
        padding: 1.5rem 0 0 1.5rem;
        max-width: 14rem;
        @include h3;
      }
    }
  }

  .placeholder {
    &__photo {
      position: relative;
      margin-bottom: 21px;

      .voice-placeholder,
      img {
        width: 228px;
        height: 228px;
        border-radius: 50%;
      }
    }

    &__card {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      flex-grow: 0;
      background-color: var(--gray-100);
      border-radius: 2.5rem;
      padding: 2rem 2.5rem;
      transition-duration: 0.2s;
      cursor: pointer;
      &:hover {
        background-color: var(--gray-200);

        .placeholder__card-player {
          visibility: visible;
        }
        .placeholder__card-name {
          color: var(--gray-950);
        }
      }
      &-name {
        margin-bottom: 0.5rem;
      }
      .supervoice {
        position: absolute;
        top: 31px;
        left: 31px;
        padding: 0.25rem 0.5rem;
        background-color: #3c54d1;
        border-radius: 0.5rem;
        color: white;
        font-size: 0.75rem;
        line-height: 1rem;
        z-index: 1;
      }
      &-license {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        color: var(--gray-600);
        font-size: 12px;
        line-height: 16px;
        span {
          display: flex;
        }
      }
      &-player {
        visibility: hidden;
        position: absolute;
        display: flex;
        bottom: 8px;
        right: 6px;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        background-color: var(--primary);
        transition-duration: 0.2s;
        span {
          display: flex;
          margin-left: 2px;
        }
        svg {
          color: white !important;
        }

        &:hover {
          transform: scale(1.166);
        }
      }
    }

    &__last-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;

      border-radius: 2.5rem;
      padding: 2.5rem 2rem 2rem 2.5rem;
      border: 1px solid var(--primary);
      transition-duration: 0.2s;

      h2 {
        max-width: 8rem;
        line-height: 36px;
      }

      button {
        align-self: flex-end;
        background-color: var(--primary);
        color: var(--gray-900);
        svg {
          transition: 0.2s;
        }
        &:active {
          background-color: var(--primary) !important;
          border-color: var(--primary);
          svg {
            color: black;
          }
        }
      }

      &:hover {
        background-color: var(--primary);
        button {
          background-color: var(--primary);
          svg {
            transform: rotate(-45deg);
          }
        }
      }
    }

    &__price-block {
      display: flex;
      align-items: center;
      p {
        color: var(--gray-900);
      }
      span {
        color: var(--gray-600);
      }
    }

    @include md-screen-down {
      &__card-player {
        visibility: visible;
      }
      &__photo {
        margin-bottom: 21px;
        .voice-placeholder,
        img {
          width: 198px;
          height: 198px;
        }
      }

      &__last-card {
        h2 {
          line-height: 2rem;
        }
      }
    }

    @include phone-only {
      &__last-card {
        grid-column-start: 1;
        grid-column-end: 3;
        padding: 1.5rem;
        gap: 0.75rem;
        border-radius: 2rem;
        h2 {
          margin: 0;
          line-height: 1.5rem;
          max-width: 6rem;
        }
        button {
          border-radius: 15px;
          padding: 11px !important;
        }
      }
      &__card {
        padding: 1rem 1rem 1.25rem 1rem;
        border-radius: 2rem;

        &-name {
          @include s3;
          line-height: 20px;
          margin-bottom: 0.25rem;
        }
        .supervoice {
          top: 12px;
          left: 12px;
          padding: 0.25rem;
          font-size: 11px;
          line-height: 11px;
        }

        &-license {
          color: var(--gray-950);
          font-size: 10px;
          line-height: 20px;
        }

        &-player {
          width: 2rem;
          height: 2rem;
          bottom: 5px;
          right: 4px;
          svg {
            margin-left: 1px;

            width: 0.75rem !important;
            height: 0.75rem !important;
          }
        }
      }
      &__price-block {
        p,
        span {
          font-size: 12px;
          line-height: 1rem;
        }
      }

      &__photo {
        margin-bottom: 16px;
        .voice-placeholder,
        img {
          width: 116px;
          height: 116px;
        }
      }
    }
  }
}
