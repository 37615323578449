@import '../../../../scss/custom/mixins';

.AiWriter {
  position: absolute;
  top: 0;
  right: 0;
  width: 60%;
  height: 100vh;
  z-index: 10050;
  background-color: white;
  border-radius: 2.5rem 0px 0px 2.5rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1.25rem 1.5rem 2rem;

    border-bottom: 1px solid var(--gray-200);
  }

  &__body {
    width: 100%;
    height: calc(100vh - 81px);
    display: flex;
  }
  &__request {
    padding: 1rem 1rem 1rem 2rem;
    border-right: 1px solid var(--gray-200);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: scroll;
    min-width: fit-content;
    @include scrollbar;

    :global textarea.is-invalid {
      border-color: var(--danger) !important;
      background-color: rgba(193, 70, 70, 0.08);
    }

    :global(.textarea-container) {
      textarea {
        border: 1px solid var(--gray-300);
        border-radius: 15px;
        caret-color: black;
      }
    }
  }
  &__input {
    &-block {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      > p {
        color: var(--gray-600);
      }
    }
    &-count {
      align-self: flex-end;
    }
    &-smalls {
      display: flex;
      justify-content: space-between;
    }
  }

  &__response {
    width: 100%;

    &-header {
      padding: 1rem;
      border-bottom: 1px solid var(--gray-200);
    }
    &-wrapper {
      padding: 0 1rem;
      width: 100%;
      height: calc(100vh - 81px - 56px);
      position: relative;

      overflow-y: scroll;
      @include scrollbar;
    }
  }
  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2.5rem;
    height: 100%;
    > p {
      width: 256px;
    }

    &-text {
      color: var(--gray-600);
    }
  }
  &__placeholder {
    padding: 1rem;
    box-shadow: 0px 1px 20px rgba(44, 46, 113, 0.12);
    border-radius: 0.5rem;
    width: 280px;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    &-top {
      display: flex;
      gap: 0.75rem;
    }
    &-small,
    &-selected {
      width: 60px;
      height: 1rem;
      background: var(--gray-200);
      border-radius: 0.5rem;
    }
    &-selected {
      background: var(--gray-400);
    }
    &-textarea {
      background: var(--gray-200);
      width: 100%;
      border-radius: 0.5rem;
      height: 2.5rem;
    }
  }

  &__item {
    &-wrapper {
      border-bottom: 1px solid var(--gray-200);
      padding: 1rem 0 0 0;
    }
    &-buttons {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 0.75rem;
      :global .btn-success {
        border-color: var(--green);
        background-color: var(--green);
        &:focus {
          background-color: var(--green);
        }
      }
    }
    &-story {
      margin-bottom: 0.5rem;
      white-space: pre-line;
    }
    &-characters {
      margin-bottom: 1rem;
      color: var(--gray-600);
    }
  }
}
