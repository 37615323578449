@import '../../../../../scss/custom/mixins';
.replica-history {
  overflow-y: hidden;
  height: calc(100% - 68px);
  scrollbar-gutter: stable;
  @include scrollbar;

  &:hover {
    overflow-y: auto;
  }
  &::-webkit-scrollbar {
    opacity: 0;
  }

  &:hover {
    &::-webkit-scrollbar {
      opacity: 1;
    }
  }
  &__item {
    display: flex;
    gap: 0.5rem;
    &-avatar {
      width: 2rem;
      height: 2rem;

      img,
      .voice-placeholder {
        border-radius: 50%;

        min-width: 2rem;
        height: 2rem;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0.5rem;
    }

    &-expand {
      display: flex;
      height: 100%;
      align-self: center;

      &:hover {
        background-color: var(--gray-100);
      }
    }

    &-text {
      &-container {
        display: flex;
        gap: 0.25rem;
      }
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      max-height: 4.25em;
      text-overflow: '';

      &.expanded {
        -webkit-line-clamp: unset;
        height: fit-content;
        max-height: 100%;
      }
    }

    &-synth {
      width: 100%;
      display: flex;
      gap: 1rem;
      &-play {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        margin-right: auto;
      }
      .play-button.justui_icon-button.btn.btn-primary:not(.withoutPadding) {
        align-self: flex-start;
        justify-self: center;
        visibility: visible;
        width: 1.5rem;
        height: 1.5rem;
        padding: 7px;
        background-color: var(--gray-100);
        border-color: var(--gray-100);

        &:not(.spinner):not(.playing) {
          padding-left: 8px;
          padding-right: 6px;
        }

        svg {
          visibility: visible;
          width: 0.5rem;
          height: 0.5rem;
        }

        &.was-synthed {
          border-color: var(--primary-300);
          background-color: white !important;
        }
      }
    }

    &-icons {
      cursor: pointer;
    }
  }
  &__actual,
  &__others {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    p {
      color: var(--gray-600);
    }
  }

  &__actual {
    margin-bottom: 1rem;
  }
  &__show-more {
    margin-top: 1.5rem;
    width: 100%;
  }
}
