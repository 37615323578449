@import '../../scss/custom/mixins';

.voice-line__state-wrapper {
  flex: 1;
  display: flex;
  height: 100%;
  align-items: center;
  grid-column-start: 2;
  min-height: 32px;
  padding-left: 4px;
  position: relative;
  &.error {
    flex-wrap: wrap;
    border-radius: 15px;
    background-color: rgba(var(--primary-red-rgb), 0.04);
  }
  .form-control.justui_input-text {
    padding-left: 0;
  }
}

.voice-line--non-selectable {
  width: 100%;
  max-width: calc(100vw - 138px);
  padding-right: 1rem;
  overflow-wrap: break-word;
  @include phone-only {
    padding-right: 0;
    max-width: calc(100vw - 112px);
  }
}

.voice-line__edit-menu {
  position: absolute;
  display: flex;
  background-color: white;
  box-shadow: 0px 1px 20px rgba(44, 46, 113, 0.12);
  border-radius: 15px;
  top: calc(100% + 12px);
  left: -40px;
  z-index: 10;
  .confirm-wrapper {
    margin: 4px 10px 4px 0;
    padding-left: 8px;
    border-left: 1px solid var(--grey-200);
    button.justui_button {
      &.justui_icon-button {
        color: var(--primary);
      }
      &:focus,
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.project__voice-line .play-button__wrapper {
  display: flex;
  align-self: flex-start;
}

.dialog-synth__speaker-modal__speaker-list {
  .dialog-synth__speaker-block {
    @include phone-only {
      .add-speaker-avatar {
        width: 2rem;
        height: 2rem;
        min-width: 2rem;
        margin-right: 6px;
      }
      .play-button.justui_icon-button.btn.btn-primary.btn-square:not(.withoutPadding) {
        padding: 9px;
        line-height: 12px;
        &:not(.spinner):not(.playing) {
          padding-left: 10px;
          padding-right: 8px;
        }
        svg {
          width: 0.75rem;
          height: 0.75rem;
        }
      }
    }
  }
}
