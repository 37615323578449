.Player {
  grid-row: 2/3;
  background-color: white;
  grid-column: 1/5;
  display: grid;
  grid-template-columns: 0.3fr 2.5fr 1fr 0.3fr;

  &__content {
    grid-column-start: 2;
    grid-column-end: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
  }

  &__play {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    .gray-background {
      background-color: gray;
    }
    &__button {
      &-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        height: 2.5rem;
        background-color: var(--gray-200);
        border-radius: 1.25rem;
        padding: 0.25rem;
        z-index: 1;
      }
      padding: 0;
      background-color: white;
      width: 100%;
      height: 100%;
    }
    &__select {
      $select: &;
      cursor: pointer;
      &-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
        background-color: var(--gray-200);
        border-radius: 0 1rem 1rem 0;
        padding: 0.25rem 0.25rem 0.25rem 0.5rem;
        margin-left: -7px;
      }

      &:hover {
        background-color: transparent;
      }

      &-arrow,
      &-text {
        color: var(--gray-600);
      }

      &__dropdown {
        $dropdown: &;
        &.show {
          #{$dropdown}-toggle {
            background-color: var(--gray-600);
          }
          #{$select} {
            &-arrow,
            &-text {
              color: white;
            }
          }
        }
        &-toggle {
          padding: 0;
          display: flex;
          align-items: center;
          gap: 6px;
          background-color: white;
          border-color: transparent;
          padding: 0.25rem 0.5rem;
          &:hover,
          &:focus {
            background-color: transparent;
            border-color: var(--gray-300);
          }
        }
      }

      &-item {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        border-radius: 1rem;
        &.selected {
          svg {
            color: var(--success);
          }
        }
        svg {
          color: transparent;
          &:last-child {
            margin: 0 !important;
          }
        }
      }
    }
  }

  &__progress {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-grow: 1;
    > div {
      width: 100%;
    }
    &.loading {
      :global .audio-progress-bar {
        background: repeating-linear-gradient(
          -45deg,
          var(--gray-600),
          var(--gray-600) 4%,
          var(--gray-200) 5%,
          var(--gray-200) 10%
        );
        background-size: 100px 100px;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-direction: infinite;
        &:local {
          animation-name: move-it;
        }
      }
    }
    :global .audio-progress-bar {
      overflow: hidden;
      background-color: var(--gray-200);
      width: 100%;
      &__progress,
      &__point {
        background-color: var(--primary);
      }
    }
    :global .progress-mouse-tracker {
      height: 8px;
    }
  }

  &__volume {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    &__input {
      -webkit-appearance: none;
      width: 80px;
      height: 3px;
      background-color: var(--gray-200);
      outline: none;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 3px;
        width: 1px;
        background-color: var(--gray-500);
      }
      &::-ms-track {
        background: transparent;
        border-color: transparent;
        color: transparent;
      }
    }

    &__icon {
      display: flex;
    }
  }

  &__download {
    &__dropdown {
      &-button {
        padding: 0.75rem 1.5rem;
        display: flex;
        align-items: center;
        background-color: transparent;
        border: 0;
        gap: 0.75rem;

        p {
          font-size: 14px;
          line-height: 1rem;
          color: var(--gray-900);
        }
        .justui__icon-wrapper {
          width: 1rem;
          height: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        svg {
          color: var(--gray-900);
        }
        :global .just-ui-spinner {
          position: relative !important;
          width: 1rem;
          height: 1rem;
          background-color: transparent !important;
        }

        &.download {
          cursor: wait;
        }
      }

      &-menu {
        transform: translate3d(-243px, -127px, 0px) !important;
        &.two-items {
          transform: translate3d(-243px, -90px, 0px) !important;
        }
      }

      &-item {
        padding: 0.5rem 0.75rem !important;
        border-radius: 0.75rem;
      }
    }
  }
}

@keyframes move-it {
  0% {
    background-position: initial;
  }
  100% {
    background-position: 100px 0px;
  }
}
