@import '../../../../../../scss/custom/mixins';
.preset-select {
  position: relative;
  &__input {
    height: 40px;
    border-radius: 15px;
    padding: 10px;
    border: 1px solid var(--gray-950);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 20px;
    }
    &--placeholder {
      color: var(--gray-500);
    }
  }

  &__menu {
    padding: 4px;
    border: 1px solid var(--gray-100);
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0px 1px 20px rgba(44, 46, 113, 0.12);
    border-radius: 16px;
    background-color: white;
    z-index: 2;

    &.overflow {
      top: auto;
      bottom: 100%;
    }
  }

  &__fixed {
    &-part {
      border-top: 1px solid var(--gray-300);
    }
    &-buttons {
      position: relative;
      padding-top: 0.25rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      button {
        width: 100%;
        line-height: 1.5rem !important;
        padding-top: 7px;
        padding-bottom: 7px;
      }

      &::before {
        position: absolute;
        width: 1px;
        left: 50%;
        transform: translate(-50%);
        height: 32px;
        background-color: var(--gray-300);
        content: '';
      }
    }
  }

  &__list {
    padding-bottom: 0.25rem;
    &-item {
      padding: 0.5rem 0.75rem;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      cursor: pointer;
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .justui__icon-wrapper {
        display: flex;
      }

      &:hover {
        background-color: var(--gray-100);
        border-radius: 0.75rem;
      }
    }

    &-icon {
      min-width: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .ReactVirtualized__List {
    overflow-y: overlay;

    @include scrollbar(4px, 4px, 0.5rem);
    &::-webkit-scrollbar-thumb {
      visibility: hidden;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
  }

  &__empty {
    text-align: center;
    padding: 0.5rem 0;
    color: var(--gray-500);
  }
  &__tooltip {
    .tooltip-inner {
      white-space: nowrap;
      max-width: fit-content;
    }
  }
}
