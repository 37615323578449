@import '../../../../../scss/custom/mixins';
.voice-page {
  &__main {
    flex: 1;
    @include md-screen-down {
      padding: 0 1.5rem;
      margin-top: -0.5rem;
    }
    @include phone-only {
      padding: 0;
      margin-top: -0.75rem;
    }
    .main {
      &__profile {
        display: flex;
        padding-top: 0.5rem;

        &-wrapper {
          border: 1px solid var(--grey-200);
          border-radius: 2rem;
          margin-bottom: 1.5rem;
          padding: 1.5rem 1.5rem 2rem 1.5rem;
          @include phone-only {
            border: 0;
            border-top: 1px solid var(--grey-200);
            border-bottom: 1px solid var(--grey-200);
            border-radius: 0;
            padding: 1rem 1rem 2rem 1rem;
            margin-bottom: 0;
          }
        }

        @include phone-only {
          display: block;
        }
      }

      &__img {
        position: relative;
        height: 22%;
        border-radius: 50%;
        cursor: pointer;

        &::after {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          border-radius: 50%;
          background-color: var(--grey-950);
          transition: opacity ease-in 0.2s;
        }
        &:hover::after {
          opacity: 0.16;
        }
        img {
          border-radius: 50%;
          width: 6.25rem;
          height: 6.25rem;
        }
        img::after {
          content: '';
          height: 100%;
          width: 100%;
        }
        > span {
          position: absolute;
          top: 73%;
          left: 50%;
          transform: translateX(-50%);
          color: white;
          z-index: 1;
        }

        @include phone-only {
          width: fit-content;
          margin-bottom: 1rem;
        }
      }

      &__form {
        width: 100%;
        margin-left: 1.5rem;
        display: flex;
        flex-direction: column;
        label {
          color: var(--secondary);
          margin-bottom: 0.5rem;
        }
        input[type='text'],
        textarea {
          margin-bottom: 1.5rem;
          border-radius: 18px;
        }
        textarea {
          padding-right: 12px;
        }
        .form__radioGroup-label {
          margin-bottom: 0.5rem;
          color: var(--secondary);
        }
        .new-voice__switch {
          margin-bottom: 1.5rem;
        }
        .form {
          &__radioGroup {
            width: 100%;
            display: flex;
            gap: 2.5rem;
            margin-bottom: 1.5rem;
          }
          &__submit {
            margin-top: 1rem;
            padding: 1rem 2rem;
            border-radius: 18px;
            line-height: 1rem;
          }
        }

        @include phone-only {
          margin-left: 0;
          button {
            width: 100%;
          }
        }
      }
    }
  }
}
.justui_input-radioButton-container {
  --bright-color: var(--primary);
  margin-bottom: 0.25rem;
}
