@import '../../scss/custom/variables';
@import '../../scss/custom/mixins';
.header-navbar {
  width: 100%;
  height: 5rem;
  background-color: transparent;
  z-index: 2;
  &.dark {
    background-color: var(--gray-900);
  }
  > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem 0 2rem;
    margin: 0 auto;
    @include tablet-down {
      padding: 0 1rem;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
    @include phone-only {
      padding-right: 1rem;
    }
  }
  .btn-outline-secondary {
    border-radius: 1.25rem;
    margin-right: 1.5rem;
    margin-left: 0.75rem;
  }
  .justui_icon-button {
    margin-right: 0.75rem;
    &:hover {
      color: var(--grey-600);
    }
  }
  .header-navbar__mobile-menu {
    &.justui_icon-button {
      display: none;
    }
  }
  &__tabs-wrapper {
    display: flex;
    align-items: center;
    z-index: 3;
  }
  .logo-container {
    z-index: 3;
    margin-top: 1px;
    width: 8.125rem;
    height: 1.5rem;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  @include tablet-down {
    height: 4rem;
    .header-navbar {
      &__mobile-menu.justui_icon-button {
        display: flex;
        margin-right: 1rem;
        padding: 0.5rem;
        padding: 3px !important;
        svg {
          width: 1.5rem !important;
          height: 1.5rem !important;
        }
      }
      &__notifications-button {
        display: none;
      }
      &__favorite-button {
        margin-left: auto;
        margin-right: 0.5rem;
      }
    }
    .justui_tabs.nav {
      display: none;
    }
    .logo-container {
      img {
        width: 108px;
        height: 20px;
      }
    }
  }

  @include phone-only {
    .header-navbar {
      &__notifications-button {
        display: flex;
        margin-left: auto;
      }
      &__favorite-button {
        display: flex;
        margin-left: 0;
      }
      &__mobile-menu {
        margin-right: 1rem;
      }
    }
    svg.btn-icon.btn-color.justui_icon {
      width: 1rem;
      height: 1rem;
    }
  }
  .user-menu .header-menu__toggle {
    margin-left: 0;
  }
}
