@import './variables';

@mixin phone-only {
  @media (max-width: $screen-width-mobile) {
    @content;
  }
}

@mixin phone-up {
  @media (min-width: $screen-width-mobile) {
    @content;
  }
}

@mixin tablet-down {
  @media (max-width: $screen-width-tablet) {
    @content;
  }
}

@mixin tablet-up {
  @media (min-width: $screen-width-tablet) {
    @content;
  }
}

@mixin lg-screen-down {
  @media (max-width: $screen-width-lg) {
    @content;
  }
}
@mixin md-screen-down {
  @media (max-width: $screen-width-md) {
    @content;
  }
}
@mixin md-screen-up {
  @media (min-width: $screen-width-md) {
    @content;
  }
}
@mixin between-tablet-and-phone {
  @media (max-width: $screen-width-670) {
    @content;
  }
}

@mixin scrollbar($width: 8px, $border: 4px, $margin: 0.5rem, $background: transparent) {
  scrollbar-width: thin;
  scrollbar-color: var(--gray-300) $background;
  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-track {
    background-color: $background;
    margin-top: $margin;
    margin-bottom: $margin;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $background;
    border-radius: $border;
    border-right: $border solid var(--gray-300);
    margin-bottom: $margin;
    padding-right: 0.5rem;
    @include md-screen-down {
      border-right-width: 3px;
    }
  }
}

@mixin display-1 {
  font-size: 5rem;
  line-height: 5.5rem;
  font-weight: 600;
}

@mixin display-2 {
  font-size: 4rem;
  line-height: 4.5rem;
  font-weight: 600;
}

@mixin h1 {
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 600;
}

@mixin h2 {
  font-size: 2rem;
  line-height: 2.75rem;
  font-weight: 600;
}

@mixin h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
}
@mixin h4 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
}

@mixin p1 {
  font-size: 1.5rem;
  line-height: 2.25rem;
}

@mixin p2 {
  font-size: 1.25rem;
  line-height: 2rem;
}

@mixin p3 {
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin p4 {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

@mixin p5 {
  font-size: 0.75rem;
  line-height: 1.25rem;
}

@mixin s1 {
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 600;
}

@mixin s2 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 600;
}

@mixin s3 {
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-weight: 600;
}
