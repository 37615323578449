.catalog-page__header {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  color: var(--gray-950);

  p {
    font-weight: 500;
  }
  .justui_dropdown {
    display: flex;
    align-items: center;
  }
  .justui_dropdown-toggle {
    display: flex;
    align-items: center;
    padding: 0;
    border-radius: 1rem;
    border: 0;
    background-color: white;
    &:hover,
    &:active,
    &:focus {
      background-color: white !important;
    }
    svg {
      color: var(--gray-900);
    }
  }

  .justui_dropdown-menu {
    margin-left: -72px;
    border-color: var(--gray-100);
  }
  &-wrapper {
    max-width: 100%;
  }
}
