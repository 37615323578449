@import '../../../../scss/custom/variables';
@import '../../../../scss/custom/mixins';

a:hover {
  color: currentColor;
  .voice-card__name-block p:first-child {
    color: var(--primary);
  }
}

.card__wrapper {
  display: flex;
  flex: 1 0 33%;
  max-width: 33.33%;
  width: 100%;
  margin-right: -1px;
  margin-bottom: -1px;
  @include tablet-down {
    flex-basis: 50%;
    max-width: 50%;
  }
}
.voice-card__container {
  cursor: pointer;
  position: relative;
  z-index: 0;
  display: flex;
  flex: 1;

  .player__container {
    @include phone-only {
      justify-content: center;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  transition: box-shadow 0.15s ease-in-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  &.just-ui-card {
    border-radius: 0;
    padding: 2rem 1rem 1.5rem;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    flex-grow: 0;
    .block-center {
      max-width: 15.4rem;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    img {
      border-radius: 50%;
      width: 170px;
      height: 170px;
      margin-bottom: 0.5rem;
      object-fit: cover;
      transition: transform 0.45s ease-in-out;
      @include phone-only {
        width: 112px;
        height: 112px;
      }
    }
    @include md-screen-down {
      flex-basis: auto;
      flex-grow: 1;
    }
  }

  .voice-card-icon {
    position: absolute;
    right: 0.75rem;
    top: 11px;
    cursor: pointer;
    &:hover {
      color: var(--danger);
    }
  }
  .super-medal {
    position: absolute;
    left: 0;
    top: 8px;
    font-size: 12px;
    color: white;
    padding: 0.125rem 0.5rem;
    background-color: #3c54d1;
    border-radius: 1rem;
    z-index: 1;
    @include phone-only {
      top: 4px;
    }
  }
  &:hover {
    z-index: 1;
    box-shadow: 0px 1px 20px rgba(44, 46, 113, 0.16);
    img {
      transform: scale(1.1);
    }
  }
  .image-container {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    overflow: hidden;
    @include phone-only {
      width: 112px;
      height: 112px;
    }
  }

  .justui-audio-player .audio-progress-bar {
    width: 100%;
  }

  @include phone-only {
    padding: 1rem !important;
  }
}

.voice-card__header {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 1.1875rem;
  h3 {
    color: var(--grey-dark-30);
  }
}

.voice-card__tags-block {
  margin-top: 0.5rem;
  span {
    font-size: 0.75rem;
  }
}

.voice-card__name-block {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1.5rem;
  svg {
    margin-left: 0.75rem;
    cursor: pointer;
  }
  p {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0;
    &:first-child {
      margin-bottom: 0.25rem;
    }
  }
  @include phone-only {
    margin-top: 0.5rem !important;
    margin-bottom: 0 !important;
    p.st-1 {
      font-size: 12px;
      line-height: 20px;
    }
  }
}
.voice-card__price-block {
  min-height: 24px;
  p {
    color: var(--gray-900);
    display: inline;
  }
  span {
    color: var(--secondary);
    font-size: 1rem;
  }
}

.voice-card__license-block {
  p:first-of-type {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    color: var(--grey-950);
    font-size: 0.75rem;
    font-weight: 500;
  }
  .justui__icon-wrapper {
    display: flex;
    &.question-icon-wrapper {
      width: 18px;
      height: 18px;
      background: var(--grey-100);
      border-radius: 100%;
      margin-left: 0.25rem;
    }
    svg {
      &.question-icon {
        color: var(--grey-600);
        width: 18px;
        height: 18px;
      }
    }
  }
  @include phone-only {
    display: flex;
    align-items: center;
    p:first-of-type {
      font-size: 10px;
    }
  }
}

.voice-card__player {
  margin-bottom: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
  .play-icon-container {
    span {
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;
      flex-shrink: 0;
      cursor: pointer;
      background-color: $orange-200;
      border-radius: 100%;
      transition: background 0.25s linear;
      svg.justui_icon {
        width: 10px;
        height: 10px;
      }
      &:hover {
        background-color: $primary;
        svg.justui_icon {
          color: white;
          transition: color 0.25s linear;
        }
      }
      @include phone-only {
        margin-right: 0 !important;
      }
    }
    &.disabled {
      span {
        background-color: var(--gray-100);
        pointer-events: none;
        svg.justui_icon {
          color: var(--gray-300);
        }
      }
    }
  }
  button {
    color: var(--secondary);
  }
  button:first-of-type {
    margin-left: 0.5rem;
  }
}

.wrapped-tags__toggle {
  cursor: pointer;
  text-decoration: underline dotted black;
  display: inline;
  box-shadow: none !important;
  .dropdown-item {
    padding: 0.25rem 0.5rem;
    &:hover {
      background-color: white;
    }
  }
  .dropdown-menu {
    padding: 0.25rem 0;
  }
  span {
    color: var(--secondary);
  }
}

.spinner {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.player {
  &__container--catalog {
    .audio-progress-bar {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      background-color: var(--grey-300);
      &__progress {
        background-color: var(--primary);
      }
      &__point {
        background-color: transparent;
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
