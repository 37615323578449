@import '../../../../../scss/custom/mixins';

.training-page__steps {
  &-wrapper {
    margin-top: 0.5rem;
    border-radius: 40px;
    background-color: rgba(244, 245, 248, 0.8);
    padding: 2rem;
  }
  &-header {
    margin-bottom: 2rem;
    h3 {
      margin-bottom: 0.5rem;
    }
  }
  &-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 208px;
    gap: 1.5rem;
  }

  &-step {
    padding: 2rem;
    border-radius: 2rem;
    background-color: white;
    display: flex;
    gap: 2rem;
    h1 {
      color: var(--gray-300);
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    flex: 1;

    h4 {
      margin-bottom: 0.5rem;
    }
    p {
      margin-bottom: 1rem;
    }
    a {
      margin-top: auto;
      width: fit-content;
      display: flex;
      align-items: center;
      gap: 6px;
      color: var(--gray-600);
      border-bottom: 1px solid var(--gray-400);
      .justui__icon-wrapper {
        transform: rotate(-45deg);
      }

      &:hover {
        color: var(--gray-600);
      }
    }
  }

  @include md-screen-down {
    &-container {
      grid-auto-rows: 232px;
    }
  }

  @include tablet-down {
    &-container {
      grid-auto-rows: 288px;
    }
    &-step {
      flex-direction: column;
      padding: 1.5rem;
      gap: 1rem;
    }
  }

  @include phone-only {
    &-container {
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
      gap: 1rem;
    }
    &-wrapper {
      padding: 2rem 1rem 2rem 1rem;
    }
    &-header {
      padding: 0 0.5rem;
    }
    &-text {
      p {
        margin-bottom: 1.5rem;
      }
    }
  }
}
