@import '../../../../scss/custom/mixins';
.voice-page {
  &__wrapper {
    background-color: #fff;
  }
  &__container {
    margin: 0 auto;
    min-height: 100vh;
    flex: 1;
    max-width: 1672px;
    padding: 19px 2rem 6.25rem 2rem;
    padding-bottom: 6.25rem;
    display: flex;
    justify-content: space-between;
    position: relative;

    @include md-screen-down {
      flex-direction: column;
      justify-content: unset;
      padding: 0;
      padding-bottom: 3.5rem;
    }
  }

  &__menu {
    margin-right: 2rem;
    flex: 0 0 15rem;
    background-color: white;
    position: sticky;
    top: -1px;
    left: 0;
    img {
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      margin-bottom: 0.5rem;
    }

    @include md-screen-down {
      flex-basis: auto;
      margin-right: 0;
      padding-top: 1rem;
      padding-bottom: 0.25rem;
      margin-bottom: 18px;
      z-index: 2;

      &.sticky {
        z-index: 1000;
        box-shadow: 0px 1px 20px rgba(44, 46, 113, 0.12) !important;
      }
    }
    @include phone-only {
      padding-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    > div:first-child {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 28px;
      @include md-screen-down {
        gap: 0;
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        .voice-placeholder {
          margin-bottom: 0;
        }
        svg {
          margin-right: 2rem;
          color: var(--gray-600);
        }
        img {
          margin-bottom: 0;
        }
        .menu__caption {
          margin-left: 0.75rem;
          margin-bottom: 0;
        }
      }

      @include phone-only {
        padding-left: 1rem;
        margin-bottom: 0.5rem;
        svg {
          margin-right: 1.5rem;
        }
      }
    }

    .menu {
      &__user {
        display: flex;
        gap: 12px;
        align-items: center;
        margin-bottom: 1.75rem;
        @include md-screen-down {
          gap: 0;
          .voice-placeholder {
            min-width: 40px;
          }
        }
      }
      &__caption {
        p {
          margin: 0;
          max-width: 214px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: var(--gray-900);
          @include md-screen-down {
            max-width: 100%;
          }
        }
        p:last-child {
          color: var(--gray-600);
        }

        @include md-screen-down {
          gap: 0;
          width: -webkit-fill-available;
          overflow: hidden;
          p {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
          }
        }
      }

      &__list {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        @include md-screen-down {
          flex-direction: row;
          overflow-x: scroll;

          &::-webkit-scrollbar {
            display: none !important;
          }
        }
      }

      &__item {
        padding: 6px 16px;
        border-radius: 15px;
        margin-left: -0.5rem;
        border: 1px solid transparent;
        width: fit-content;

        &:hover {
          color: currentColor;
        }

        &.active {
          background-color: var(--gray-100);
          font-weight: 600;
        }

        @include md-screen-down {
          font-size: 14px;
          line-height: 20px;
          margin-left: 0;
          padding: 0.5rem 1rem;
          border-radius: 1rem;
          color: var(--gray-600);
          white-space: nowrap;

          &:first-child {
            margin-left: 0.5rem;
          }
          &:last-child {
            margin-right: 0.5rem;
          }

          &.active {
            background-color: var(--gray-100);
            color: var(--gray-900);
            font-weight: 500;
          }
        }
        @include phone-only {
          &:first-child {
            margin-left: 0.25rem;
          }
          &:last-child {
            margin-right: 0.25rem;
          }
        }
      }
    }
  }
}

.changed {
  background-color: #e1f4e8;
  border-radius: 0.25rem;
  padding: 0 1rem;
  display: flex;
  align-items: baseline;
  overflow: hidden;
  visibility: hidden;
  max-height: 0;
  transition: all 1s;
  svg {
    margin-right: 0.75rem;
  }

  &.open {
    padding: 0.5rem 1rem;
    max-height: 2.3rem;
    visibility: visible;
    margin-bottom: 1.5rem;
  }
}

.shadow-pulse {
  border: 1px solid var(--grey-100);
  border-radius: 4px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  animation: pulse-shadow 2s infinite;

  display: flex;
  align-items: center;
  .dot {
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.5rem;
    background-color: var(--primary);
    border-radius: 50%;
  }
}

.menu__item.shadow-pulse {
  margin-left: -1.5rem;
}
@keyframes pulse-shadow {
  0% {
    box-shadow: 0px 1px 20px rgba(44, 46, 113, 0.5);
  }
  50% {
    box-shadow: 0px 1px 20px rgba(44, 46, 113, 0);
  }
  100% {
    box-shadow: 0px 1px 20px rgba(44, 46, 113, 0.5);
  }
}

.justui_input-radioButton-container input:focus ~ .justui_input-radioButton {
  box-shadow: none;
}
