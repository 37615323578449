@import '../custom/functions';
@import '../custom/variables';

$primary: $orange-600;

$btn-box-shadow: $orange-500;

$primary-100: $orange-100;
$primary-200: $orange-200;
$primary-300: $orange-300;
$primary-400: $orange-400;
$primary-500: $orange-500;
$primary-600: $orange-600;
$primary-700: $orange-700;
$primary-800: $orange-800;
$primary-900: $orange-900;
$primary-950: $orange-950;

$grey: #647180;
$red: #ff463a;
$green: #45b700;
$yellow: #ffb800;

$grey-100: #f4f5f8;
$grey-200: #e9ecf1;
$grey-300: #bcc6d2;
$grey-400: #9facbb;
$grey-500: #818f9e;
$grey-600: $grey; // default grey
$grey-700: #485361;
$grey-800: #303c4a;
$grey-900: #1d2937;
$grey-950: #021730;

$grey-100--dark: #021730;
$grey-200--dark: #1d2937;
$grey-300--dark: #303c4a;
$grey-400--dark: #485361;
$grey-500--dark: $grey;
$grey-600--dark: #818f9e; // original grey
$grey-700--dark: #9facbb;
$grey-800--dark: #bcc6d2;
$grey-900--dark: #e9ecf1;
$grey-950--dark: #f4f5f8;

$theme-colors-states--buttons: map-merge(
  $theme-colors-states--buttons,
  (
    'primary': (
      'default': $grey-900,
      'hover': $grey-950,
      'active': $grey-900,
      'disabled': $grey-900,
      'focus': $grey-900,
      'flat-active-bg': $grey-200,
    ),
    'secondary': (
      'default': rgba($grey-400, 0.16),
      'hover': rgba($grey-400, 0.32),
      'active': rgba($grey-400, 0.32),
      'disabled': rgba($grey-400, 0.16),
      'focus': rgba($grey-400, 0.32),
      'flat-active-bg': rgba($grey-400, 0.32),
    ),
    'info': (
      'default': $primary-500,
      'hover': $primary-600,
      'active': $primary-600,
      'disabled': $primary-500,
      'focus': $primary-600,
      'flat-active-bg': $primary-600,
    ),
  )
);

$input-btn-padding-y: 0.6875rem;
$input-btn-padding-x: 1rem;
$icon-btn-padding-x: 0.6875rem;
$input-btn-font-size: 0.875rem;
$input-btn-line-height: 1;

$input-btn-padding-y-sm: 0.4375rem;
$input-btn-padding-x-sm: 0.9375rem;
$input-btn-font-size-sm: $font-size-sm;
$input-btn-line-height-sm: $input-btn-line-height;
$icon-btn-padding-x-sm: $input-btn-padding-y-sm;

$input-btn-padding-y-lg: 0.9375rem;
$input-btn-padding-x-lg: 1.4375rem;
$input-btn-font-size-lg: 0.875rem;
$input-btn-line-height-lg: $input-btn-line-height;
$icon-btn-padding-x-lg: $input-btn-padding-y-lg;

$input-btn-padding-y-xl: 1.1875rem;
$input-btn-padding-x-xl: 1.9375rem;
$input-btn-font-size-xl: 0.875rem;
$input-btn-line-height-xl: $input-btn-line-height;
$icon-btn-padding-x-xl: $input-btn-padding-y-xl;

$border-radius: 18px;
$border-radius-sm: 15px;
$border-radius-lg: 20px;

$btn-border-radius: $border-radius-sm;
$btn-border-radius-sm: 12px;
$btn-border-radius-lg: $border-radius;
$btn-border-radius-xl: $border-radius-lg;

$input-font-size: 1rem;
$input-font-weight: $font-weight-base;
$input-line-height: 1.5;

$input-padding-y-sm: 0.5625rem;
$input-padding-x-sm: $input-btn-padding-x;
$input-font-size-sm: $input-btn-font-size;
$input-line-height-sm: 1.25;

$input-padding-y-lg: $input-btn-padding-x;
$input-padding-x-lg: $input-btn-padding-x;
$input-font-size-lg: $input-font-size;
$input-line-height-lg: $input-line-height;

$input-placeholder-color: $grey-500;

$modal-md: 520px;
$modal-lg: 900px;
$modal-sm: 280px;

.textarea-container {
  position: relative;
  textarea {
    padding-right: 2.25rem;
    border-radius: 3px;
    &:not(.is-invalid):not(.is-valid):not(.is-warning):hover {
      border-color: $grey-200;
    }
    &:not(.is-invalid):not(.is-valid):not(.is-warning):focus {
      border-color: $grey-900;
      box-shadow: none;
    }
  }
}

//general override of components inside button in aimyvoice
button.justui_button.btn {
  display: flex;
  font-weight: normal;
  span.justui__icon-wrapper {
    display: flex;
  }
  svg.btn-icon.justui_icon {
    width: 1rem;
    height: 1rem;
  }
  &:focus {
    box-shadow: none !important;
  }
  &.btn-primary {
    border-color: transparent;
  }
  &.btn-info {
    color: var(--grey-900);
    border-color: transparent;
    &:active {
      color: var(--grey-900);
    }
  }
  &.btn-secondary:not(.dark):not(.btn-flat) {
    color: var(--grey-900);
    border-color: transparent;
    &:active {
      color: var(--grey-900);
    }
  }
  &.disabled,
  &:disabled {
    opacity: 0.4;
    &.btn-primary {
      color: white;
    }
  }
}

.btn.justui_button.justui_icon-button {
  &.btn-sm {
    padding-left: $icon-btn-padding-x-sm;
    padding-right: $icon-btn-padding-x-sm;
  }
  &.btn-lg {
    padding-left: $icon-btn-padding-x-lg;
    padding-right: $icon-btn-padding-x-lg;
  }
  &.btn-xl {
    padding-left: $icon-btn-padding-x-xl;
    padding-right: $icon-btn-padding-x-xl;
  }
}

button.justui_button.btn.btn-outline-primary,
button.justui_button.btn.btn-outline-secondary {
  &:not(:disabled) {
    color: $grey-950;
    border-color: $grey-400;
    &:hover {
      background: transparent;
      border-color: $grey-950;
    }
    &:focus,
    &.focus,
    &:active,
    &.active {
      background: transparent;
      border-color: $grey-950;
    }
  }
  &:disabled {
    color: $grey-400;
  }
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
  color: $grey-950;
  box-shadow: none;
}

.dark {
  button.justui_button.btn.btn-outline-primary,
  button.justui_button.btn.btn-outline-secondary {
    &:not(:disabled) {
      color: white;
      &:hover {
        border-color: white;
      }
      &:focus,
      &.focus,
      &:active,
      &.active {
        color: white;
      }
    }
  }
  .btn-none {
    color: white;
    &:hover {
      color: $grey-300;
    }
  }
}

input.form-control.justui_input-text {
  &:not(.is-invalid):not(.is-valid) {
    border-color: $grey-300;
    &:hover {
      border-color: $grey-300;
    }
    &:active,
    &:focus {
      &:not(:disabled) {
        border-color: $grey-600;
        box-shadow: none;
      }
    }
  }
  &.is-invalid {
    background-color: rgba($danger, 0.08);
  }
  &.is-valid {
    background-color: rgba($success, 0.08);
  }
  &:disabled {
    background-color: $grey-100;
    color: $grey-500;
  }
}

:root {
  --main-spinner-background: rgba(255, 255, 255, 0.9);
  --compact-spinner-background: rgba(255, 255, 255, 1);
}
