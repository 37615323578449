@import '../../scss/custom/mixins';

.projects-page__container {
  flex: 1;
  background-color: var(--projects-grey);
  display: grid;
  grid-template-columns: 0.3fr 2.5fr 1fr 0.3fr;
  grid-template-rows: 1fr 56px;
  max-height: calc(100vh - 4rem);
  @include md-screen-down {
    grid-template-columns: 3fr 1fr;
    .projects-page__lines-block {
      grid-column: 1;
      margin-left: 1.5rem;
    }
  }
  @include tablet-up {
    gap: 1.5rem;
  }
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 40px;
    .projects-page__lines-block {
      margin-left: 0;
      max-height: calc(100vh - 96px);
      padding-top: 18px;
      padding-right: 24px;
      padding-left: 16px;
    }
  }
  @include phone-only {
    grid-template-rows: 1fr 32px;
    .projects-page__lines-block {
      max-height: calc(100vh - 72px);
      padding-top: 12px;
      padding-right: 8px;
      padding-left: 4px;
      border-radius: 24px;
    }
  }
  .projects-page__docs-link {
    margin-left: 1.5rem;
    padding-right: 2rem;
    @include md-screen-down {
      padding-right: 1.5rem;
      margin-left: 1rem;
    }
    @include tablet-down {
      padding: 12px 0;
      margin: 0;
      display: flex;
      justify-content: center;
    }
    @include phone-only {
      padding: 8px 0;
    }
    a:hover {
      color: unset;
    }
    p {
      display: flex;
      align-items: center;
      svg {
        transform: rotate(45deg);
        margin-left: 0.25rem;
      }
    }
    ul {
      list-style: none;
      padding-left: 0;
    }
    li {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
    }
    .links-container {
      @include tablet-up {
        width: 230px;
        padding: 1rem;
        border-radius: 1rem;
        .justui__icon-wrapper {
          display: flex;
          margin-right: 0.25rem;
          padding: 0.25rem;
        }
      }
    }
  }
  .projects-page__content-wrapper {
    display: flex;
  }
}
