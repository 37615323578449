// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Custom above
// Color system
// Colors are separated by themes using modifier syntax: <color-name>--<theme-name>
@use "sass:math";

$font-size-sm: 0.75rem !default;

$white: #fff;
/* --white-5per */
$white-5per: rgba(255, 255, 255, 0.05);
/* --black-4per */
$black-4per: rgba(0, 0, 0, 0.04);
$grey: #6a7178 !default;
$gray: $grey;

$grey-50: #F9FAFC !default;
$grey-100: #f4f5f5 !default;
$grey-200: #e0e1e3 !default;
$grey-300: #c3c6c9 !default;
$grey-400: #a6abaf !default;
$grey-500: #888e93 !default;
$grey-600: $grey; // default grey
$grey-700: #4b535b !default;
$grey-800: #333c45 !default;
$grey-900: #1f2832 !default;
$grey-950: #0d1822 !default;


$grey-100--dark: #0d1822 !default;
$grey-200--dark: #1f2832 !default;
$grey-300--dark: #333c45 !default;
$grey-400--dark: #4b535b !default;
$grey-500--dark: $grey;
$grey-600--dark: #888e93 !default; // original grey
$grey-700--dark: #a6abaf !default;
$grey-800--dark: #c3c6c9 !default;
$grey-900--dark: #e0e1e3 !default;
$grey-950--dark: #f4f5f5 !default;

$gray-50: $grey-50;
$gray-100: $grey-100;
$gray-200: $grey-200;
$gray-300: $grey-300;
$gray-400: $grey-400;
$gray-500: $grey-500;
$gray-600: $grey-600; // default grey
$gray-700: $grey-700;
$gray-800: $grey-800;
$gray-900: $grey-900;
$gray-950: $grey-950;

$gray-100--dark: $grey-100--dark;
$gray-200--dark: $grey-200--dark;
$gray-300--dark: $grey-300--dark;
$gray-400--dark: $grey-400--dark;
$gray-500--dark: $grey-500--dark;
$gray-600--dark: $grey-600--dark; // original grey
$gray-700--dark: $grey-700--dark;
$gray-800--dark: $grey-800--dark;
$gray-900--dark: $grey-900--dark;
$gray-950--dark: $grey-950--dark;

$black: #000; // also original grey darkened by 40%
$black-light: #e7ebed !default;
$black-light30: #4d4d4d;

$greyblue-light: #ecf0f1; // greyblue lighten 10%
$greyblue: #cfd8dc !default;
$greyblue-medium: #283337; // greyblue darken 65%
$greyblue-dark: #1e2528 !default; // greyblue darken 70%
$grey-600-L20: $grey-500;

$grey-custom-default: $greyblue;
$grey-custom-hover: $black-light !default;

$greys: ();
// stylelint-disable-next-line scss/dollar-variable-default
$greys: map-merge(
  (
    "100": $grey-100,
    "200": $grey-200,
    "300": $grey-300,
    "400": $grey-400,
    "500": $grey-500,
    "600": $grey-600,
    "700": $grey-700,
    "800": $grey-800,
    "900": $grey-900,
  ),
  $greys
);

$grays: $greys;

// Basic palette colors

$blue: #2375b0 !default;
$indigo: #605fe3 !default;
$purple: #7E0EBC !default;
$pink: #e83e8c !default;
$red: #c14646 !default;
$orange: #ff753a !default;
$yellow: #e6c323 !default;
$green: #3a7f3a !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

// Basic palette colors tone breakdown

// blue (primary for aimylogic) colors array

$blue-50: #F8F9FC;
$blue-100: #f2f5fb;
$blue-200: #d6e2f2;
$blue-300: #b0c8e6;
$blue-400: #86add9;
$blue-500: #5492cd;
$blue-600: $blue;
$blue-700: #225680;
$blue-800: #1e3e5a;
$blue-900: #182839;
$blue-950: #11181f;

$blue-100--dark: #11181f;
$blue-200--dark: #182839;
$blue-300--dark: #1e3e5a;
$blue-400--dark: #225680;
$blue-500--dark: $blue;
$blue-600--dark: #5492cd;
$blue-700--dark: #86add9;
$blue-800--dark: #b0c8e6;
$blue-900--dark: #d6e2f2;
$blue-950--dark: #f2f5fb;

$aimylogic-100: $blue-100;
$aimylogic-200: $blue-200;
$aimylogic-300: $blue-300;
$aimylogic-400: $blue-400;
$aimylogic-500: $blue-500;
$aimylogic-600: $blue-600;
$aimylogic-700: $blue-700;
$aimylogic-800: $blue-800;
$aimylogic-900: $blue-900;
$aimylogic-950: $blue-950;

$aimylogic-100--dark: $blue-100--dark;
$aimylogic-200--dark: $blue-200--dark;
$aimylogic-300--dark: $blue-300--dark;
$aimylogic-400--dark: $blue-400--dark;
$aimylogic-500--dark: $blue-500--dark;
$aimylogic-600--dark: $blue-600--dark;
$aimylogic-700--dark: $blue-700--dark;
$aimylogic-800--dark: $blue-800--dark;
$aimylogic-900--dark: $blue-900--dark;
$aimylogic-950--dark: $blue-950--dark;

$blues: ();
// stylelint-disable-next-line scss/dollar-variable-default
$blues: map-merge(
  (
    "100": $blue-100,
    "200": $blue-200,
    "300": $blue-300,
    "400": $blue-400,
    "500": $blue-500,
    "600": $blue-600,
    "700": $blue-700,
    "800": $blue-800,
    "900": $blue-900,
  ),
  $blues
);

//indigo (primary for jaicp) colors array

$indigo-100: #f5f4fe;
$indigo-200: #e3defb;
$indigo-300: #c8bff6;
$indigo-400: #aba0f1;
$indigo-500: #8981ea;
$indigo-600: $indigo;
$indigo-700: #4646a8;
$indigo-800: #353374;
$indigo-900: #25224a;
$indigo-950: #171526;

$indigo-100--dark: #171526;
$indigo-200--dark: #25224a;
$indigo-300--dark: #353374;
$indigo-400--dark: #4646a8;
$indigo-500--dark: $indigo;
$indigo-600--dark: #8981ea;
$indigo-700--dark: #aba0f1;
$indigo-800--dark: #c8bff6;
$indigo-900--dark: #e3defb;
$indigo-950--dark: #f5f4fe;

$jaicp-100: $indigo-100;
$jaicp-200: $indigo-200;
$jaicp-300: $indigo-300;
$jaicp-400: $indigo-400;
$jaicp-500: $indigo-500;
$jaicp-600: $indigo-600;
$jaicp-700: $indigo-700;
$jaicp-800: $indigo-800;
$jaicp-900: $indigo-900;
$jaicp-950: $indigo-950;

$jaicp-100--dark: $indigo-100--dark;
$jaicp-200--dark: $indigo-200--dark;
$jaicp-300--dark: $indigo-300--dark;
$jaicp-400--dark: $indigo-400--dark;
$jaicp-500--dark: $indigo-500--dark;
$jaicp-600--dark: $indigo-600--dark;
$jaicp-700--dark: $indigo-700--dark;
$jaicp-800--dark: $indigo-800--dark;
$jaicp-900--dark: $indigo-900--dark;
$jaicp-950--dark: $indigo-950--dark;

$indigos: ();
// stylelint-disable-next-line scss/dollar-variable-default
$indigos: map-merge(
  (
    "100": $indigo-100,
    "200": $indigo-200,
    "300": $indigo-300,
    "400": $indigo-400,
    "500": $indigo-500,
    "600": $indigo-600,
    "700": $indigo-700,
    "800": $indigo-800,
    "900": $indigo-900,
  ),
  $indigos
);

//purple (primary for cloud) colors array

$purple-100: #F9F1FC;
$purple-200: #EDDBF6;
$purple-300: #DCB9EC;
$purple-400: #CA97E3;
$purple-500: #B472D8;
$purple-600: #9C49CC;
$purple-700: $purple;
$purple-800: #5A1682;
$purple-900: #3A1551;
$purple-950: #20102A;

$cloud-100: $purple-100;
$cloud-200: $purple-200;
$cloud-300: $purple-300;
$cloud-400: $purple-400;
$cloud-500: $purple-500;
$cloud-600: $purple-600;
$cloud-700: $purple-700;
$cloud-800: $purple-800;
$cloud-900: $purple-900;
$cloud-950: $purple-950;

$purples: ();
// stylelint-disable-next-line scss/dollar-variable-default
$purples: map-merge(
    (
      "100": $purple-100,
      "200": $purple-200,
      "300": $purple-300,
      "400": $purple-400,
      "500": $purple-500,
      "600": $purple-600,
      "700": $purple-700,
      "800": $purple-800,
      "900": $purple-900,
      "950": $purple-950,
    ),
    $purples
);

// red (danger, error) colors array

$red-100: #fef2f0 !default;
$red-200: #fbdad5 !default;
$red-300: #f4b7af !default;
$red-400: #ea9389 !default;
$red-500: #dc6c63 !default;
$red-600: $red;
$red-700: #8b3635 !default;
$red-800: #682522 !default;
$red-900: #431b18 !default;
$red-950: #25120f !default;

$red-100--dark: #25120f !default;
$red-200--dark: #431b18 !default;
$red-300--dark: #682522 !default;
$red-400--dark: #8b3635 !default;
$red-500--dark: $red;
$red-600--dark: #dc6c63 !default;
$red-700--dark: #ea9389 !default;
$red-800--dark: #f4b7af !default;
$red-900--dark: #fbdad5 !default;
$red-950--dark: #fef2f0 !default;

$reds: ();
// stylelint-disable-next-line scss/dollar-variable-default
$reds: map-merge(
  (
    "100": $red-100,
    "200": $red-200,
    "300": $red-300,
    "400": $red-400,
    "500": $red-500,
    "600": $red-600,
    "700": $red-700,
    "800": $red-800,
    "900": $red-900,
  ),
  $reds
);

// yellow (warning) colors array

$yellow-100: #fff4dc !default;
$yellow-200: #fedf78 !default;
$yellow-300: $yellow;
$yellow-400: #c7a81d !default;
$yellow-500: #a58c16 !default;
$yellow-600: #836f0f !default;
$yellow-700: #615108 !default;
$yellow-800: #473a04 !default;
$yellow-900: #2f2602 !default;
$yellow-950: #1d1701 !default;

$yellow-100--dark: #1d1701 !default;
$yellow-200--dark: #2f2602 !default;
$yellow-300--dark: #473a04 !default;
$yellow-400--dark: #615108 !default;
$yellow-500--dark: #836f0f !default;
$yellow-600--dark: #a58c16 !default;
$yellow-700--dark: #c7a81d !default;
$yellow-800--dark: $yellow;
$yellow-900--dark: #fedf78 !default;
$yellow-950--dark: #fff4dc !default;

$yellows: ();
// stylelint-disable-next-line scss/dollar-variable-default
$yellows: map-merge(
  (
    "100": $yellow-100,
    "200": $yellow-200,
    "300": $yellow-300,
    "400": $yellow-400,
    "500": $yellow-500,
    "600": $yellow-600,
    "700": $yellow-700,
    "800": $yellow-800,
    "900": $yellow-900,
  ),
  $yellows
);

// green (success) colors array

$green-100: #f2f6f3 !default;
$green-200: #d9e4d9 !default;
$green-300: #b3cdb2 !default;
$green-400: #8bb588 !default;
$green-500: #5b9c59 !default;
$green-600: $green;
$green-700: #325c30 !default;
$green-800: #294126 !default;
$green-900: #1d2b1b !default;
$green-950: #121910 !default;

$green-100--dark: #121910 !default;
$green-200--dark: #1d2b1b !default;
$green-300--dark: #294126 !default;
$green-400--dark: #325c30 !default;
$green-500--dark: $green;
$green-600--dark: #5b9c59 !default;
$green-700--dark: #8bb588 !default;
$green-800--dark: #b3cdb2 !default;
$green-900--dark: #d9e4d9 !default;
$green-950--dark: #f2f6f3 !default;

$greens: ();
// stylelint-disable-next-line scss/dollar-variable-default
$greens: map-merge(
  (
    "100": $green-100,
    "200": $green-200,
    "300": $green-300,
    "400": $green-400,
    "500": $green-500,
    "600": $green-600,
    "700": $green-700,
    "800": $green-800,
    "900": $green-900,
  ),
  $greens
);

// orange (aimyvoice primary) colors array

$orange-100: #fff5f2;
$orange-200: #ffe3cf;
$orange-300: #ffba94;
$orange-400: #ff8c5a;
$orange-500: $orange;
$orange-600: #FF530A;
$orange-700: #9f2600;
$orange-800: #751b00;
$orange-900: #511300;
$orange-950: #340b00;

$orange-100--dark: #340b00;
$orange-200--dark: #511300;
$orange-300--dark: #751b00;
$orange-400--dark: #9f2600;
$orange-500--dark: #FF530A;
$orange-600--dark: $orange;
$orange-700--dark: #ff8c5a;
$orange-800--dark: #ffba94;
$orange-900--dark: #ffe3cf;
$orange-950--dark: #fff5f2;

$oranges: ();
// stylelint-disable-next-line scss/dollar-variable-default
$oranges: map-merge(
  (
    "100": $orange-100,
    "200": $orange-200,
    "300": $orange-300,
    "400": $orange-400,
    "500": $orange-500,
    "600": $orange-600,
    "700": $orange-700,
    "800": $orange-800,
    "900": $orange-900,
  ),
  $oranges
);

$blue-dark15: #1e7899;

$blue-light: #e5f4f7;
$blue-light35: #c3e6f3;

$yellow-light35: #fcefb5;

$green-dark: #004330;
$green-light35: #d3eedc;

$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "yellow-200": $yellow-200,
    "yellow-100": $yellow-100,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "grey": $grey-600,
    "grey-dark": $grey-800,
    "greyblue": $greyblue,
    "grey-dark-10": $grey-700,
    "grey-dark-20": $grey-800,
    "grey-dark-30": $grey-900,
    "grey-light-40": $grey-300,
    "grey-light-55": $grey-100,
    "black-light": $black-light,
    "greyblue-dark": $greyblue-dark,
    "black-light-30": $black-light30,
  ),
  $colors
);

$primary: $blue !default;
$primary-light: $blue-light !default;
$primary-light-20: $blue-400 !default;
$secondary: $grey-600 !default;
$success: $green;
$success-dark: $green-dark !default;
$success-light35: $green-light35 !default;
$success-light-20: $green-400 !default;
$info: $blue !default; //$cyan
$warning: $yellow !default;
$warning-light-20: $yellow-200 !default;
$danger: $red;
$danger-light-20: $red-400 !default;
$light: $grey-100 !default;
$dark: $grey-800 !default;
$dark-bg: $greyblue-medium !default;

$primary-100: $blue-100 !default;
$primary-200: $blue-200 !default;
$primary-300: $blue-300 !default;
$primary-400: $blue-400 !default;
$primary-500: $blue-500 !default;
$primary-600: $blue-600 !default;
$primary-700: $blue-700 !default;
$primary-800: $blue-800 !default;
$primary-900: $blue-900 !default;
$primary-950: $blue-950 !default;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
  ),
  $theme-colors
);

$theme-colors-states--buttons: () !default;
$theme-colors-states--buttons: map-merge(
  (
    "primary": (
      "default": $primary,
      "hover": $primary-700,
      "active": $primary-800,
      "disabled": $grey-300,
      "focus": $primary-500,
      "flat-active-bg": $primary-200,
    ),
    "secondary": (
      "default": $secondary,
      "hover": rgba(0, 0, 0, 0.04),
      "active": $grey-200,
      "disabled": $grey-300,
      "focus": $primary-500,
      "flat-active-bg": $grey-200,
    ),
    "success": (
      "default": $success,
      "hover": $green-700,
      "active": $green-800,
      "disabled": $grey-300,
      "focus": $green-700,
      "flat-active-bg": $green-200,
    ),
    "info": (
      "default": $info,
      "hover": $blue-700,
      "active": $blue-800,
      "disabled": $grey-300,
      "focus": $blue-700,
      "flat-active-bg": $blue-200,
    ),
    "warning": (
      "default": $warning,
      "hover": $yellow-400,
      "active": $yellow-500,
      "disabled": $grey-300,
      "focus": $yellow-400,
      "flat-active-bg": $yellow-200,
    ),
    "danger": (
      "default": $danger,
      "hover": $red-700,
      "active": $red-800,
      "disabled": $grey-300,
      "focus": $red-700,
      "flat-active-bg": $red-200,
    ),
    "light": (
      "default": $primary,
      "hover": $primary-700,
      "active": $primary-800,
      "disabled": $grey-300,
      "focus": $primary-700,
      "flat-active-bg": $yellow-200,
    ),
    "dark": (
      "default": $primary,
      "hover": $primary-700,
      "active": $primary-800,
      "disabled": $grey-300,
      "focus": $primary-700,
      "flat-active-bg": $yellow-200,
    ),
  ),
  $theme-colors-states--buttons
);

$theme-colors--dark: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors--dark: map-merge(
  (
    "primary": $primary-light-20,
    "secondary": $secondary,
    "success": $success-light-20,
    "info": $info,
    "warning": $warning-light-20,
    "danger": $danger-light-20,
    "light": $light,
    "dark": $dark,
  ),
  $theme-colors--dark
);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8%;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
//$yiq-contrasted-threshold:  150 ;
$yiq-contrasted-threshold: 170;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $grey-950;
$yiq-text-light: $white;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true;
$enable-rounded: true;
$enable-shadows: false !default;
$enable-gradients: false;
$enable-transitions: true;
$enable-prefers-reduced-motion-media-query: true;
$enable-hover-media-query: false; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes: true;
$enable-pointer-cursor-for-buttons: true;
$enable-print-styles: true;
$enable-responsive-font-sizes: false;
$enable-validation-icons: false;
$enable-validation-icons-textarea: false;
$enable-deprecation-messages: true;


$input-number-spin-buttons-mode: 'hover' !default; // possible values: always | hover | none

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.125,
    ),
    2: (
      $spacer * 0.25,
    ),
    3: (
      $spacer * 0.5,
    ),
    4: $spacer,
    5: (
      $spacer * 1.5,
    ),
    6: (
      $spacer * 2,
    ),
    7: (
      $spacer * 3,
    ),
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto,
  ),
  $sizes
);

// Body
//
// Settings for the `<body>` element.

$body-bg: #e4e5e6;
$body-color: $black;

// Links
//
// Style anchor elements.

$link-color: theme-color("primary");
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;
$link-base-line-height: calc(20rem/16);
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15%;

// Paragraphs
//
// Style p element.

$paragraph-font-size: calc(14rem/16);
$paragraph-line-height: calc(20rem/16);
$paragraph-margin-bottom: 1rem;

$letter-spacing-base: 0.01em;
$letter-spacing-small: 0.0075em;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1380px,
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 30px;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.5;
$line-height-sm: 1.5;

$border-width: 1px;
$border-color: $grey-300;

$border-radius-sm: 0.2rem !default;
$border-radius: 0.25rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-xl: 0.2rem !default;

$rounded-pill: 50rem;

$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow: 0 0.5rem 1rem rgba($black, 0.15);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);

$component-active-color: $white;
$component-active-bg: theme-color("primary");

$caret-width: 0.3em;
$caret-vertical-align: $caret-width * 0.85;
$caret-spacing: $caret-width * 0.85;

$transition-base: all 0.2s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;

$embed-responsive-aspect-ratios: ();
// stylelint-disable-next-line scss/dollar-variable-default
$embed-responsive-aspect-ratios: join(
  ((21 9), (16 9), (4 3), (1 1)),
  $embed-responsive-aspect-ratios
);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
  Helvetica Neue, Arial, sans-serif;
$font-family-base: $font-family-sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace;

// stylelint-enable value-keyword-case

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-body: $font-size-base * 0.875; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * 0.75;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$h1-font-size: $font-size-base * 1.5;
$h2-font-size: $font-size-base * 1.25;
$h3-font-size: $font-size-base;

$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$h1-line-height: $font-size-base * 1.75;
$h2-line-height: $font-size-base * 1.5;
$h3-line-height: $font-size-base * 1.5;

$h1-font-weight: 700;
$h2-font-weight: 700;
$h3-font-weight: 500;

$headings-margin-bottom: 0;
$headings-font-family: null;
$headings-font-weight: 500;
$headings-line-height: 1.2;
$headings-color: null;

$display1-size: 2rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;

$display1-weight: 500;
$display2-weight: 300;
$display3-weight: 300;
$display4-weight: 300;

$display-line-height: $font-size-base * 2.5;

$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 300;

$small-font-size: calc(12rem/16);
$small-line-height: $font-size-base;
$small-letter-spacing: $letter-spacing-base;

$text-muted: $grey-600;

$blockquote-small-color: $grey-600;
$blockquote-small-font-size: $small-font-size;
$blockquote-font-size: $font-size-base * 1.25;

$hr-border-color: rgba($black, 0.1);
$hr-border-width: $border-width;

$mark-padding: 0.2em;

$dt-font-weight: $font-weight-bold;

$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25);
$nested-kbd-font-weight: $font-weight-bold;

$list-inline-padding: 0.5rem;

$mark-bg: #fcf8e3;

$hr-margin-y: $spacer;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-head-font-size: 0.875rem;
$table-cell-padding: 0.75rem;
$table-cell-padding-sm: 0.3rem;

$table-color: $body-color;
$table-bg: null;
$table-accent-bg: rgba($black, 0.05);
$table-hover-color: $table-color;
$table-hover-bg: rgba($black, 0.075);
$table-active-bg: $table-hover-bg;

$table-border-width: $border-width;
$table-border-color: $grey-100;

$table-head-bg: $grey-100;
$table-head-color: $grey-700;

$table-dark-color: $white;
$table-dark-bg: $grey-800;
$table-dark-accent-bg: rgba($white, 0.05);
$table-dark-hover-color: $table-dark-color;
$table-dark-hover-bg: rgba($white, 0.075);
$table-dark-border-color: lighten($table-dark-bg, 7.5%);
$table-dark-color: $white;

$table-striped-order: odd;

$table-caption-color: $text-muted;

$table-bg-level: -9;
$table-border-level: -6;

$table-header-line-height: 17px;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.375rem !default;
$input-btn-padding-x: 0.775rem !default;
$icon-btn-padding-x: 0.625rem !default;
$input-btn-font-family: null;
$input-btn-font-size: 0.875rem !default;
$input-btn-line-height: 1.5 !default;

$input-btn-focus-width: 2px;
$input-btn-focus-color: rgba($component-active-bg, 0.3);
$input-btn-focus-color--dark: darken($component-active-bg, 15%);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;
$input-btn-focus-box-shadow--dark: 0 0 0 $input-btn-focus-width
  $input-btn-focus-color--dark;

$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-padding-x-sm: 0.5rem !default;
$input-btn-font-size-sm: $font-size-sm !default;
$input-btn-line-height-sm: 1.25 !default;

$input-btn-padding-y-lg: 0.5rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-font-size-lg: 1rem !default;
$input-btn-line-height-lg: 1.75 !default;

$input-btn-padding-y-xl: 0.5rem !default;
$input-btn-padding-x-xl: 1rem !default;
$input-btn-font-size-xl: 1rem !default;
$input-btn-line-height-xl: 1.75 !default;

$input-btn-border-width: $border-width;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;
$btn-line-height-sm: $input-btn-line-height-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;
$btn-line-height-lg: $input-btn-line-height-lg;

$btn-padding-y-xl: $input-btn-padding-y-xl;
$btn-padding-x-xl: $input-btn-padding-x-xl;
$btn-font-size-xl: $input-btn-font-size-xl;
$btn-line-height-xl: $input-btn-line-height-xl;

$icon-btn-padding-x-sm: $input-btn-padding-y-sm;
$icon-btn-padding-x-lg: $input-btn-padding-y-lg;
$icon-btn-padding-x-xl: $input-btn-padding-y-xl;


$btn-border-width: $input-btn-border-width;

$btn-font-weight: $font-weight-normal;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075);
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: 1;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125);

$btn-link-disabled-color: $grey-600;

$btn-block-spacing-y: 0.5rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-sm: $border-radius-sm !default;
$btn-border-radius-lg: $border-radius-lg !default;
$btn-border-radius-xl: $border-radius-xl !default;


$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

// Forms

$label-margin-bottom: 0.5rem;

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-font-family: $input-btn-font-family;
$input-font-size: $input-btn-font-size;
$input-font-weight: $font-weight-base !default;
$input-line-height: $input-btn-line-height !default;

$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-font-size-sm: $input-btn-font-size-sm !default;
$input-line-height-sm: $input-btn-line-height-sm !default;

$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-font-size-lg: $input-btn-font-size-lg !default;
$input-line-height-lg: $input-btn-line-height-lg !default;

$input-bg: $white;
$input-disabled-bg: white;
$input-disabled-border: $grey-200;

$input-color: $black;
$input-border-color: $grey-200;
$input-border-width: $input-btn-border-width;
$input-box-shadow: inset 0 1px 1px rgba($black, 0.075);

$input-border-radius: $border-radius;
$input-border-radius-lg: $border-radius-lg;
$input-border-radius-sm: $border-radius-sm;

$input-focus-bg: $input-bg;
$input-focus-border-color: $component-active-bg;
$input-focus-border-color--dark: lighten($component-active-bg, 20%) !default;
$input-focus-color: $input-color;
$input-focus-width: 0.25rem;
$input-focus-box-shadow: $input-btn-focus-box-shadow;
$input-focus-box-shadow--dark: $input-btn-focus-box-shadow--dark;

$input-placeholder-color: lighten($black, 40%) !default;
$input-placeholder-color--dark: $grey;
$input-plaintext-color: $body-color;

$input-height-border: $input-border-width * 2;

$input-height-inner-double: calc(
  #{$input-line-height * 2em} + #{$input-padding-y * 4}
);
$input-height-inner-double-negative: calc(
  #{$input-line-height * -2em} + #{$input-padding-y * -4} - 1px
);
$input-height-inner: calc(
  #{$input-line-height * 1em} + #{$input-padding-y * 2}
);
$input-height-inner-negative: calc(
  #{$input-line-height * -1em} + #{$input-padding-y * -2} - 1px
);
$input-height-inner-half: calc(
  #{$input-line-height * 0.5em} + #{$input-padding-y}
);
$input-height-inner-quarter: calc(
  #{$input-line-height * 0.25em} + #{$input-padding-y * 0.5}
);

$input-height: calc(
  #{$input-line-height * 1rem} + #{$input-padding-y * 2} + #{$input-height-border}
);
$input-height-sm: calc(
  #{$input-line-height-sm * 1rem} + #{$input-padding-y-sm * 2} + #{$input-height-border}
);

$input-height-sm-inner: calc(
  #{$input-line-height-sm * 1em} + #{$input-btn-padding-y-sm * 2}
);
$input-height-sm-inner-negative: calc(
  #{$input-line-height-sm * -1em} + #{$input-btn-padding-y-sm * -2} - 1px
);
$input-height-sm-inner-double: calc(
  #{$input-line-height-sm * 2em} + #{$input-btn-padding-y-sm * 4}
);
$input-height-sm-inner-double-negative: calc(
  #{$input-line-height-sm * -2em} + #{$input-btn-padding-y-sm * -4} - 1px
);
$input-height-sm-inner-quarter: calc(
  #{$input-line-height-sm * 0.25em} + #{$input-btn-padding-y-sm * 0.5}
);

$input-height-lg: calc(
  #{$input-line-height-lg * 1rem} + #{$input-btn-padding-y-lg * 2} + #{$input-height-border}
);

$input-height-lg-inner: calc(
  #{$input-line-height-lg * 1em} + #{$input-btn-padding-y-lg * 2}
);
$input-height-lg-inner-negative: calc(
  #{$input-line-height-lg * -1em} + #{$input-btn-padding-y-lg * -2} - 1px
);
$input-height-lg-inner-double: calc(
  #{$input-line-height-lg * 2em} + #{$input-btn-padding-y-lg * 4}
);
$input-height-lg-inner-double-negative: calc(
  #{$input-line-height-lg * -2em} + #{$input-btn-padding-y-lg * -4} - 1px
);
$input-height-lg-inner-quarter: calc(
  #{$input-line-height-lg * 0.25em} + #{$input-btn-padding-y-lg * 0.5}
);

$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

$form-text-margin-top: 0.25rem;
$form-text-margin-bottom: 0.5rem;

$form-check-input-gutter: 1.25rem;
$form-check-input-margin-y: 0.3rem;
$form-check-input-margin-x: 0.25rem;

$form-check-inline-margin-x: 0.75rem;
$form-check-inline-input-margin-x: 0.3125rem;

$form-grid-gutter-width: 10px;
$form-group-margin-bottom: 1.875rem; // default 1rem
$form-group-margin-bottom-lg: 1rem; // default 1rem

$input-group-addon-color: $input-color;
$input-group-addon-bg: #e7ebed;
$input-group-addon-border-color: $input-border-color;

$custom-forms-transition: background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

$custom-control-gutter: 0.5rem;
$custom-control-spacer-x: 1rem;

$custom-control-indicator-size: 0.875rem;
$custom-control-indicator-bg: $input-bg;

$custom-control-indicator-bg-size: 70% 70%;
$custom-control-indicator-box-shadow: $input-box-shadow;
$custom-control-indicator-border-color: lighten($black, 40%);
$custom-control-indicator-border-width: $input-border-width;

$custom-control-indicator-disabled-bg: $input-disabled-bg;
$custom-control-label-disabled-color: $grey-300;

$custom-control-indicator-checked-color: $component-active-color;
$custom-control-indicator-checked-bg: $component-active-bg;
$custom-control-indicator-checked-disabled-bg: lighten($blue, 35%);
$custom-control-indicator-checked-disabled-color: $grey-600;
$custom-control-indicator-checked-box-shadow: none;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg;

$custom-control-indicator-focus-box-shadow: $input-focus-box-shadow;
$custom-control-indicator-focus-border-color: $input-focus-border-color;

$custom-control-indicator-active-color: $component-active-color;
$custom-control-indicator-active-bg: lighten($component-active-bg, 35%);
$custom-control-indicator-active-box-shadow: none;
$custom-control-indicator-active-border-color: $custom-control-indicator-active-bg;

$custom-checkbox-indicator-border-radius: 0.125rem;
$custom-checkbox-indicator-icon-checked: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 8'%3e%3cpath fill='#{$custom-control-indicator-checked-color}' d='M2.76029 7.58379L0.533822 4.70248C0.00994742 3.34041 1.13628 2.86892 1.97448 3.65473L3.41513 5.48829L7.86807 0.511484C8.65388 -0.313618 9.96357 0.157869 9.43969 1.42826L4.20095 7.45282C3.63778 8.10767 3.1532 7.9767 2.76029 7.58379Z'/%3e%3c/svg%3e"),
  "#",
  "%23"
);
$custom-checkbox-indicator-icon-checked-disabled: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 8'%3e%3cpath fill='#{$custom-control-indicator-checked-disabled-color}' d='M2.76029 7.58379L0.533822 4.70248C0.00994742 3.34041 1.13628 2.86892 1.97448 3.65473L3.41513 5.48829L7.86807 0.511484C8.65388 -0.313618 9.96357 0.157869 9.43969 1.42826L4.20095 7.45282C3.63778 8.10767 3.1532 7.9767 2.76029 7.58379Z'/%3e%3c/svg%3e"),
  "#",
  "%23"
);

$custom-checkbox-indicator-indeterminate-bg: $component-active-bg;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color;
$custom-checkbox-indicator-icon-indeterminate: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3e%3c/svg%3e"),
  "#",
  "%23"
);
$custom-checkbox-indicator-indeterminate-box-shadow: none;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg;

$custom-radio-indicator-border-radius: 50%;
$custom-radio-indicator-icon-checked: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3e%3c/svg%3e"),
  "#",
  "%23"
);

$custom-switch-width-md: 40px;
$custom-switch-height-md: 20px;
$custom-switch-width-sm: 32px;
$custom-switch-height-sm: 16px;
$custom-switch-indicator-focus-border-color: theme-color("primary");

$custom-select-padding-y: $input-padding-y;
$custom-select-padding-x: $input-padding-x;
$custom-select-font-family: $input-font-family;
$custom-select-font-size: $input-font-size;
$custom-select-height: $input-height;
$custom-select-indicator-padding: 1rem; // Extra padding to account for the presence of the background-image based indicator
$custom-select-font-weight: $input-font-weight;
$custom-select-line-height: $input-line-height;
$custom-select-color: $input-color;
$custom-select-disabled-color: $grey-600;
$custom-select-bg: $input-bg;
$custom-select-disabled-bg: $grey-200;
$custom-select-bg-size: 8px 10px; // In pixels because image dimensions
$custom-select-indicator-color: $grey-800;
$custom-select-indicator: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e"),
  "#",
  "%23"
);
$custom-select-background: $custom-select-indicator no-repeat right
  $custom-select-padding-x center / $custom-select-bg-size; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

$custom-select-feedback-icon-padding-right: calc(
  (1em + #{2 * $custom-select-padding-y}) * 3 / 4 + #{$custom-select-padding-x +
    $custom-select-indicator-padding}
);
$custom-select-feedback-icon-position: center right
  ($custom-select-padding-x + $custom-select-indicator-padding);
$custom-select-feedback-icon-size: $input-height-inner-half
  $input-height-inner-half;

$custom-select-border-width: $input-border-width;
$custom-select-border-color: $input-border-color;
$custom-select-border-radius: $border-radius;
$custom-select-box-shadow: inset 0 1px 2px rgba($black, 0.075);

$custom-select-focus-border-color: $input-focus-border-color;
$custom-select-focus-width: $input-focus-width;
$custom-select-focus-box-shadow: 0 0 0 $custom-select-focus-width
  $input-btn-focus-color;

$custom-select-padding-y-sm: $input-padding-y-sm;
$custom-select-padding-x-sm: $input-padding-x-sm;
$custom-select-font-size-sm: $input-font-size-sm;
$custom-select-height-sm: $input-height-sm;

$custom-select-padding-y-lg: $input-padding-y-lg;
$custom-select-padding-x-lg: $input-padding-x-lg;
$custom-select-font-size-lg: $input-font-size-lg;
$custom-select-height-lg: $input-height-lg;

$custom-range-track-width: 100%;
$custom-range-track-height: 0.5rem;
$custom-range-track-cursor: pointer;
$custom-range-track-bg: $grey-300;
$custom-range-track-border-radius: 1rem;
$custom-range-track-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1);

$custom-range-thumb-width: 1rem;
$custom-range-thumb-height: $custom-range-thumb-width;
$custom-range-thumb-bg: $component-active-bg;
$custom-range-thumb-border: 0;
$custom-range-thumb-border-radius: 1rem;
$custom-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1);
$custom-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg,
  $input-focus-box-shadow;
$custom-range-thumb-focus-box-shadow-width: $input-focus-width; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg: lighten($component-active-bg, 35%);
$custom-range-thumb-disabled-bg: $grey-500;

$custom-file-height: $input-height;
$custom-file-height-inner: $input-height-inner;
$custom-file-focus-border-color: $input-focus-border-color;
$custom-file-focus-box-shadow: $input-focus-box-shadow;
$custom-file-disabled-bg: $input-disabled-bg;

$custom-file-padding-y: $input-padding-y;
$custom-file-padding-x: $input-padding-x;
$custom-file-line-height: $input-line-height;
$custom-file-font-family: $input-font-family;
$custom-file-font-weight: $input-font-weight;
$custom-file-color: $input-color;
$custom-file-bg: $input-bg;
$custom-file-border-width: $input-border-width;
$custom-file-border-color: $input-border-color;
$custom-file-border-radius: $input-border-radius;
$custom-file-box-shadow: $input-box-shadow;
$custom-file-button-color: $custom-file-color;
$custom-file-button-bg: $input-group-addon-bg;
$custom-file-text: (
  en: "Browse",
);

// Form validation

$custom-checkbox-indicator-border-color:        $grey-600 !default;



$form-feedback-margin-top: $form-text-margin-top;
$form-feedback-font-size: $small-font-size;
$form-feedback-valid-color: theme-color("success");
$form-feedback-valid-color--dark: theme-color--dark("success");
$form-feedback-invalid-color: theme-color("danger");
$form-feedback-invalid-color--dark: theme-color--dark("danger");
$form-feedback-warning-color: theme-color("warning");
$form-feedback-warning-color--dark: theme-color--dark("warning");

$form-feedback-icon-disabled-color: #737373;
$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid-color--dark: $form-feedback-valid-color--dark;
$form-feedback-icon-valid: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"),
  "#",
  "%23"
);
$form-feedback-icon-valid--dark: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$form-feedback-icon-valid-color--dark}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"),
  "#",
  "%23"
);
$form-feedback-icon-valid-disabled: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$form-feedback-icon-disabled-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"),
  "#",
  "%23"
);

$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid-color--dark: $form-feedback-invalid-color--dark;
$form-feedback-icon-invalid: str-replace(
  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>"),
  "#",
  "%23"
);
$form-feedback-icon-invalid--dark: str-replace(
  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color--dark}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color--dark}' stroke='none'/></svg>"),
  "#",
  "%23"
);
$form-feedback-icon-invalid-disabled: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-feedback-icon-disabled-color}' viewBox='-2 -2 7 7'%3e%3cpath stroke='#{$form-feedback-icon-disabled-color}' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"),
  "#",
  "%23"
);

$form-feedback-icon-warning-color: $form-feedback-warning-color;
$form-feedback-icon-warning-color--dark: $form-feedback-warning-color--dark;
$form-feedback-icon-warning: str-replace(
  url("data:image/svg+xml,%3csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='exclamation-triangle' class='svg-inline--fa fa-exclamation-triangle fa-w-18 ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3e%3cpath fill='#{$form-feedback-icon-warning-color}' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'%3e%3c/path%3e%3c/svg%3E"),
  "#",
  "%23"
);
$form-feedback-icon-warning--dark: str-replace(
  url("data:image/svg+xml,%3csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='exclamation-triangle' class='svg-inline--fa fa-exclamation-triangle fa-w-18 ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3e%3cpath fill='#{$form-feedback-icon-warning-color--dark}' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'%3e%3c/path%3e%3c/svg%3E"),
  "#",
  "%23"
);
$form-feedback-icon-warning-disabled: str-replace(
  url("data:image/svg+xml,%3csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='exclamation-triangle' class='svg-inline--fa fa-exclamation-triangle fa-w-18 ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3e%3cpath fill='#{$form-feedback-icon-disabled-color}' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'%3e%3c/path%3e%3c/svg%3E"),
  "#",
  "%23"
);

$form-validation-states: ();
// stylelint-disable-next-line scss/dollar-variable-default
$form-validation-states: map-merge(
  (
    "valid": (
      "color": $form-feedback-valid-color,
      "icon": $form-feedback-icon-valid,
      "icon--dark": $form-feedback-icon-valid--dark,
      "icon-disabled": $form-feedback-icon-valid-disabled,
    ),
    "warning": (
      "color": $form-feedback-warning-color,
      "icon": $form-feedback-icon-warning,
      "icon--dark": $form-feedback-icon-warning--dark,
      "icon-disabled": $form-feedback-icon-warning-disabled,
    ),
    "invalid": (
      "color": $form-feedback-invalid-color,
      "icon": $form-feedback-icon-invalid,
      "icon--dark": $form-feedback-icon-invalid--dark,
      "icon-disabled": $form-feedback-icon-invalid-disabled,
    ),
  ),
  $form-validation-states
);

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;

// Navs

$nav-link-padding-y: 0.5rem;
$nav-link-padding-x: 1rem;
$nav-link-disabled-color: $grey-600;

$nav-tabs-border-color: $grey-300;
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: $grey-200 $grey-200 $nav-tabs-border-color;
$nav-tabs-link-active-color: $grey-700;
$nav-tabs-link-active-bg: $body-bg;
$nav-tabs-link-active-border-color: $grey-300 $grey-300 $nav-tabs-link-active-bg;

$nav-pills-border-radius: $border-radius;
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

$nav-divider-color: $grey-200;
$nav-divider-margin-y: $spacer * 0.5;

// Navbar

$navbar-padding-y: $spacer * 0.5;
$navbar-padding-x: $spacer;

$navbar-nav-link-padding-x: 0.5rem;

$navbar-brand-font-size: $font-size-lg;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) * 0.5;

$navbar-toggler-padding-y: 0.25rem;
$navbar-toggler-padding-x: 0.75rem;
$navbar-toggler-font-size: $font-size-lg;
$navbar-toggler-border-radius: $btn-border-radius;

$navbar-dark-color: rgba($white, 0.5);
$navbar-dark-hover-color: rgba($white, 0.75);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, 0.25);
$navbar-dark-toggler-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
  "#",
  "%23"
);
$navbar-dark-toggler-border-color: rgba($white, 0.1);

$navbar-light-color: rgba($black, 0.5);
$navbar-light-hover-color: rgba($black, 0.7);
$navbar-light-active-color: rgba($black, 0.9);
$navbar-light-disabled-color: rgba($black, 0.3);
$navbar-light-toggler-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
  "#",
  "%23"
);
$navbar-light-toggler-border-color: rgba($black, 0.1);

$navbar-light-brand-color: $navbar-light-active-color;
$navbar-light-brand-hover-color: $navbar-light-active-color;
$navbar-dark-brand-color: $navbar-dark-active-color;
$navbar-dark-brand-hover-color: $navbar-dark-active-color;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 10rem;
$dropdown-padding-y: 0.5rem;
$dropdown-spacer: 0.125rem;
$dropdown-font-size: $font-size-base;
$dropdown-color: $body-color;
$dropdown-bg: $white;
$dropdown-border-color: rgba($black, 0.15);
$dropdown-border-radius: $border-radius;
$dropdown-border-width: $border-width;
$dropdown-inner-border-radius: calc(
  #{$dropdown-border-radius} - #{$dropdown-border-width}
);
$dropdown-divider-bg: $grey-200;
$dropdown-divider-margin-y: $nav-divider-margin-y;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175);

$dropdown-link-color: $grey-900;
$dropdown-link-hover-color: darken($grey-900, 5%);
$dropdown-link-hover-bg: $grey-100;

$dropdown-link-active-color: $component-active-color;
$dropdown-link-active-bg: $component-active-bg;

$dropdown-link-disabled-color: $grey-600;

$dropdown-item-padding-y: 0.25rem;
$dropdown-item-padding-x: 1.5rem;

$dropdown-header-color: $grey-600;

// Pagination

$pagination-padding-y: 0.5rem;
$pagination-padding-x: 0.75rem;
$pagination-padding-y-sm: 0.25rem;
$pagination-padding-x-sm: 0.5rem;
$pagination-padding-y-lg: 0.75rem;
$pagination-padding-x-lg: 1.5rem;
$pagination-line-height: 1.25;

$pagination-color: $link-color;
$pagination-bg: $white;
$pagination-border-width: $border-width;
$pagination-border-color: $grey-300;

$pagination-focus-box-shadow: $input-btn-focus-box-shadow;
$pagination-focus-outline: 0;

$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $grey-200;
$pagination-hover-border-color: $grey-300;

$pagination-active-color: $component-active-color;
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $pagination-active-bg;

$pagination-disabled-color: $grey-600;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: $grey-300;

// Jumbotron

$jumbotron-padding: 2rem;
$jumbotron-color: null;
$jumbotron-bg: $grey-200;

// Cards

$card-spacer-y: 0.75rem;
$card-spacer-x: 1.25rem;
$card-border-width: $border-width;
$card-border-radius: $border-radius;
$card-border-color: rgba($black, 0.125);
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg: rgba($black, 0.03);
$card-cap-color: null;
$card-color: null;
$card-bg: $white;

$card-img-overlay-padding: 1.25rem;

$card-group-margin: $grid-gutter-width * 0.5;
$card-deck-margin: $card-group-margin;

$card-columns-count: 3;
$card-columns-gap: 1.25rem;
$card-columns-margin: $card-spacer-y;

// Tooltips

$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 200px;
$tooltip-color: $white;
$tooltip-bg: lighten($black, 30%);
$tooltip-border-radius: $border-radius;
$tooltip-opacity: 1;
$tooltip-padding-y: 0.25rem;
$tooltip-padding-x: 0.5rem;
$tooltip-margin: 0;

$tooltip-arrow-width: 0.8rem;
$tooltip-arrow-height: 0.4rem;
$tooltip-arrow-color: $tooltip-bg;

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y: $tooltip-padding-y;
$form-feedback-tooltip-padding-x: $tooltip-padding-x;
$form-feedback-tooltip-font-size: $tooltip-font-size;
$form-feedback-tooltip-line-height: $line-height-base;
$form-feedback-tooltip-opacity: $tooltip-opacity;
$form-feedback-tooltip-border-radius: $tooltip-border-radius;

// Popovers

$popover-font-size: $font-size-sm;
$popover-bg: $white;
$popover-max-width: 276px;
$popover-border-width: $border-width;
$popover-border-color: rgba($black, 0.2);
$popover-border-radius: $border-radius-lg;
$popover-box-shadow: 0 0.25rem 0.5rem rgba($black, 0.2);

$popover-header-bg: darken($popover-bg, 3%);
$popover-header-color: $headings-color;
$popover-header-padding-y: 0.5rem;
$popover-header-padding-x: 0.75rem;

$popover-body-color: $body-color;
$popover-body-padding-y: $popover-header-padding-y;
$popover-body-padding-x: $popover-header-padding-x;

$popover-arrow-width: 1rem;
$popover-arrow-height: 0.5rem;
$popover-arrow-color: $popover-bg;

$popover-arrow-outer-color: fade-in($popover-border-color, 0.05);

// Toasts

$toast-max-width: 350px;
$toast-padding-x: 0.75rem;
$toast-padding-y: 0.25rem;
$toast-font-size: 0.875rem;
$toast-color: null;
$toast-background-color: rgba($white, 0.85);
$toast-border-width: 1px;
$toast-border-color: rgba(0, 0, 0, 0.1);
$toast-border-radius: 0.25rem;
$toast-box-shadow: 0 0.25rem 0.75rem rgba($black, 0.1);

$toast-header-color: $grey-600;
$toast-header-background-color: rgba($white, 0.85);
$toast-header-border-color: rgba(0, 0, 0, 0.05);

// Badges

$badge-font-size: 75%;
$badge-font-weight: $font-weight-bold;
$badge-padding-y: 0.25em;
$badge-padding-x: 0.4em;
$badge-border-radius: $border-radius;

$badge-transition: $btn-transition;
$badge-focus-width: $input-btn-focus-width;

$badge-pill-padding-x: 0.6em;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius: 10rem;

// Modals

// Padding applied to the modal body
$modal-inner-padding: 1.5rem;

$modal-dialog-margin: 0.5rem;
$modal-dialog-margin-y-sm-up: 1.75rem;

$modal-title-line-height: 1.5rem;

$modal-content-color: null;
$modal-content-bg: $white;
$modal-content-border-color: rgba($black, 0.2);
$modal-content-border-width: 0px; //$border-width ;
$modal-content-border-radius: 3px; //$border-radius-lg ;
$modal-content-box-shadow-xs: 0px 0px 1px rgba(17, 60, 92, 0.06),
  0px 6px 12px rgba(12, 43, 66, 0.16), 0px 0px 2px rgba(0, 0, 0, 0.1); //0 .25rem .5rem rgba($black, .5) ;
$modal-content-box-shadow-sm-up: 0px 0px 1px rgba(17, 60, 92, 0.06),
  0px 6px 12px rgba(12, 43, 66, 0.16), 0px 0px 2px rgba(0, 0, 0, 0.1); //0 .5rem 1rem rgba($black, .5) ;

$modal-backdrop-bg: rgba($black, 0.5); // $black;
$modal-backdrop-opacity: 1;
$modal-header-border-color: $border-color;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: 0px; //$modal-content-border-width ;
$modal-footer-border-width: 0px; //$modal-header-border-width ;
$modal-header-padding-y: 1.5rem;
$modal-header-padding-x: 1.5rem;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

$modal-header-close-padding: 1rem;

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 300px !default;

$modal-fade-transform: translate(0, -50px);
$modal-show-transform: none;
$modal-transition: transform 0.3s ease-out;
$modal-close-icon-padding: 0.75rem;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: 0.75rem;
$alert-padding-x: 1.25rem;
$alert-margin-bottom: 1rem;
$alert-border-radius: $border-radius;
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: $border-width;

$alert-bg-level: -10;
$alert-border-level: -9;
$alert-color-level: 6;

// Progress bars

$progress-height: 1rem;
$progress-font-size: $font-size-base * 0.75;
$progress-bg: $grey-200;
$progress-border-radius: $border-radius;
$progress-box-shadow: inset 0 0.1rem 0.1rem rgba($black, 0.1);
$progress-bar-color: $white;
$progress-bar-bg: theme-color("primary");
$progress-bar-animation-timing: 1s linear infinite;
$progress-bar-transition: width 0.6s ease;

// List group

$list-group-color: null;
$list-group-bg: $white;
$list-group-border-color: rgba($black, 0.125);
$list-group-border-width: $border-width;
$list-group-border-radius: $border-radius;

$list-group-item-padding-y: 0.75rem;
$list-group-item-padding-x: 1.25rem;

$list-group-hover-bg: $grey-100;
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border-color: $list-group-active-bg;

$list-group-disabled-color: $grey-600;
$list-group-disabled-bg: $list-group-bg;

$list-group-action-color: $grey-700;
$list-group-action-hover-color: $list-group-action-color;

$list-group-action-active-color: $body-color;
$list-group-action-active-bg: $grey-200;

// Image thumbnails

$thumbnail-padding: 0.25rem;
$thumbnail-bg: $body-bg;
$thumbnail-border-width: $border-width;
$thumbnail-border-color: $grey-300;
$thumbnail-border-radius: $border-radius;
$thumbnail-box-shadow: 0 1px 2px rgba($black, 0.075);

// Figures

$figure-caption-font-size: 90%;
$figure-caption-color: $grey-600;

// Breadcrumbs

$breadcrumb-padding-y: 0.2rem; // .75rem
$breadcrumb-padding-x: 1rem;
$breadcrumb-item-padding: 0.5rem;

$breadcrumb-margin-bottom: 1rem;

$breadcrumb-bg: $white; //$grey-200 ;
$breadcrumb-divider-color: $grey-600;
$breadcrumb-active-color: $grey-600;
$breadcrumb-divider: quote("/");

$breadcrumb-border-radius: $border-radius;

// Carousel

$carousel-control-color: $white;
$carousel-control-width: 15%;
$carousel-control-opacity: 0.5;
$carousel-control-hover-opacity: 0.9;
$carousel-control-transition: opacity 0.15s ease;

$carousel-indicator-width: 30px;
$carousel-indicator-height: 3px;
$carousel-indicator-hit-area-height: 10px;
$carousel-indicator-spacer: 3px;
$carousel-indicator-active-bg: $white;
$carousel-indicator-transition: opacity 0.6s ease;

$carousel-caption-width: 70%;
$carousel-caption-color: $white;

$carousel-control-icon-width: 20px;

$carousel-control-prev-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"),
  "#",
  "%23"
);
$carousel-control-next-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"),
  "#",
  "%23"
);

$carousel-transition-duration: 0.6s;
$carousel-transition: transform $carousel-transition-duration ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

// Spinners

$spinner-width: 2rem;
$spinner-height: $spinner-width;
$spinner-border-width: 0.25em;

$spinner-width-sm: 1rem;
$spinner-height-sm: $spinner-width-sm;
$spinner-border-width-sm: 0.2em;

// Close

$close-font-size: $font-size-base * 1.5;
$close-font-weight: $font-weight-bold;
$close-color: $black;
$close-text-shadow: 0 1px 0 $white;

// Code

$code-font-size: 87.5%;
$code-color: $pink;

$kbd-padding-y: 0.2rem;
$kbd-padding-x: 0.4rem;
$kbd-font-size: $code-font-size;
$kbd-color: $white;
$kbd-bg: $grey-900;

$pre-color: $grey-900;
$pre-scrollable-max-height: 340px;

// Utilities

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex,
  inline-flex;
$overflows: auto, hidden;
$positions: static, relative, absolute, fixed, sticky;

// Printing

$print-page-size: a3;
$print-body-min-width: map-get($grid-breakpoints, "lg");

// Popover

$popover-font-size: 0.875rem;
$popover-font-size-small: 0.75rem;
$popover-padding-large: 0.75rem;
$popover-padding-small: 0.5rem;
$popover-btn-close-padding: 0 0.625rem 0.375rem;
$popover-border-color: $greyblue;
$popover-box-shadow: 0px 0px 1px rgba(17, 60, 92, 0.06),
  0px 6px 12px rgba(12, 43, 66, 0.16), 0px 0px 2px rgba(0, 0, 0, 0.1);
