@import '../../../../scss/custom/variables';
@import '../../../../scss/custom/mixins';

.projects-page__lines-block {
  border-radius: 24px;
  background-color: white;
  padding: 0 0.75rem 1.5rem 0.75rem;
  grid-column-start: 2;
  max-height: 80vh;
  overflow-y: auto;
  overflow-anchor: none;
  display: flex;
  flex-direction: column;
  @include scrollbar(4px, 4px, 2rem);
  //hide scrollbar in default state
  &::-webkit-scrollbar-thumb {
    //all browsers besides firefox
    border-right-color: transparent;
    transition: border-color 0.5s;
  }
  scrollbar-color: transparent transparent; //firefox
  transition: scrollbar-color 0.5s;
  &.scrolling {
    &::-webkit-scrollbar-thumb {
      border-right-color: var(--gray-300);
    }
    scrollbar-color: var(--grey-300) transparent; //firefox
  }
  .project__voice-line {
    display: grid;
    grid-template-columns: 36px 1fr 24px 20px;
    gap: 8px;
    padding: 6px 0;
    margin-right: 0;
    margin-left: 1.5rem;
    border-radius: 1rem;
    @include tablet-down {
      grid-template-columns: 36px 1fr 32px;
      margin-left: 0.5rem;
    }

    .justui_dropdown.dropdown {
      align-self: flex-start;
    }
    &:hover {
      .justui_dropdown.dropdown .speaker-block__menu-button {
        visibility: visible;
      }
    }
    .add-speaker-avatar {
      width: 2rem;
      height: 2rem;
      min-width: 2rem;
      border-radius: 0.75rem;
      background-color: var(--grey-100);
      grid-column-start: 1;
      align-self: flex-start;
      margin-right: 0.5rem;
      &:hover {
        background-color: var(--grey-200);
      }
      svg.fa-plus {
        color: var(--grey-600);
      }
      .voice-placeholder {
        width: 100%;
        height: 100%;
        min-width: unset;
        svg {
          margin: 0;
          position: absolute;
          top: 50%;
        }
      }

      > span {
        display: flex;
      }
    }
    .play-button.justui_icon-button.btn.btn-primary:not(.withoutPadding) {
      align-self: flex-start;
      justify-self: center;
      visibility: visible;
      width: 1.5rem;
      height: 1.5rem;
      padding: 7px !important;
      margin-top: 4px;

      &:not(.spinner):not(.playing) {
        padding-left: 8px !important;
        padding-right: 6px !important;
      }

      svg {
        visibility: visible;
        width: 0.5rem;
        height: 0.5rem;
      }
      @include tablet-down {
        width: 2rem;
        height: 2rem;
        padding: 9px;
        margin-top: 0;

        &:not(.spinner):not(.playing) {
          padding-left: 10px;
          padding-right: 8px;
        }
        svg {
          width: 0.75rem;
          height: 0.75rem;
        }
      }
      &.was-synthed {
        border-color: var(--primary-300);
        background-color: white !important;
      }
      &.disabled {
        cursor: not-allowed;
      }
    }
    textarea.form-control {
      font-size: 1rem;
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 0;
      margin-right: 0;
      padding-right: 4rem;
      @include tablet-down {
        padding-right: 1rem;
        position: relative;
      }
      @include phone-only {
        padding-right: 0;
      }
      cursor: text;
    }
    &--deleting {
      grid-column: 1 / 4;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      min-height: 44px;
      margin-left: 8px;

      background-color: var(--projects-grey);
      border-radius: 1rem;
      @include phone-only {
        grid-template-columns: 3fr 2fr;
        margin-left: 16px;
        padding-left: 12px;
      }
      .btn {
        margin-left: auto;
      }
      p {
        grid-column-start: 2;
        align-self: center;
        span {
          display: inline-block;
        }
        @include phone-only {
          grid-column-start: 1;
        }
        @include phone-up {
          text-align: center;
        }
      }
      button {
        color: var(--grey-500);
      }
    }
    .justui_dropdown-menu.dropdown-menu.show {
      &.project-voiceline__speakers-dropdown {
        min-width: 360px;
        max-height: 440px;
        padding: 0.25rem;
        overflow-y: auto;
        @include scrollbar(4px, 4px, 1rem, white);
        padding-right: 0;
        overscroll-behavior: contain;
      }
      .dialog-synth__speaker-modal__speaker-list {
        margin: 0;
        padding: 0;
        padding-bottom: 24px;
        .dialog-synth__speaker-block {
          margin: 0;
          border-radius: 1rem;
          padding-right: 0.5rem;
          &:hover {
            .play-button {
              background-color: white !important;
            }
          }
          .play-button {
            align-self: center;
            margin-left: auto;
            margin-top: 0;
            svg {
              margin: 0;
              position: static;
            }
          }
          p {
            max-width: 250px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
    .add-speaker-avatar {
      padding: 0;
    }
    &.project-voiceline__placeholder {
      @include tablet-down {
        padding-bottom: 3rem;
      }
    }
  }
  .speaker-block__menu-button {
    border-radius: 6px;
    padding: 2px;
    border: 0;
    &:focus {
      background-color: var(--grey-100);
    }
    svg {
      color: var(--grey-950);
    }
  }
  .justui_dropdown.dropdown.speaker-block__menu-dropdown {
    &.show {
      .speaker-block__menu-button {
        visibility: visible;
      }
    }
    .speaker-block__menu-button {
      visibility: hidden;
    }
    align-self: flex-start;
    margin-top: 4px;
  }
  .placeholder-focus-block {
    min-height: 200px;
    flex: 1;
    margin: 0 -12px -24px;
    cursor: text;
  }
}

.projects-page__draggable-line {
  display: flex;
  padding-left: 8px;
  .projects-page__drag-handle {
    margin-top: 0.7125rem;
    align-self: flex-start;
    width: 1rem;
    height: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      visibility: hidden;
    }
  }
  &:hover {
    .projects-page__drag-handle span {
      visibility: visible;
    }
  }
  .justui__icon-wrapper {
    display: flex;

    svg.justui_icon.svg-inline--fa.fa-color-secondary {
      color: var(--grey-500);
      &:focus {
        outline: none;
      }
    }
  }
  .dialog-synth__speaker-block {
    flex: 1;
    flex-basis: 100%;
    padding-left: 0.5rem;
    margin-left: 1.5rem;
    &.project__voice-line {
      margin-left: 0;
    }
  }
  &.selected-for-markup {
    border-radius: 8px;
    outline: 1px solid var(--grey-200);
  }
}

.projects-voicelines__droppable-area {
  margin-left: -8px;
  padding-top: 8px;
  @include tablet-down {
    padding-top: 0;
  }
}

//styles for circling timer

.countdown {
  position: relative;
  margin: auto;
  height: 20px;
  width: 20px;
  text-align: center;
  margin-right: 0.5rem;
  svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    transform: rotateY(-180deg) rotateZ(-90deg);
  }

  svg circle {
    stroke-dasharray: 67px;
    stroke-dashoffset: 0px;
    stroke-linecap: round;
    stroke-width: 1px;
    stroke: var(--grey-950);
    fill: none;
    animation: countdown 5s linear forwards;
  }
}

.countdown-number {
  color: var(--grey-950);
  display: inline-block;
  line-height: 20px;
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 67px;
  }
}

.projects-page__replica-header {
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}
