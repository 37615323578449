@import '../../../../scss/custom/mixins';

.my-page {
  &__wrapper {
    max-width: 1672px;
    width: 100%;
    margin: 0 auto 0 auto;
    padding: 45px 0 125px 0;
    display: flex;
    flex-direction: column;
    gap: 4rem;

    h1 {
      .orange {
        color: var(--primary);
      }
    }

    &.reverse {
      flex-direction: column-reverse;
    }

    @include md-screen-down {
      padding-bottom: 105px;
    }

    @include tablet-down {
      padding-top: 2rem;
    }
    @include phone-only {
      gap: 50px;

      padding-top: 13px;
      padding-bottom: 62px;
    }
  }

  &__usage {
    margin: 0 2.25rem;
    h2 {
      margin-bottom: 2.25rem;
    }
    @include lg-screen-down {
      margin: 0 5rem;
    }

    @include md-screen-down {
      margin: 0 2rem;
      h2 {
        @include h3;
        margin-bottom: 2rem;
      }
    }

    @include tablet-down {
      margin-bottom: 2.25rem;
      margin: 0 1.5rem;
      h2 {
        margin-bottom: 1.5rem;
      }
    }

    @include phone-only {
      margin: 0;
      h2 {
        @include h4;
        margin-left: 1rem;
        margin-bottom: 1rem;
      }
    }
  }

  &__manage {
    margin: 0 2.25rem 0 2.25rem;
    &-header {
      margin-bottom: 2.25rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-container {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-auto-rows: 10.75rem;
      gap: 1.5rem;
      padding-top: 4px;
    }

    &-moreBtn {
      display: flex;
      justify-content: center;
      margin-top: 2.5rem;
      button {
        padding: 20px 40px;
      }
    }

    &-create {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      color: var(--gray-500);
      border-radius: 2rem;
      cursor: pointer;
      transition: 0.2s;
      border: 1px dashed var(--gray-300);
      &:hover {
        box-shadow: 0px 1px 20px rgba(44, 46, 113, 0.12);
      }
    }

    @include lg-screen-down {
      margin: 0 5rem;
      &-container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      &-header {
        margin-bottom: 2rem;
      }
    }
    @include md-screen-down {
      margin: 0 2rem;
      &-moreBtn {
        margin-top: 2rem;
      }
      h2 {
        @include h3;
      }
      &-header {
        margin-bottom: 2rem;
      }
    }

    @include tablet-down {
      margin: 0 1.5rem;

      &-header {
        margin-bottom: 1.5rem;
      }
      &-container {
        grid-template-columns: minmax(0, 1fr);
        gap: 1rem;
      }
      &-moreBtn {
        margin-top: 1.5rem;
        button {
          padding: 1rem 2rem;
        }
      }
    }

    @include phone-only {
      margin: 0;

      &-header {
        button {
          margin-right: 1rem;
        }
      }
      &-container {
        margin: 0 1rem;
        gap: 0.5rem;
        padding-top: 0;
      }
      &-moreBtn {
        margin: 1rem 1rem 0 1rem;
        button {
          display: flex;
          flex: 1;
        }
      }
      h2 {
        @include h4;
        margin-left: 1rem;
      }
      &-header {
        margin-bottom: 1rem;
      }

      &-create {
        padding-top: 8px;
        h4 {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  &__projects {
    margin: 0 2rem;
    overflow-anchor: none;
    &-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 1fr;
      gap: 1.5rem;
    }

    &-header {
      margin-bottom: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__buttons {
        display: flex;
        align-items: center;
        gap: 1rem;
        button:first-of-type {
          color: var(--gray-900) !important;
          padding-right: 1.5rem;
          .iconLeft {
            margin-right: 8px;
          }
          &:hover,
          &:active {
            background-color: rgba(159, 172, 187, 0.16) !important;
          }

          &:active {
            outline: none;
            border-color: transparent !important;
          }
        }
      }
    }
    &-moteBtn {
      display: flex;
      justify-content: center;
      margin-top: 2.5rem;
      button {
        padding: 20px 40px;
      }
    }

    @include lg-screen-down {
      margin: 0 5rem;
      &-container {
        gap: 1.25rem;
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
      &-header {
        margin-bottom: 2rem;
      }
    }

    @include md-screen-down {
      margin: 0 2rem;
      &-moreBtn {
        margin-top: 2rem;
      }
      h2 {
        @include h3;
      }
      &-header {
        margin-bottom: 2.25rem;
      }
    }

    @include tablet-down {
      margin: 0 1.5rem;

      &-container {
        grid-template-columns: 1fr;
        gap: 1rem;
      }
      &-header {
        margin-bottom: 1.5rem;
      }
      &-moreBtn {
        margin-top: 1.5rem;
        button {
          padding: 1rem 2rem;
        }
      }
    }
    @include phone-only {
      margin: 0;

      &-header {
        padding-top: 3px;
        button {
          margin-right: 1rem;
          padding: 7px !important;
          border-radius: 12px !important;
        }
      }
      &-container {
        margin: 0 1rem;
        gap: 0.5rem;
        padding-top: 0;
      }
      &-moreBtn {
        margin: 1rem 1rem 0 1rem;
        button {
          display: flex;
          flex: 1;
        }
      }
      h2 {
        @include h4;
        margin-left: 1rem;
      }
      &-header {
        margin-bottom: 1rem;
      }

      &-create {
        padding-top: 8px;
        h4 {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}

.project-header__input {
  display: flex;
  flex-direction: column;
  input {
    font-size: 1rem;
    line-height: 1.5rem;
    height: 40px;
  }
  p {
    color: var(--primary-red);
  }
  &.error {
    justify-self: flex-end;
  }
}

.my-page__projects,
.my-page__manage {
  &.order-1 {
    order: 1;
  }
  &.order-2 {
    order: 2;
  }
}
