.pagination {
  &__container {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    button.justui_button.btn-flat:enabled {
      color: black;
      box-shadow: none !important;
    }
    button.justui_button.btn-flat:disabled:not(.pagination__button.nav_button) {
      color: white;
      background-color: var(--gray-900);
      box-shadow: none !important;
    }
  }
  &__dots-block {
    align-self: center;
    margin-top: -1px;
  }
}
