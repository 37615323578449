@import '../../../../../scss/custom/mixins';

.catalog-page__catalog {
  padding: 0rem 0 0 1.5rem;
  width: 100%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &.scroll {
    padding-right: 3px;
  }
  a:hover {
    color: var(--gray-900);
  }

  &-row {
    padding: 0.75rem;
    padding-left: 0.6rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    border-radius: 0.75rem;
    overflow: hidden;

    &:hover {
      background-color: var(--gray-100);
    }

    &-text {
      width: 95%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 3px;
      p {
        display: block;
        width: fit-content;
      }
      p:first-child {
        max-width: 38%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      p:last-child {
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      p:not(:first-child) {
        color: var(--gray-500);
      }
    }
    &.active {
      background-color: var(--gray-100);
    }
  }

  &-errors {
    padding: 1rem 0.75rem 0.5rem 0.75rem;
    > div {
      padding: 0.25rem 0.75rem;
      display: flex;
      width: fit-content;
      align-items: center;
      gap: 0.5rem;
      border-radius: 0.75rem;
      background-color: var(--gray-100);
      transition: all 0.2s;
      cursor: pointer;

      &:hover,
      &.active {
        background-color: var(--gray-900);
        color: white;
        > div {
          background-color: white;
        }
      }

      > div {
        display: block;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background-color: red;
      }
    }
  }
  .ReactVirtualized__Grid.ReactVirtualized__List {
    border: 0;
    padding-top: 0.5rem;
    outline: 0;
    @include scrollbar(8px, 4px);
  }
}
