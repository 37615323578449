@import '../../../../../scss/custom/mixins';
.voice-page {
  &__banner {
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: space-between;
    button {
      border-radius: 20px;
      padding: 1.25rem 2.5rem;
      line-height: 1rem;

      @include phone-only {
        padding: 20px 0;
        width: 100%;
      }
    }
    &-usage {
      box-sizing: border-box;
      padding: 2.5rem 4rem 2.5rem 2.5rem;
      max-height: none;
      h2 {
        font-size: 1.5rem;
      }
      button {
        padding: 0.5rem 1.5rem;
      }
    }

    background: radial-gradient(
      148.96% 2785.64% at -26.56% -19.94%,
      rgba(236, 79, 53, 0.08) 19.98%,
      rgba(246, 149, 97, 0.08) 34.99%,
      rgba(145, 118, 251, 0.08) 100%
    );
    border-radius: 2rem;

    h3 {
      padding-bottom: 10px;
    }
    .banner {
      &__text {
        display: flex;
        flex-direction: column;
        border: 1px solid transparent;
        overflow: hidden;
        p {
          max-width: 24rem;
          margin: 0;
        }
      }
      &__inner {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;
        margin: 40px 48px;

        @include tablet-down {
          flex-direction: column;
          align-items: flex-start;
          gap: 40px;
        }

        @include phone-only {
          margin: 1.5rem;
          gap: 3rem;

          h4 {
            margin-bottom: 1rem;
          }
        }
      }
    }

    @include phone-only {
      margin: 2rem 1rem 0 1rem;
      a {
        width: 100%;
      }
    }
  }
  &__add-to-catalog {
    position: relative;
    border: 1px solid var(--gray-200);
    border-radius: 2rem;
    overflow: hidden;
    h4 {
      margin-bottom: 1.5rem;
    }

    @include phone-only {
      padding: 1.5rem 1rem;
      border: 0;
      border-radius: 0;
      border-top: 1px solid var(--gray-200);
      margin-top: -1px;
    }
    .add-to-catalog {
      &.publication::after {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: white;
      }
      &__header {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        a {
          display: flex;
          align-items: center;
          gap: 0.25rem;
          color: black;
          text-decoration: underline;
          text-decoration-color: var(--grey-400);
        }
        svg {
          width: 0.625rem;
          height: 0.625rem;
          transform: rotate(45deg);
        }

        @include phone-only {
          flex-direction: column;
          margin-bottom: 1rem;

          h4 {
            margin-bottom: 1rem;
          }
        }
      }
      &__price {
        max-width: 553px;
        min-height: 4.25rem;
        display: flex;
        align-items: baseline;
        margin-bottom: 1rem;

        p {
          white-space: nowrap;
        }
        p:first-child {
          margin-right: 1rem;
        }
        p:last-child {
          margin-left: 1rem;
        }

        @include tablet-down {
          flex-direction: column;
          max-width: unset;
        }
      }
      &__break {
        width: 100%;
        height: 1px;
        background-color: var(--grey-200);
        margin-bottom: 1.5rem;
      }
      &__price-input {
        display: flex;
        flex-direction: column;
        min-width: 290px;
        input {
          border-radius: 18px;
          font-size: 16px;
          line-height: 28px;
          padding: 0.5rem 1rem;
          margin: 0.5rem 0 0 0;
          width: 271px;

          @include tablet-down {
            width: 100%;
          }
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      &__remove {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        border-radius: 20px;
        background-color: var(--grey-100);
        p {
          margin: 0;
        }
        button {
          padding: 12px 24px;
          background: rgba(159, 172, 187, 0.16);
          border-radius: 15px;
          line-height: 16px;
          border: 0;
          color: var(--gray-900);
        }

        @include phone-only {
          padding: 1rem;
          flex-direction: column;
          gap: 1rem;
          p {
            font-size: 14px;
            line-height: 20px;
          }
          button {
            width: 100%;
          }
        }
      }
      &__warning {
        background: var(--red-100);
        border-radius: 1rem;
        padding: 0.75rem 1rem;
        margin-bottom: 2rem;
      }
      &__textarea {
        position: relative;
        display: flex;
        flex-direction: column;
      }
      &__test-voice {
        p {
          margin-bottom: 0.25rem;
        }
        .textarea-container {
          margin-top: 1rem;
        }
      }

      &__buttons {
        display: flex;
        gap: 1rem;
        margin-top: 1.5rem;
      }
      &__synth-area {
        margin-bottom: 2rem;
      }

      &__btn-block {
        display: flex;
        gap: 1rem;
        margin-top: 1.5rem;
        button {
          padding: 1rem 2rem;
          border-radius: 18px;
          line-height: 16px;
          @include phone-only {
            display: flex;
            flex: 1;
            margin-top: 44px;
          }
        }
        @include tablet-down {
          flex-direction: column;
          button {
            margin: 0;
            gap: 0.5rem;
          }
        }
      }
      &__publication_info {
        margin-bottom: 1.5rem;
        @include phone-only {
          margin-bottom: 1rem;
        }
      }
    }
    .just-ui-spinner {
      position: static !important;
      min-height: 333px !important;
    }
  }
}
.syntharea__container {
  @include tablet-down {
    height: 128px;
  }
  @include phone-only {
    height: 169px;
  }
}
.synth-area__textarea {
  border-radius: 18px !important;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 16px;
  @include tablet-down {
    height: 100%;
  }
  textarea {
    height: 100%;
    max-height: unset !important;
  }
  .textarea-container {
    @include tablet-down {
      max-height: 41px;
      height: 100%;
      flex: 1;
    }
  }
  .text-muted {
    margin-bottom: 1rem;
  }
}
.synth-area__textarea-controls {
  display: flex;
  position: absolute;
  bottom: 0.8rem;
  left: 1rem;
  width: calc(100% - 16px);
  button:first-child {
    margin-right: 1.5rem;
  }
}

small {
  color: var(--secondary);
  &.warning {
    color: var(--danger);
    svg {
      margin-right: 0.25rem;
    }
  }
}
