@import '../../../../scss/custom/variables';
@import '../../../../scss/custom/mixins';
.user-menu {
  &__login {
    margin-right: -2px;
    font-size: 1rem;
    padding: 11px 1rem;
    transition: 0.2s;
    color: var(--gray-950);
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      color: var(--gray-600);
    }
  }
}

.header-navbar.dark {
  .user-menu a {
    color: white;
    &:hover {
      color: var(--gray-300);
    }
  }
}

.header-menu {
  &__user {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    & > p {
      white-space: nowrap;
    }

    &-info {
      padding: 0.5rem 0.75rem;
      display: flex;
      flex-direction: column;
      gap: 0.125rem;
      border-bottom: 1px solid var(--gray-200);
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:last-child {
          color: var(--gray-600);
        }
      }
    }

    &.active {
      color: var(--primary);
    }

    &-item {
      color: var(--gray-700);
    }
  }

  .placeholder-avatar {
    display: flex;
    gap: 0.5rem;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.header-dropdown {
  .justui_dropdown-menu {
    min-width: 12.5rem;
    max-width: 20rem;
    a:hover {
      color: currentColor;
    }
    button {
      font-size: 1rem;
    }
    .justui_dropdown-item {
      &.dropdown-item {
        &.disabled {
          cursor: unset !important;
        }
      }
    }
  }
}

.sidebar-menu {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 280px;
  height: 100%;
  z-index: 1050;
  transition: transform 300ms ease-in-out;
  background-color: white;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  &__header {
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    a {
      display: flex;
      flex: 1;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    svg {
      width: 1.5rem !important;
      height: 1.5rem !important;
    }

    img {
      width: 108px;
      height: 20px;
    }
  }

  &__logo {
    margin-bottom: 3px;
  }
  &__content {
    padding: 1rem 1rem 1.5rem 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    .login-button-block {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      margin-bottom: 1.5rem;
      line-height: 1rem;
      .btn:first-child {
        background-color: rgba(159, 172, 187, 0.16);
        color: black;
      }
    }
    .privacy-policy {
      &:hover,
      &:focus {
        color: var(--grey-400);
      }
    }

    @include phone-only {
      &.not-logged {
        padding-bottom: 1.5rem;
      }
    }
  }
  &__account {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
    margin-bottom: 1rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--gray-200);
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__nav {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    a:hover {
      color: var(--gray-700);
    }
    p {
      cursor: pointer;
    }
  }
  &__footer {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  &.slide-enter,
  &.slide-exit {
    transform: translateX(-100%);
  }
}

.placeholder-avatar {
  width: 3rem;
  height: 3rem;
  background-color: var(--grey-100);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.5rem;
  svg {
    width: 20px;
    height: 20px;
  }
}
