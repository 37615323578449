@import '../../../../scss/custom/mixins';

.dictionary {
  max-width: 910px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  &__add,
  &__edit {
    padding: 1rem 1.5rem;
    background-color: var(--gray-50);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid var(--gray-200);
    border-radius: 0.5rem;
    button {
      align-self: flex-end;
    }

    &__error {
      color: var(--primary-red);
    }
    .play-button__wrapper {
      align-self: flex-start;
    }
  }
  &__synth {
    display: flex;
    margin-top: 10px;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
      cursor: default;
      opacity: 0.4;
    }
  }
  &__arrow {
    color: var(--gray-300);
    display: flex;
    margin-top: 12px;
  }
  &__word,
  &__pronounce {
    flex-grow: 1;
  }
  &__word {
    &__form {
      display: flex;
      align-items: flex-start;
      gap: 0.5rem;
      margin-top: 0.25rem;
    }
    &__input {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      flex: 1;
      small {
        align-self: flex-end;
      }
      textarea {
        padding: 10px 12px;
        font-size: 14px;
        line-height: 20px;
        border: 1px solid var(--gray-200);
        resize: none;
        border-radius: 18px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        outline: none;
        &:hover {
          border-color: var(--gray-300);
        }
        &:focus,
        &:focus-visible {
          border-color: var(--gray-500);
        }
      }
    }
  }
  &__search {
    margin-top: 1rem;
    margin-bottom: 0.75rem;

    :global(.IconButtons-wrap-prepend) {
      z-index: 4;
    }
    input {
      padding-left: 42px !important;
    }
    &__icon {
      pointer-events: none;
      align-self: center;
      height: fit-content !important;
      padding-left: 21px !important;
    }
  }
  &__view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    &:hover {
      background-color: var(--gray-100);
    }
    &__word {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      width: 100%;
      overflow: hidden;
      p {
        display: block;
        width: fit-content;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        flex: 1;
      }
      p:last-child {
        color: var(--gray-600);
      }
    }
    &__buttons {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
  &__edit {
    background-color: var(--gray-100);
    flex-wrap: wrap;
    &__buttons {
      display: flex;
      flex-grow: 1;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
      > div {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    overflow-y: scroll;
    @include scrollbar();
  }

  &__placeholder {
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__content {
      p {
        margin-top: 36px;
        text-align: center;
        margin-bottom: 2rem;
        color: var(--gray-600);
      }
    }
    &__blocks {
      position: relative;
      height: 88px;
      width: 282px;
    }
    &__block {
      position: absolute;

      padding: 1rem;
      display: flex;
      align-items: center;
      gap: 0.75rem;
      box-shadow: 0px 1px 20px rgba(44, 46, 113, 0.12);
      border-radius: 0.5rem;
      background-color: white;
      &:first-child {
        top: -0.5rem;
        left: 0;
      }
      &:last-child {
        bottom: 0.5rem;
        right: 0;
        z-index: 2;
      }
    }
    &__circle {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: var(--gray-200);
      &.red {
        background-color: var(--orange-300);
      }
    }
    &__rect {
      width: 88px;
      height: 1rem;
      border-radius: 0.5rem;
      background-color: var(--gray-200);
    }
  }
}
