@import "../../../../scss/custom/_mixins.scss";

.public-voice {
  &__back-link {
    flex-basis: 100%;
    a {
      display: flex;
      color: var(--grey-600);

      &:hover {
        color: var(--grey-600);
      }
      p {
        margin-left: 0.5rem;
      }
      svg {
        margin-top: 0.25rem;
      }
    }
    &.sticky {
      position: sticky;
      top: -1px;
      left: 0;
      z-index: 1000;
      box-shadow: 0px 1px 20px rgba(44, 46, 113, 0.12);
    }
  }
  &__versions {
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    padding-top: 1rem;
    gap: 2.5rem;
  }
  &__voice {
    .voice-card__license-block {
      text-align: center;
      margin-bottom: 2rem;
      p:first-of-type {
        font-size: .875rem;
        margin-bottom: .25rem;
        padding: 0 .25rem;
      }
      @include phone-only {
        flex-direction: column;
      }
    }
  }
}
