@import '../../../../../scss/custom/mixins';

.my-page__manage-placeholder.placeholder {
  background-color: var(--gray-100);
  padding: 5rem 8.5rem 5rem 8.5rem;
  border-radius: 3.5rem;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  .placeholder {
    &__text-block {
      padding: 2.5rem 0 2.25rem 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1.5rem;
      h1 {
        width: 23rem;
      }
      button {
        padding: 19px 2.5rem;
        align-self: flex-start;
        line-height: 1rem;
        border-radius: 20px;
      }
    }
  }

  img {
    max-height: 398px;
  }
  @include lg-screen-down {
    padding: 66px 72px;
    .placeholder__text-block {
      padding: 39px 0 35px 37px;
    }
    img {
      max-height: 317px;
    }
  }

  @include md-screen-down {
    padding: 2rem;
    img {
      max-height: 276px;
    }
    h1 {
      @include h2;
      line-height: 36px;
    }
    .placeholder__text-block {
      padding: 2rem;
      h1 {
        width: 18.18rem;
      }
    }
    .placeholder__img-block {
      margin-right: 2px;
    }
  }

  @include tablet-down {
    padding: 5rem 5.5rem;
    flex-direction: column;
    align-items: center;
    .placeholder__text-block {
      padding: 0;
      margin-bottom: 5rem;
      h1 {
        width: 28rem;
        text-align: center;
      }
    }

    .placeholder__img-block {
      margin-bottom: 5rem;
    }
    button {
      padding: 20px 40px;
    }
  }

  @include phone-only {
    padding: 2.5rem 1rem;
    border-radius: 2.5rem;

    .placeholder__text-block {
      margin-bottom: 2.5rem;
      h1 {
        width: 14rem;
        @include h3;
      }
    }

    .placeholder__img-block {
      overflow: hidden;
      margin-bottom: 2rem;
      img {
        max-height: 251px;
      }
    }
    button {
      padding: 20px 70px;
    }
  }
}
