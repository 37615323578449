@import '../../../../scss/custom/mixins';
.landing-page {
  &__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--gray-900);
    color: white;
    margin-top: -1px;
    overflow: hidden;
    &-inner {
      max-width: 1672px;
      width: 100%;
      padding: 0 2rem;
      margin: 0 auto;
    }
    button {
      font-size: 1rem;
    }
    @media (max-width: 940px) {
      &-inner {
        padding: 0;
      }
    }
  }
  &__main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: rgba(0, 0, 0, 0.2);
    flex: 2;
    padding: 56px;
    padding-right: 40px;
    gap: 158px;
    border-radius: 4rem;
    margin-bottom: 0;
    h1 {
      max-width: 39rem;
      width: 100%;
      @include display-2;
    }
    &-block {
      display: flex;
      width: 100%;
      gap: 24px;
      margin-bottom: 4rem;
    }
    &-buttons {
      display: flex;
      gap: 1rem;
      width: 100%;
      button {
        padding: 23px 3rem;
        border-radius: 1.5rem;
      }

      button:last-child {
        color: white !important;
        &:hover {
          border-color: white !important;
        }
      }
    }

    @media (max-width: 1276px) {
      gap: 132px;
      padding: 40px;
      h1 {
        max-width: 23rem;
        @include h1;
      }
      &-buttons {
        button {
          font-size: 14px;
          padding: 20px 40px;
          border-radius: 20px;
        }
      }
      &-block {
        gap: 20px;
        margin-bottom: 56px;
      }
    }
    @media (max-width: 940px) {
      &-block {
        margin-bottom: 1.5rem;
      }
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }

    @media (max-width: 560px) {
      margin-left: 1rem;
      margin-right: 1rem;
      padding: 24px 16px 20px 16px;
      border-radius: 32px;
      gap: 72px;
      &-block {
        margin-bottom: 1rem;
      }
      h1 {
        @include h2;
      }
      &-buttons {
        flex-direction: column;
        gap: 0.5rem;
      }
    }
  }
  &__slider {
    position: relative;
    flex: 1;
    border-radius: 4rem;
    flex: 1;
    min-width: 515px;
    max-height: 550px;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4rem;
    }

    &-item {
      height: 100%;
      border-radius: 4rem;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 4rem;
        background: linear-gradient(1turn, rgba(0, 0, 0, 0.3) 2%, rgba(2, 23, 48, 0) 53%);
      }
    }

    &-controls-wrapper {
      z-index: 10;
      position: absolute;
      bottom: 2rem;
      display: flex;
      justify-content: space-between;
      left: 2rem;
      right: 50px;
      align-items: center;
      &.last {
        bottom: 50px;
      }
    }
    &-play {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 14px;
      .justui__icon-wrapper {
        display: flex;
      }
      > div {
        width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: white;
        svg {
          color: black;
        }
        .fa-play {
          margin-left: 2px;
        }
      }
      h4 {
        @include h4;
      }
    }
    &-controls {
      display: flex;
      gap: 36px;
      margin-left: auto;
      svg {
        cursor: pointer;
        width: 28px !important;
        height: 28px !important;
        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
    &-last {
      color: var(--gray-950);
      background-color: var(--orange);
      padding: 4rem 2.5rem 2.5rem 2.5rem;
      border-radius: 4rem;
      display: flex;
      flex-direction: column;
      height: 100%;
      h2 {
        margin-bottom: 1.5rem;
        @include h2;
      }
      p {
        margin-bottom: 2.5rem;
        @include p2;
      }
      button {
        padding: 24px 48px;
        width: fit-content;
        border-radius: 1.5rem;
      }
    }

    @media (max-width: 1276px) {
      min-width: 386px;
      &-controls-wrapper {
        left: 1.5rem;
        right: 40px;
        bottom: 1.5rem;
        &.last {
          bottom: 41px;
        }
      }
      &-play {
        > div {
          height: 56px;
          width: 56px;
        }
        h4 {
          @include s1;
        }
      }
      &-controls {
        gap: 28px;
        svg {
          height: 21px !important;
          width: 21px !important;
        }
      }
      &-last {
        padding: 48px 40px;
        h2 {
          margin-bottom: 1rem;
          @include h3;
        }
        p {
          @include p3;
        }
      }
      img {
        max-height: 414px;
      }
    }

    @media (max-width: 940px) {
      &-last {
        padding: 32px 24px 24px 24px;
        h2 {
          @include h4;
          margin-bottom: 1rem;
        }
        p {
          @include p4;
        }
        button {
          margin-top: auto;
          width: 100%;
          padding: 24px 0;
        }
      }
      &-play {
        gap: 0.5rem;
        > div {
          width: 3rem;
          height: 3rem;
        }
      }
      &-controls-wrapper {
        left: 1rem;
        bottom: 1rem;
      }
    }
  }
  &__mobile {
    &-slider {
      .progress {
        background: rgba(255, 255, 255, 0.21);
        border-radius: 9px;
      }
      .progress-bar {
        background: #ffffff;
        border-radius: 9px;
      }
      .landing-page__slider-last {
        min-width: 270px;
        height: 290px;
        border-radius: 40px;
      }
    }
    &-container {
      position: relative;
      max-width: 270px;
      min-width: 270px;
      height: 290px;
      border-radius: 40px;
      margin-bottom: 56px;
      img {
        height: 100%;
        width: 100%;
        border-radius: inherit;
        object-fit: cover;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 40px;
        background: linear-gradient(1turn, rgba(0, 0, 0, 0.3) 2%, rgba(2, 23, 48, 0) 53%);
      }
    }

    @media (max-width: 960px) {
      &-container:first-child {
        margin-left: 2rem;
      }

      &-slider {
        .landing-page__slider-last {
          margin-right: 2rem;
        }
      }
    }
    @media (max-width: 560px) {
      &-container:first-child {
        margin-left: 1rem;
      }
      &-slider {
        .landing-page__mobile-slider-last {
          margin-right: 1rem;
        }
      }
    }
  }

  &__projects {
    max-width: 1672px;
    margin: 80px auto 140px auto;
    padding: 0 2rem;
    width: 100%;

    @include md-screen-down {
      margin: 40px auto 100px auto;
    }

    @include tablet-down {
      margin: 24px auto 84px auto;
    }

    @include phone-only {
      margin: 16px auto 64px auto;

      padding: 0 1rem;
    }
  }
  &__purpose {
    display: flex;
    justify-content: space-between;
    margin-bottom: 120px;
    &-item {
      max-width: 515px;
      width: 100%;
      flex: 1;
      h3 {
        @include h3;
      }
      p {
        @include p3;
      }
      .check {
        width: 40px;
        height: 40px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 28px;
      }
    }
    &-title {
      max-width: 380px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      p {
        color: var(--gray-300);
      }
    }

    @media (max-width: 1276px) {
      gap: 45px;
      margin-bottom: 64px;
      &-item {
        max-width: unset;
        display: flex;
        flex-direction: column;
        flex: 1;
        .check {
          margin-right: 0;
        }
      }
      &-title {
        max-width: unset;
      }
    }
    @media (max-width: 1267px) {
      &-item {
        h3 {
          @include h4;
        }
        p {
          @include p4;
        }
      }
    }
    @media (max-width: 940px) {
      flex-wrap: wrap;
      gap: 40px;
      margin-left: 2rem;
      margin-right: 2rem;
      &-item {
        flex-grow: 0;
        flex-basis: calc(50% - 26px);
        h4 {
          @include s1;
        }
        .check {
          margin-bottom: 1rem;
        }
      }
      &-title {
        gap: 0.5rem;
      }
    }

    @media (max-width: 560px) {
      margin-left: 1rem;
      margin-right: 1rem;
      flex-direction: column;
    }
  }
  &__dialog {
    width: 100%;
    padding: 0 2rem;
    margin: 0 auto;
    background: var(--gray-100);
    border-radius: 4rem;
    display: flex;
    flex-direction: column;
    min-height: 550px;
    padding: 92px 36px 36px 36px;

    @media (max-width: 940px) {
      padding: 72px 36px 36px 36px;
      border-radius: 56px;
    }

    @media (max-width: 560px) {
      border-radius: 40px;
      padding: 48px 16px 12px;
    }
    .dialog-synth__speaker-block .add-speaker-avatar {
      @include tablet-down {
        margin-right: 0;
      }
    }
  }

  &__advantages {
    max-width: 1672px;
    width: 100%;
    padding: 0 2rem;
    margin: 0 auto;
    margin-bottom: 140px;
    button {
      margin: 0 auto;
      padding: 20px 40px;
      color: var(--gray-950) !important;
      border-radius: 24px;
    }
    &-title {
      display: flex;
      flex-direction: column;
      margin-bottom: 5rem;
      h2 {
        white-space: nowrap;
        color: var(--orange);
        font-size: 200px;
        line-height: 169px;
        max-width: 655px;
        text-align: center;
      }
      > div {
        display: flex;
        align-items: center;
        > div {
          width: 33vw;
          margin-left: auto;
        }
      }

      p {
        max-width: 241px;
        margin-top: 32px;
        font-size: 2.5rem;
        line-height: 2.5rem;
      }
    }
    &-wrapper {
      display: flex;
      gap: 1.5rem;
      flex-wrap: wrap;
      margin-bottom: 2.5rem;
    }
    &-item {
      background: rgba(0, 0, 0, 0.2);
      width: calc(50% - 0.75rem);
      border-radius: 56px;
      padding: 3rem 2.5rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      position: relative;
      height: 400px;
      justify-content: flex-end;
      h3 {
        max-width: 331px;
        z-index: 1;
        @include h3;
      }
      p {
        color: var(--gray-300);
        max-width: 331px;
        z-index: 1;
        @include p3;
      }
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background-position: right top;
        background-repeat: no-repeat;
        overflow: hidden;
        height: 100%;
        top: 0;
        right: 0;
      }
      &:nth-child(1)::after {
        background-image: url('../../../../img/LandingPage/catalog.svg');
      }
      &:nth-child(1).usd-images::after {
        background-image: url('../../../../img/LandingPage/catalog-usd.svg');
      }
      &:nth-child(2)::after {
        background-image: url('../../../../img/LandingPage/clock.svg');
      }
      &:nth-child(3)::after {
        background-image: url('../../../../img/LandingPage/task.svg');
      }
      &:nth-child(4)::after {
        background-image: url('../../../../img/LandingPage/smile.svg');
        top: 14%;
        right: 7%;
      }
    }

    @media (max-width: 1276px) {
      margin-bottom: 140px;
      &-title {
        > div {
          gap: 72px;
        }
        p {
          font-size: 2rem;
          line-height: 2rem;
          max-width: 192px;
        }
      }
    }

    @media (max-width: 1124px) {
      margin-bottom: 80px;

      button {
        width: 100%;
      }
      &-wrapper {
        flex-wrap: nowrap;
        flex-direction: column;
        margin-bottom: 1rem;
        gap: 1rem;
      }
      &-item {
        width: 100%;
      }
    }

    @media (max-width: 990px) {
      &-title {
        h2 {
          font-size: 135px;
          line-height: 114px;
        }
        h2:last-child {
          text-align: inherit;
          margin-left: 49px;
        }
      }
    }

    @media (max-width: 770px) {
      &-title {
        align-items: flex-start;
        flex-direction: column;
        gap: 32px;
        margin-bottom: 32px;
        p {
          margin-left: 3rem;
          margin-top: 0;
        }
        > div {
          flex-direction: column;
          gap: 0;
          h2:last-child {
            margin: 0;
          }
        }
      }
    }

    @media (max-width: 560px) {
      padding: 0;
      margin-bottom: 4rem;
      button {
        margin-left: 1rem;
        margin-right: 1rem;
        width: -webkit-fill-available;
      }
      &-wrapper {
        gap: 0.5rem;
      }
      &-title {
        h2 {
          font-size: 88px;
          line-height: 88px;
        }
        p {
          font-size: 28px;
          margin-left: 2.1rem;
          max-width: 166px;
        }
      }
      &-item {
        width: -webkit-fill-available;
        border-radius: 2rem;
        margin-left: 1rem;
        margin-right: 1rem;
        height: 360px;
        padding: 0 1rem 2rem 1rem;
        h3 {
          @include h4;
        }
        p {
          @include p4;
        }

        &:nth-child(1)::after {
          background-image: url('../../../../img/LandingPage/catalog-sm.svg');
        }
        &:nth-child(1).usd-images::after {
          background-image: url('../../../../img/LandingPage/catalog-usd-sm.svg');
        }
        &:nth-child(2)::after {
          background-image: url('../../../../img/LandingPage/clock-sm.svg');
        }
        &:nth-child(3)::after {
          background-image: url('../../../../img/LandingPage/task-sm.svg');
        }
        &:nth-child(4)::after {
          background-image: url('../../../../img/LandingPage/smile-sm.svg');
          top: 7%;
          right: 7%;
        }
      }
    }
  }

  &__earn {
    &-wrapper {
      color: var(--gray-950);
      background-color: var(--gray-100);
      border-radius: 4rem;
      padding: 120px 0;
      margin-bottom: 140px;
    }
    &-title {
      max-width: 1672px;
      padding: 0 2rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 200px;
      h2 {
        max-width: 582px;
        @include display-2;
      }
      p {
        max-width: 519px;
      }
    }
    &-subtitle {
      width: 49.1%;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      p {
        @include p2;
      }
      button {
        width: fit-content;
        padding: 20px 40px;
        border-radius: 1.5rem;
      }
    }
    &-container {
      max-width: 1672px;
      width: 100%;
      margin: 0 auto;
      padding: 0 2rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 0;
    }
    &-item {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 515px;
      > div {
        font-weight: 600;
        font-size: 140px;
        line-height: 120px;
        color: var(--gray-100);
        text-shadow: -1px -1px 0 var(--primary), 1px -1px 0 var(--primary), -1px 1px 0 var(--primary),
          1px 1px 0 var(--primary);
      }
      p {
        @include p2;
      }
    }

    @media (max-width: 1276px) {
      &-wrapper {
        padding: 80px 0;
        margin-bottom: 100px;
      }
      &-title {
        padding: 0 36px;
        align-items: flex-start;
        gap: 1.5rem;
        margin-bottom: 155px;
        h2 {
          max-width: 364px;
          @include h1;
        }
      }
      &-subtitle {
        max-width: 383px;
        gap: 2rem;
        margin-right: 90px;
        p {
          @include p3;
        }
      }
      &-item {
        width: 296px;
        p {
          @include p3;
        }
      }
    }

    @media (max-width: 940px) {
      &-wrapper {
        padding: 56px 24px 24px 24px;
        border-radius: 56px;
        button {
          width: 100%;
          padding: 20px 0;
          border-radius: 1.5rem;
        }
      }
      &-container {
        padding: 0;
        margin-bottom: 64px;
      }
      &-item {
        max-width: 218px;
        > div {
          font-size: 96px;
          line-height: 96px;
        }
      }
      &-title {
        flex-direction: column;
        align-items: center;
        margin-bottom: 88px;

        h2 {
          max-width: 100%;
          text-align: center;
        }
      }
      &-subtitle {
        max-width: 100%;
        width: 100%;
        text-align: center;
        margin-right: 0;
        p {
          margin: 0 auto;
        }
      }
    }

    @media (max-width: 560px) {
      &-wrapper {
        border-radius: 40px;
        padding: 48px 1rem 20px 1rem;
        margin-bottom: 64px !important;
      }
      &-container {
        flex-direction: column;
        gap: 40px;
      }
      &-title {
        text-align: center;
        padding: 0;
        margin-bottom: 40px;
        h2 {
          @include h2;
        }
      }
      &-subtitle {
        p {
          @include p4;
        }
      }
      &-item {
        p {
          @include p4;
        }
      }
    }
  }

  &__contact {
    &-wrapper {
      width: 850px;
      margin: 0 auto;
      text-align: center;

      h4 {
        margin-bottom: 2.5rem;
        @include display-2;
      }
      p:first-of-type {
        @include p2;
        margin-bottom: 5rem;
      }
      p:last-of-type {
        @include p4;
      }
    }
    &-inputs {
      display: flex;
      gap: 1rem;
      justify-content: center;
      margin-bottom: 1rem;
      input {
        width: 306px;
        height: 56px;
        padding: 1rem 1.5rem;
        background-color: transparent;
        border: 1px solid var(--gray-300);
        border-radius: 1.5rem;
        color: var(--gray-300) !important;
        &:focus {
          background-color: transparent;
        }
      }
      input:-webkit-autofill,
      input:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
      }
      button {
        padding: 20px 40px;
        border-radius: 20px;
      }
    }
    &-info {
      color: var(--gray-500);
      text-align: initial;
      margin-left: 8px;
      margin-bottom: 200px !important;
      .underline {
        text-decoration: underline;
      }
      a:hover {
        color: currentColor;
      }
    }

    @media (max-width: 1276px) {
      &-wrapper {
        h4 {
          @include h1;
          max-width: 513px;
          text-align: center;
          margin: 0 auto;
          margin-bottom: 2rem;
        }
        p:first-of-type {
          margin-bottom: 4rem;
          @include p3;
        }
      }
      &-info {
        margin-bottom: 148px !important;
      }
    }
    @media (max-width: 940px) {
      &-wrapper {
        width: auto;
        h1 {
          margin-bottom: 1.5rem;
        }
        p:last-of-type {
          @include p5;
          max-width: 437px;
          margin: 0 auto;
        }
      }
      &-inputs {
        flex-direction: column;
        margin: 4rem auto 1rem auto;
        max-width: 456px;
        input {
          width: 100%;
        }
        button {
          margin-top: 1rem;
        }
      }
      &-info {
        margin-bottom: 124px !important;
      }
    }
    @media (max-width: 560px) {
      &-wrapper {
        padding: 0 1rem;
        h4 {
          @include h2;
        }
        p:first-of-type {
          margin-bottom: 45px;
          @include p4;
        }
        p:last-of-type {
          text-align: center;
        }
      }
    }
  }
}

.dialog-synth {
  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    color: var(--gray-900);
    flex: 1;
    h1 {
      margin-bottom: 4.75rem;
      margin-left: 2rem;
      @include tablet-down {
        font-size: 40px;
        line-height: 48px;
        margin-left: 0.5rem;
      }
      @include phone-only {
        font-size: 32px;
        line-height: 36px;
        margin-right: 0.25rem;
        margin-bottom: 2.5rem;
      }
    }
  }
  &__speakers-wrapper {
    margin-bottom: 2rem;
    .dialog-synth__speaker-block {
      &:hover {
        background-color: white;
        .play-button {
          visibility: visible;
          background-color: var(--grey-100) !important;
        }
      }
    }
  }
  &__player {
    display: flex;
    margin-top: auto;
    align-items: center;
    padding: 1rem 3rem;
    background-color: white;
    border-radius: 2rem;
    @include tablet-down {
      padding: 1rem 1.5rem;
    }
    @include phone-only {
      padding: 0.5rem;
      padding-right: 0.75rem;
    }
    p.tp-3:not(.duration) {
      margin-right: 6.875rem;
    }
    .play-button.justui_icon-button.btn.btn-primary.btn-square {
      background-color: var(--primary);
      border-color: var(--primary);
      border-radius: 40px;
      padding: 15px;
      @include phone-only {
        padding: 12px;
      }
      margin-right: 1.5rem;
      &:not(.spinner):not(.playing) {
        padding-left: 16px;
        padding-right: 14px;
        @include phone-only {
          padding-left: 13px;
          padding-right: 11px;
        }
      }

      &:active:not(:disabled) {
        background-color: var(--primary-400) !important;
        border-color: var(--primary-400) !important;
      }
      &:disabled {
        cursor: default;
      }
    }
    .audio-progress-bar {
      background-color: var(--grey-200);
    }
    .audio-progress-bar__point {
      background-color: transparent;
    }
    .audio-progress-bar__progress {
      background-color: var(--primary);
    }
    p.duration {
      margin-left: 1.5rem;
      @include phone-only {
        margin-left: 0.75rem;
      }
    }
    .volume-container {
      position: static;
      display: flex;
      min-width: 150px;
      height: 100%;
      align-items: center;
      box-shadow: none;
      margin-left: 5rem;
      svg {
        margin-right: 0.5rem;
      }
      .justui__icon-wrapper {
        display: flex;
      }
      .volume-progress-bar {
        border-radius: 3px;
        height: 3px;
        background-color: var(--grey-200);
        &__point {
          background-color: transparent;
        }
        &__progress {
          border-radius: 3px;
          background-color: var(--grey-500);
        }
      }
    }
  }
  &__speaker-block {
    display: flex;
    align-items: center;
    padding: 0.75rem;
    margin: 0 2.25rem;
    border-radius: 2rem;
    @include tablet-down {
      margin: 0;
    }
    .play-button {
      visibility: hidden;
      &:disabled {
        cursor: default;
        svg.justui_icon {
          color: var(--grey-300);
        }
      }
    }
    .add-speaker-avatar {
      position: relative;
      width: 4rem;
      height: 4rem;
      min-width: 4rem;
      background-color: white;
      object-fit: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 1.5rem;
      font-size: 2rem;
      font-weight: 500;
      cursor: pointer;
      margin-right: 1.5rem;
      overflow: hidden;
      @include phone-only {
        width: 3rem;
        height: 3rem;
        min-width: 3rem;
        border-radius: 1rem;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .avatar-edit-overlay {
        position: absolute;
        visibility: hidden;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.24);

        svg {
          color: white;
        }
      }
      &:hover {
        background-color: var(--grey-100);
        .avatar-edit-overlay {
          visibility: visible;
          span {
            display: flex;
          }
        }
      }
    }
    .play-button.justui_icon-button.btn.btn-primary.btn-square:not(.withoutPadding) {
      display: flex;
      margin-left: auto;
      margin-right: 0;
      background-color: var(--grey-100);
      border-color: var(--grey-100);
      border-radius: 40px;
      padding: 15px;
      @include phone-only {
        padding: 12px;
      }
      &:not(.spinner):not(.playing) {
        padding-left: 16px;
        padding-right: 14px;
        @include phone-only {
          padding-left: 13px;
          padding-right: 11px;
        }
      }
      &:active:not(:disabled) {
        background-color: var(--grey-200) !important;
        border-color: var(--grey-200) !important;
      }
    }
    input,
    textarea {
      background-color: transparent !important;
      border: none !important;
      font-size: 20px;
      margin-right: 40px;
      cursor: pointer;
    }
    textarea {
      &:focus {
        box-shadow: none;
      }
    }
  }
  &__speaker-modal:not(.fullscreen) {
    &.custom-modal__container .modal-content {
      border-radius: 3rem;
      width: 628px;
    }
    .modal-body {
      padding: 0;
    }
  }
  &__speaker-modal {
    .modal-body {
      padding: 0;
    }
    h5 {
      font-size: 2rem;
    }
    .custom-modal__header {
      padding-left: 0.75rem;
      padding-top: 0.75rem;
      .close {
        top: 20px;
        right: 25px;
      }
      @include phone-only {
        padding-top: 0;
        .close {
          top: 18px;
          right: 18px;
        }
      }
    }
    &__speaker-list {
      margin-left: -0.75rem;
      padding-left: 0.75rem;
      @include tablet-down {
        margin-top: 0;
      }
      @include phone-only {
        margin-left: 0;
        padding-left: 0;
      }
      overflow: auto;
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--gray-300);
        border-radius: 2px;
      }
    }

    .custom-modal {
      padding: 1rem;
      @include phone-only {
        padding: 1rem 4px 0;
      }
    }

    .dialog-synth__speaker-block {
      margin-right: 0;
      margin-left: 0;
      .play-button {
        visibility: visible;
      }
      &:hover {
        cursor: pointer;
        background-color: var(--grey-100);
        .play-button {
          background-color: white !important;
        }
      }
      @include tablet-down {
        margin-right: 0;
      }
      @include phone-only {
        border-radius: 1rem;
        padding: 0.5rem;
      }
    }
  }
}
