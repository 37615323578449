@import '../../scss/custom/variables';
@import '../../scss/custom/mixins';
.footer {
  margin-top: auto;

  &__container {
    background-color: rgba(244, 245, 248, 0.5);
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 102px 1.5rem 36px;
    margin: auto;

    > div {
      display: flex;
      gap: 0.5rem;
    }

    &:last-child {
      border-top: 1px solid var(--gray-200);
    }

    &:first-child {
      justify-content: flex-start;
      gap: 2rem;
      a {
        @include p3;
        @include tablet-down {
          @include p4;
        }
      }
    }

    @include tablet-down {
      padding: 1.5rem 2rem;

      &:first-child {
        flex-direction: row;
        justify-content: space-between;
        gap: 1.5rem;
      }
      &:last-child {
        flex-direction: column;
        padding: 1rem 2rem;
        gap: 0.5rem;
        > div {
          flex-direction: row;
        }
      }
    }
    @include phone-only {
      &:first-child {
        flex-direction: column;
        gap: 1rem;
        a {
          line-height: 1.25rem;
        }
      }
      &:last-child {
        padding: 12px 2rem 1rem 2rem;
        gap: 12px;
        > div {
          flex-direction: column;
          gap: 8px;
        }
        a {
          line-height: 1rem;
        }
      }
    }
  }
  &__text-muted {
    font-size: 0.75rem;
    color: var(--gray-500);
    margin: 0;
    &:hover:not(p) {
      color: var(--gray-900);
    }
  }
  .footer__underline {
    text-decoration: underline;
  }
  &.dark {
    background-color: var(--gray-900);
    > div:last-child {
      border-color: var(--gray-700);
    }
    > div {
      background-color: rgba(0, 0, 0, 0.1);
    }

    .footer__text-muted {
      color: var(--gray-500);
      &:hover:not(p) {
        color: var(--gray-100);
      }
    }
  }
}
