@import "../../../../../scss/custom/mixins";
.success-request-info {
  .custom-modal {
    &__container {
      margin: 0 auto;
      &:not(.fullscreen) {
        .modal-content {
          max-width: 529px;
          width: 100%;
        }
      }
    }

    @include md-screen-down {
      &__header {
        padding: unset;
      }
    }
    @include phone-only {
      height: fit-content;
    }

    .custom-modal__header.hidden {
      button.close {
        @include phone-only {
          display: none;
        }
      }
    }

    &__body {
      &.success-request-info {
        margin-bottom: 2.5rem;
        img {
          margin-bottom: 1.5rem;
        }
        h3 {
          margin-bottom: 1rem;
        }
        p {
          margin-bottom: 20px;
        }
        @include phone-only {
          margin-bottom: 1.25rem;
          img {
            width: 144px;
            height: 144px;
            margin-bottom: 1rem;
          }
          h3 {
            margin-bottom: 0.5rem;
            font-size: 1rem;
            line-height: 1.5rem;
          }
          p {
            margin-bottom: 0;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
