.justui_collapse__container {
  width: 100%;
  min-width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  padding: 24px;

  border: 1px solid #E7EBED;
  border-radius: 4px;

  .justui_collapse__header {
    margin-bottom: 0px;

    width: 100%;
    height: 24px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    transition: 300ms;
    will-change: margin-bottom;

    &-opened {
      margin-bottom: 24px;
    }

    &_collapseButton {
      height: 24px;
      transform: rotateZ(0);
      transition: 300ms;
      transition-timing-function: ease-in-out;
      outline: none;
      box-shadow: none;

      will-change: transform;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    &_collapseButton-opened {
      transform: rotateZ(-180deg);
    }
  }

  .justui_collapse__body {
    width: 100%;
  }

}

