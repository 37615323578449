@import './scss/custom/variables';
@import './scss/custom/mixins';
html {
  background-color: var(--projects-grey);
}
body {
  background: white;
  overflow: overlay;
  font-family: Golos, -apple-system, BlinkMacSystemFont, Segoe UI;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include scrollbar;
}
.main-page__container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: white;
  @include md-screen-down {
    margin-left: 0;
  }
}

.central-column {
  max-width: 1672px;
  width: 100%;
  margin: 0 auto;
}

a {
  // a, p  important needs to override normalize.css just-ui styles
  letter-spacing: 0 !important;
  color: unset;
  text-decoration: unset;
}

a,
p {
  letter-spacing: 0 !important;
}

h1,
h2 {
  font-weight: 600;
}

p {
  letter-spacing: 0 !important;
  margin-bottom: unset;
  letter-spacing: unset;
}

.display-1,
.display-2,
h1,
h2,
h3,
h4,
.st-1,
.st-2,
.st-3 {
  font-weight: 600;
}

.display-1 {
  font-size: 5rem;
  line-height: 5.5rem;
}

.display-2 {
  font-size: 4rem;
  line-height: 4.5rem;
}

h1 {
  font-size: 2.5rem;
  line-height: 3rem;
}

h2 {
  font-size: 2rem;
  line-height: 2.75rem;
}

h3 {
  font-size: 1.5rem;
  line-height: 2rem;
}

h4 {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.tp-1 {
  font-size: 1.5rem;
  line-height: 2.25rem;
}

.tp-2 {
  font-size: 1.25rem;
  line-height: 2rem;
}

.tp-3,
.st-1 {
  font-size: 1rem;
  line-height: 1.5rem;
}

.tp-4,
.st-2 {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.tp-5 {
  font-size: 0.75rem;
  line-height: 1rem;
}

.st-3 {
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.text-muted {
  color: var(--secondary);
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
}

.error-block {
  background-color: #ffe899;
  color: #664f00;
  border: 1px solid #ffdc66;
  padding: 0.75rem 1rem;
  border-radius: 3px;
}

.justui_dropdown .dropdown-item {
  a {
    color: unset;
  }
  &:hover {
    background-color: var(--grey-100);
  }
  &:focus,
  &:active {
    background-color: var(--grey-200);
    outline: none;
    color: unset;
  }
}

.skeleton-text-short {
  height: 20px;
  width: 60%;
  border-radius: 3px;
  margin: 1rem 0;
  background-color: var(--grey-light-40);
}
.skeleton-text-large {
  height: 20px;
  width: 90%;
  border-radius: 3px;
  margin: 1rem 0;
  background-color: var(--grey-light-40);
}

.skeleton-table-image {
  width: 48px;
  height: 48px;
  border-radius: 3px;
  background-color: var(--grey-light-40);
  &--big {
    width: 6.25rem;
    height: 6.25rem;
  }
}

.animated-pulse {
  animation: pulse 2s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0);
  }
}

.error-text {
  color: var(--danger);
  display: flex;
  align-items: center;
  gap: 4px;
}
.notification-container {
  padding: 0;
  margin: 24px 0 0;
  z-index: 99999 !important;
}
.notification-container.toasts-container {
  width: 0;
}
.notification-container.toasts-container {
  bottom: 0.75rem;
  right: 50%;
  transform: translateX(50%);
  a {
    text-decoration: underline;
  }
}

.animated-card {
  overflow: hidden;
  transition: all ease-out 200ms;
}

.btn.justui_button {
  &.noIconsMargin {
    .iconLeft,
    .iconRight {
      margin: 0;
    }
  }
}

.btn.justui_icon-button {
  &.compact {
    padding: 0.375rem;
    &:disabled {
      color: #666666;
    }
    svg {
      width: 1rem;
      height: 1rem;
      &.dropdown-caret {
        width: 0.5rem;
        height: 0.5rem;
        font-size: 0.8rem;
      }
    }
    .dropdown-caret {
      margin-left: 4px;
    }
  }
}

.small-text {
  font-size: 12px;
}

.just-ui-spinner {
  svg {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
}

button.btn-outline-primary,
button.btn-outline-secondary,
.btn.focus,
.btn:focus {
  box-shadow: none !important;
  &:active {
    box-shadow: none !important;
  }
}

textarea:disabled {
  background-color: white !important;
}

.switch-success > .switch-input:checked:not(:disabled) ~ .switch-label {
  background-color: var(--primary);
}

.custom-checkbox {
  input:focus {
    outline: none;
  }
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #6a7178 !important;
}

.grey-text-0 {
  color: var(--grey-400);
}

.grey-text-1 {
  color: var(--grey-500);
}

.grey-text-2 {
  color: var(--gray-600);
}

.grey-text-3 {
  color: var(--grey-700);
}

.modal-dialog-centered {
  @include phone-only {
    min-height: 100%;
  }
}

button,
.btn {
  .justui__icon-wrapper {
    display: flex;
  }
}

.modal:not(.show) {
  #g-recaptcha {
    visibility: hidden;
  }
}
.modal .just-ui-modal {
  .modal-footer {
    padding: 2rem;
    padding-top: 1.5rem;
    .modal-base-buttons {
      margin: -0.25rem 0;
      gap: 1rem;
      > * {
        margin: unset;
      }
    }
  }
  .modal-header {
    .close {
      svg.fa-times.fa-sm {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}

.modal {
  @include tablet-down {
    overflow-y: hidden !important;
  }
}

#crisp-chatbox {
  z-index: 1000;
}

ol,
ul,
dl {
  margin-bottom: 0;
}

.justui_dropdown-menu {
  padding: 0.25em;
  border-radius: 1rem;
  box-shadow: 0px 1px 20px rgba(44, 46, 113, 0.12);
  border: none;
  .justui_dropdown-item {
    padding: 0.5rem 0.75rem;
    border-radius: 0.75rem;
    a {
      text-decoration: none;
    }
    &:hover {
      background-color: var(--gray-100);
    }
  }
}

.tooltip.show {
  .arrow {
    visibility: hidden;
  }
}

.just-select-list {
  border-radius: 1rem;
  padding: 0.25rem;
  &.opened {
    z-index: 10500 !important;
  }
  .just-select-item {
    border-radius: 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
    &.focused.active,
    &.focused {
      color: var(--gray-900);
      background-color: var(--gray-100);
    }
    &.active {
      background-color: white;
      color: currentColor;
    }
  }
}

.just-select-backdrop {
  z-index: 10499;
}
.just-select-container {
  border: 1px solid var(--gray-300);
  border-radius: 15px;
  &.focused {
    border-color: unset;
  }
  input {
    font-size: 14px;
    line-height: 2rem;
    font-weight: 400;
    border-radius: 15px;
    padding: 11px 36px 11px 12px;
    height: 38px;

    &:not(:focus):hover {
      background-color: transparent;
    }
    &::placeholder {
      font-size: 14px;
      color: var(--gray-600);
    }
  }
  &.error input {
    border-color: red;
    background-image: none;
    background-color: rgba(var(--primary-red-rgb), 0.08);
    &:hover {
      background-color: rgba(var(--primary-red-rgb), 0.08);
    }
  }
}
