.justui-range-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
  .justui-range-steps {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    position: absolute;
    top: calc(100% - 0.25rem);
    z-index: -1;
    width: 100%;
    padding-left: 0.25rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    & > * {
      width: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      color: var(--gray-800);
      font-size: 0.75rem;
      &:first-child {
        align-items: flex-start;
      }
      &:last-child {
        align-items: flex-end;
      }
    }
  }
}
