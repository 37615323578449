@import '../../../../../scss/custom/mixins';

.voice-page {
  &__managers {
    flex: 0 0 18.75rem;
    margin-left: 2.5rem;

    @include lg-screen-down {
      padding: 1.5rem;
      border-radius: 2rem;
      border: 1px solid var(--gray-200);
      margin: 0;
      margin-bottom: 1.5rem;
    }
    @include phone-only {
      margin-top: -1px;
      padding: 1.5rem 1rem;
      border-radius: 0;
      border: 0;
      border-top: 1px solid var(--gray-200);
      border-bottom: 1px solid var(--gray-200);
      margin-bottom: 0;
    }
    .managers {
      &__item-wrapper {
        @include lg-screen-down {
          display: flex;
          flex-wrap: wrap;
          gap: 1.5rem;
          margin-bottom: 1.5rem;
        }
        @include tablet-down {
          flex-direction: column;
        }
      }
      &__title {
        display: flex;
        margin-bottom: 10px;
        gap: 0.5rem;
        span {
          color: var(--gray-400);
        }

        @include lg-screen-down {
          h4:last-child {
            color: var(--gray-400);
          }
          margin-bottom: 1.5rem;
        }
      }
      &__caption {
        margin-right: auto;
      }
      &__item {
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 1rem;
        &:hover .managers__icons {
          opacity: 1;
        }
        p {
          max-width: 11.5rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 0;
          margin-right: 0.5rem;
        }
        p:last-child {
          color: var(--secondary);
        }
        .manager__photo {
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 20px;
          width: 32px;
          height: 32px;
          background-color: var(--info);
          border-radius: 50%;
          margin-right: 0.5rem;
          > p {
            margin: 0;
            font-weight: 600;
            color: white;
          }
        }

        @include lg-screen-down {
          max-width: 31.4%;
          width: 100%;
          margin-bottom: 0;
        }
        @include tablet-down {
          max-width: 100%;
        }
      }

      &__icons {
        align-self: center;
        opacity: 0;
        transition: 0.3s;
        svg {
          cursor: pointer;
          color: var(--grey-500);
          &:hover {
            color: var(--grey-dark-10);
          }
        }
        svg:first-child {
          margin-right: 0.5rem;
        }

        @include lg-screen-down {
          margin-left: auto;
        }
        @include tablet-down {
          opacity: 1;
        }
      }

      &__add {
        cursor: pointer;
        color: var(--grey-500);
        svg {
          margin-right: 0.625rem;
        }
        &:hover {
          color: var(--grey-dark-10);
        }
      }
    }
  }
}
