@import '../../../../../scss/custom/mixins';
.unpublish-voice {
  .custom-modal {
    &__remove-from-catalog {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0;
      > div {
        margin-bottom: 1rem;
      }
      h4 {
        margin-bottom: 1rem;
        color: black;
        @include phone-only {
          margin-bottom: 0.5rem;
        }
      }

      img {
        border-radius: 50%;
        width: 6.25rem;
        height: 6.25rem;
        margin-bottom: 1rem;
      }
    }
    &__price {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-bottom: 1.5rem;
      color: var(--gray-600);
      line-height: 24px;
      h3 {
        font-weight: 500;
        font-size: 1rem;
        line-height: 24px;
        margin-bottom: 0;
      }
    }

    &__header {
      margin: 0;
      .close {
        padding: 8px;
        background: rgba(0, 0, 0, 0.32);
        border-radius: 12px;
        position: absolute;
        top: 0;
        right: -39px;
        color: white;
        span {
          display: flex;
        }
      }
    }
    &__footer {
      flex-direction: row;
      @include phone-only {
        flex-direction: column-reverse;
      }
    }

    @include phone-only {
      padding: 2rem 1rem 1rem 1rem;
      &__header {
        display: none;
      }
    }
  }
  .modal-content {
    border-radius: 2rem;
  }
}
