.catalog-page__breadcrumbs {
  max-width: 1672px;
  padding: 0 calc(1672px * 0.165) 0 calc(1672px * 0.13);
  margin: auto;
  ol {
    padding: 0;
    margin: 0;
    margin-bottom: 0.5rem;
    color: var(--gray-600);
    font-size: 1rem;
    line-height: 28px;
    a:hover {
      color: var(--gray-900);
    }
    a[href] {
      cursor: pointer;
    }
  }
  li {
    display: flex;
    align-items: center;
    a {
      max-width: 100%;
    }
    p {
      font-size: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  li:last-child {
    display: flex;
    align-items: center;
  }

  &-wrapper {
    position: absolute;
    top: 26px;
    left: 0;
    width: 100%;
  }
  .breadcrumbs-container {
    position: relative;
    width: 100%;
    z-index: 3;

    li:first-child {
      max-width: 36%;
    }
    li:last-child {
      max-width: 37%;
    }
  }
}
