.dictionary-page {
  max-width: 1672px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 19px 2rem 0 2rem;
  height: calc(100vh - 5rem);
  overflow: hidden;
  &__back {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--gray-600);
    cursor: pointer;
    margin-bottom: 2.5rem;

    &-container {
      padding-top: 0.5rem;
    }
  }
}
