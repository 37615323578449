.catalog-page {
  &__sample {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px 2rem 0.75rem;
    height: 100%;
    &-player {
      height: 207px;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      &-progress {
        display: flex;
        height: 38px;
        align-items: center;
        gap: 1.5rem;
        margin-bottom: 2.5rem;
        p {
          display: block;
          min-width: 50px;
          font-size: 12px;
          color: var(--gray-600);
        }
        .audio-progress-bar {
          overflow: hidden;
          background-color: var(--gray-200);
          &__point,
          &__progress {
            background-color: var(--orange);
          }
        }
      }

      &-controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
        margin-bottom: 0.25rem;
        button {
          border-radius: 0.75rem;
          padding: 0.5rem 1.5rem;
          svg {
            color: var(--gray-600);
          }
        }
      }
      &-delete {
        width: 43.5%;
        display: flex;
        justify-content: flex-end;
        > div {
          width: fit-content;
        }
        button {
          margin-left: auto;
          display: flex;
          align-items: center;
          gap: 4px;
        }
      }
      &-download {
        width: 43.5%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.5rem;
        flex-direction: row-reverse;
        button:first-child {
          align-self: center;
        }
        button:last-child {
          display: flex;
          align-items: center;
          gap: 3px;
          span {
            color: var(--secondary);
          }
        }
      }
      &-play {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 60px;
        button:first-child {
          background-color: transparent;
          outline: none;

          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border-radius: 50%;
          border: 1px solid var(--primary);
          transition: all 0.2s;
          &:hover {
            width: 60px;
            height: 60px;
          }
        }
        div:last-child {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          color: var(--gray-600);
        }
      }

      &-space {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        color: var(--gray-600);
      }
    }
  }

  &__sample-record {
    height: 207px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    .catalog-page__header {
      border: 0;
    }
    .waveform-container {
      position: relative;
      width: 100%;
      margin-bottom: 2.5rem;
      overflow: hidden;
      .waveform-baseline {
        position: absolute;
        width: 100%;
        top: 49%;
        background-color: #fb501d;
        height: 3px;
        border-radius: 100%;
      }
      .waveform-corner {
        background-color: white;
        z-index: 10;
        position: absolute;
        width: 100%;
        height: 80px;
        border-radius: 100%;
        top: 52%;
        left: 50%;
        &.corner-top-right {
          top: -50%;
        }
        &.corner-top-left {
          top: -50%;
          left: -50%;
        }
        &.corner-bottom-left {
          left: -50%;
        }
      }
      #waveform {
        margin: 0 auto;
        overflow: hidden;
      }
    }
    p:first-child {
      display: flex;
      align-items: center;
      height: 80px;
      margin-bottom: 2.5rem;
      color: var(--gray-600);
    }
    .justui_dropdown-toggle {
      display: flex;
      align-items: center;
      padding: 0.25rem 0.75rem;
      gap: 0.5rem;
      button {
        padding: 0;
      }
    }

    &-controls {
      width: 100%;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      gap: 4rem;
    }
    &-microphone {
      padding: 0;
      margin: 0;
      width: 43.5%;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      button {
        display: flex;
        align-items: center;
        gap: 4px;
        border-radius: 0.75rem;
        padding: 0.5rem 1.5rem;

        svg,
        span {
          color: var(--gray-600);
        }
      }
      .justui_dropdown-menu {
        margin-left: 0;
        border-color: var(--gray-100);
      }
      .justui_dropdown-item {
        text-align: end;
        justify-content: flex-start;
        display: flex;
        align-items: center;
        padding-left: 32px !important;
        &.active {
          padding-left: 0 !important;
          background-color: transparent;
          font-weight: 600;
          color: black;
          svg {
            color: black;
          }
        }
      }
    }
    &-start {
      display: flex;
      flex-direction: column;
      align-items: center;
      > button:first-child {
        outline: none;
        border: 0;
        background-color: transparent;
        > div {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: var(--orange);
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            color: white;
          }
        }
        width: 60px;
        height: 60px;
        border: 1px solid var(--orange);
        border-radius: 50%;
        padding: 0.25rem;
        display: flex;
        cursor: pointer;
        transition: all 0.2s;
        margin-bottom: 0.5rem;

        &:hover {
          padding: 0;
        }
      }
    }
    &-stop {
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: var(--primary);
    }
    &-duration {
      width: 43.5%;
      text-align: end;
    }
  }

  &__sample-media {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    &:focus-within {
      .catalog-page__sample-record:not(:focus-within) {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  &__sample-no-permission {
    &-container {
      margin-bottom: 66px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    text-align: center;
    width: 460px;
    h4 {
      color: var(--gray-900);
      font-size: 20px;
      font-weight: 600;
      line-height: 2rem;
      margin-bottom: 1rem;
    }
    p {
      line-height: 24px;
    }
  }
}
