.learning-data-modal.modal-dialog {
  max-width: 584px;
}

.learning-data-modal {
  .modal-body-container {
    padding-right: 0 !important;
    max-height: unset !important;
    .scrollbar-container {
      padding-left: 0.5rem;
      max-height: unset !important;
    }
  }
  .modal-content:not(.fullscreen) {
    margin: 0;
  }
  &.just-ui-modal .modal-footer {
    padding: 2rem;
    padding-top: 1.5rem;
  }
}

.learning-modal__heading {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.learning-modal__content {
  padding-bottom: 3px;
  margin-bottom: 4px;
  color: var(--grey-900);
  .FileUploadContainer {
    padding-top: 0;
    margin-top: 2.5rem;
    border: none;
    background-image: url("../../../../img/file-upload-border.svg");
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 1.5rem;
    min-height: 196px;
    background-color: white;
    cursor: pointer;
    span {
      text-decoration: underline dashed;
      cursor: pointer;
    }
    p {
      color: var(--grey-900);
      text-decoration: none;
      margin-bottom: 1.5rem;
    }
    svg.justui_icon.fa-color-secondary {
      color: var(--grey-300);
      margin-bottom: 1rem;
    }
    &.error {
      background-image: url("../../../../img/file-upload-border--error.svg");
    }
  }
  ul {
    padding-inline-start: 1.75rem;
  }
  input {
    margin-bottom: 1.5rem;
  }
  a {
    color: var(--grey-900);
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-color: var(--grey-400);
    span {
      border-bottom: 1px solid var(--grey-400);
      svg {
        transform: rotate(45deg);
      }
    }
  }
  .upload-flow-demo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.25rem;
    padding-top: 2rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
    .justui__icon-wrapper {
      display: block;
    }
    .demo-block {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      justify-content: center;
      text-align: center;
      box-shadow: 0px 1px 20px rgba(44, 46, 113, 0.08);
      border-radius: 16px;
      height: 85px;
      width: 120px;
      background-color: white;
      .bg-block {
        position: absolute;
        box-shadow: 0px 1px 20px rgba(44, 46, 113, 0.08);
        border-radius: 16px;
        height: 85px;
        width: 120px;
        top: -7px;
        left: -7px;
        &--2 {
          top: -14px;
          left: -14px;
        }
      }
      img {
        width: 24px;
        object-fit: contain;
      }
    }
  }
  .error-text {
    height: 16px;
  }
  .uploaded-file-block {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .justui__icon-wrapper {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      margin-bottom: 0;
    }
    svg.svg-inline--fa.fa-times.fa-w-10.justui_icon.fa-color-secondary {
      margin-bottom: 0;
    }
  }
}
.modal-base-buttons {
  display: flex;
  flex: 1;
  button {
    flex: 1;
  }
}

.learning-data-modal {
  padding-top: 1.125rem;
  .modal-content {
    overflow: visible !important;
  }
  .modal-header {
    position: relative;
    padding: 2rem;
    padding-bottom: 1.5rem;
  }
  &.custom-modal .modal-body {
    padding: 0 1.5rem 0;
    max-height: unset;
  }
  .close {
    margin: 0 !important;
    padding: 8px !important;
    background: rgba(0, 0, 0, 0.32);
    border-radius: 12px;
    position: absolute;
    top: 12px;
    right: -44px;
    color: white !important;
    opacity: 1 !important;
    display: flex;
  }
}

.modal .just-ui-modal.modal-dialog.learning-data-modal .modal-header .close {
  color: var(--secondary);
  font-weight: 300;
}
