.catalog-page {
  display: flex;
  flex: 1;
  &__container {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0 auto;
    border-top: 1px solid var(--gray-200);
  }

  &__catalog-container {
    max-width: 1672px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex: 1;
  }
  &__catalog-column:first-child {
    display: flex;
    flex-direction: column;
    width: 37%;
    border-right: 1px solid var(--gray-200);
  }
  &__catalog-column:last-child {
    max-width: 1085px;
    width: 63%;
  }

  &__catalog-tab {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    span {
      color: var(--gray-500);
    }
  }
}
