@import '../../../../../scss/custom/mixins';
.project-voices__more {
  width: 32px;
  height: 32px;
  border-radius: 12px;
  background-color: rgba(159, 172, 187, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gray-600);

  @include tablet-down {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.5rem;
  }
}
