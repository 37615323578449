@import '../../../../scss/custom/variables';
@import '../../../../scss/custom/mixins';

.syntharea {
  &__container {
    position: relative;
    flex: 1;
    flex-basis: 100%;
    &.disabled {
      .synth-area__textarea {
        background-color: var(--grey-100);
      }
    }
    .synth-area__textarea-controls {
      flex-direction: column;
      min-height: 50px;
      justify-content: flex-end;
      margin-top: 0.25rem;
      @include phone-only {
        .progress-mouse-tracker {
          margin-left: 1rem;
        }
      }
    }
    .synth-area__textarea {
      position: relative;
      display: flex;
      flex-direction: column;
      background-color: white;
      border: 1px solid var(--grey-100);
      border-radius: 0.25rem;
      padding: 0.75rem 1rem;
      &:focus-within {
        border-color: var(--grey-600);
        box-shadow: none;
      }
      .textarea-container textarea {
        resize: none;
        max-height: 70px;
        line-height: 1.3;
        border-color: transparent;
        box-shadow: none;
        padding: 0;
        &:hover {
          box-shadow: none !important;
          border-color: transparent !important;
        }
        &:focus {
          box-shadow: none !important;
          border-color: transparent !important;
        }
        @include scrollbar(4px, 2px, 0.5rem);
        &:disabled {
          background-color: transparent !important;
        }
      }
    }
  }
}
