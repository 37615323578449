.FileUploadContainer{
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px 10px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--grey-300);
  border-radius: 3px;
  position: relative;
  background-color: var(--grey-100);
  transition: 100ms ease-in-out;

  &_DnD {
    background-color: var(--primary-100);
    border: 1px dashed var(--primary-600);
  }

  a {
    color: var(--primary-600);
    text-decoration: underline dashed;
  }

  p:last-child{
    margin-bottom: 0;
  }
  .file-upload__loading--progress {
    width: 100%;
    padding: 0 2rem;
    text-align: left;
    p {
      margin-bottom: 0.25rem;
      font-weight: 700;
    }
  }
}
