@import '../../../../../scss/custom/mixins';

.my-page__projects-placeholder.placeholder {
  padding: 40px 94px 40px 110px;
  display: flex;
  justify-content: space-between;

  background-color: var(--gray-900);
  border-radius: 56px;

  .placeholder {
    &__text-block {
      margin: 60px auto;
      display: flex;
      flex-direction: column;
      color: white;
      gap: 3rem;
      button {
        align-self: flex-start;
        padding: 19px 39px;
        background-color: var(--primary-500);
        border-radius: 20px;
        color: var(--gray-900);
        &:hover,
        &:focus,
        &:active {
          background-color: var(--primary-600);
          color: var(--gray-900);
          border-color: var(--primary-600);
        }
      }
    }

    &__img-block {
      img {
        height: 370px;
      }
    }
  }

  &.landing {
    background-color: var(--primary);
    padding-top: 50px;
    padding-bottom: 50px;
    gap: 8.1rem;
    justify-content: unset;
    .placeholder {
      &__text-block {
        color: var(--gray-950);
        gap: 0;
        margin: 30px 0 30px 0;
        h1 {
          max-width: 30rem;
          margin-bottom: 2rem;
        }
        p {
          max-width: 19rem;
          margin-bottom: 3.5rem;
        }
        button {
          color: white;
          font-size: 14px;
          background-color: var(--gray-900);

          &:hover,
          &:active,
          &:focus {
            background-color: var(--gray-950);
            border-color: var(--gray-950);
          }
        }
      }

      &__img-block {
        img {
          height: 420px;
        }
      }
    }
  }

  @include md-screen-down {
    padding-top: 2rem;
    padding-bottom: 2rem;
    .placeholder {
      &__text-block {
        margin: 31px 31px 31px 0;
        h1 {
          font-size: 2rem;
          line-height: 2.25rem;
        }
      }

      &__img-block {
        img {
          height: 276px;
        }
      }
    }

    &.landing {
      padding: 50px 4rem;
      gap: 5.1rem;
      padding-right: 74px;

      .placeholder {
        &__text-block {
          margin-top: 14px;
          h1 {
            margin-bottom: 1.5rem;
          }
          p {
            font-size: 1rem;
            line-height: 1.5rem;
            margin-bottom: 48px;
          }
        }
        &__img-block {
          img {
            height: 312px;
            width: 346px;
          }
        }
      }
      button {
        color: white;
        font-size: 14px;
        background-color: var(--gray-900);

        &:hover,
        &:active,
        &:focus {
          background-color: var(--gray-950);
          border-color: var(--gray-950);
        }
      }
    }
  }

  @include tablet-down {
    padding: 80px 105px;
    flex-direction: column;
    align-items: center;
    gap: 80px;

    .placeholder {
      &__text-block {
        order: 1;
        margin: 0;
        text-align: center;

        br {
          display: none;
        }
      }
      &__img-block {
        order: 2;
      }
    }
    button {
      order: 3;
      padding: 19px 39px;
      background-color: var(--primary-500);
      border-radius: 20px;
      color: var(--gray-900);
      &:hover,
      &:focus,
      &:active {
        background-color: var(--primary-600);
        color: var(--gray-900);
        border-color: var(--primary-600);
      }
    }

    &.landing {
      padding: 4rem 2.5rem;
      gap: 4rem;
      .placeholder {
        &__text-block {
          width: 100%;
          margin: 0;
          h1,
          p {
            max-width: none;
          }
          p {
            margin-bottom: 0;
          }
        }
        &__img-block {
          img {
            height: 275px;
            width: 304px;
          }
        }
      }
    }
  }
  @include phone-only {
    padding: 2.5rem;
    gap: 0;
    border-radius: 40px;
    margin-top: -4px;
    .placeholder {
      &__text-block {
        margin: 0 -1.5rem 2.5rem -1.5rem;
        h1 {
          font-size: 1.5rem;
          line-height: 2rem;
          br {
            display: block;
          }
        }
      }
      &__img-block {
        margin-bottom: 2rem;
        img {
          height: 251px;
          width: 274px;
        }
      }
    }
    button {
      width: 100%;
    }
    &.landing {
      border-radius: 32px;
      padding: 1rem;
      gap: 0;
      .placeholder {
        &__text-block {
          padding-top: 1rem;
          align-items: center;
          h1 {
            font-size: 1.25rem;
            line-height: 1.5rem;
            max-width: 98%;
            margin-bottom: 1rem;
          }
          p {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 2rem;
          }
        }
        &__img-block {
          img {
            width: 200px;
            height: 180px;
          }
        }
      }
    }
  }
}
