@import '../../../../scss/custom/mixins';
.voice-placeholder {
  position: relative;
  border-radius: 50%;
  background-color: var(--gray-100);

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.2;
  }

  &--main {
    width: 170px;
    height: 170px;
    transition: transform 0.15s ease-in-out;

    @include phone-only {
      width: 112px;
      height: 112px;
    }
  }

  &--big {
    height: 8rem;
    flex: 0 0 8rem;
    margin-right: 2.5rem;
  }

  &--medium {
    width: 6.25rem;
    height: 6.25rem;
  }

  &--small {
    display: flex;
    height: 2.5rem;
    min-width: 2.5rem;
    @include phone-only {
      height: 2rem;
      min-width: 2rem;
    }
  }

  &--scale {
    &:hover {
      transform: scale(1.2);
    }
  }
}
