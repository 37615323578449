@import '~@just-ai/just-ui/scss/basic/custom/functions';
@import '~@just-ai/just-ui/scss/basic/custom/variables';
@import '~@just-ai/just-ui/scss/basic/aimyvoice/colors';
$primary: $primary-500;
$primary-light: $orange-400;
$primary-light-20: $orange-500;

$screen-width-lg: 1439px;
$screen-width-md: 1200px;
$screen-width-tablet: 840px;
$screen-width-670: 740px;
$screen-width-mobile: 560px;

$enable-shadows: false;

$projects-grey: #f9f9fb;

button.justui_button.btn:not(.btn-lg):not(.btn-sm):not(.btn-xl) {
  display: flex;
  line-height: 16px;
  font-weight: normal;
}

$grid-breakpoints: map-merge(
  $grid-breakpoints,
  (
    xs: 0,
    sm: 560px,
    md: 768px,
    lg: 992px,
    xl: 1380px,
  )
);

:root {
  --primary-red: #ff463a;
  --primary-red-rgb: 255, 70, 58;
  --border-radius-lg: 2rem;
  --screen-width-lg: $screen-width-lg;
  --screen-width-md: $screen-width-md;
  --screen-width-tablet: $screen-width-tablet;
  --screen-width-670: $screen-width-670;
  --screen-width-mobile: $screen-width-mobile;
  --projects-grey: #f9f9fb;
}
