@import '../../../../scss/custom/variables';
@import '../../../../scss/custom/mixins';

.page-width-container {
  max-width: 1672px;
  width: 100%;
  display: flex;
  flex: 1;
  padding: 0 2rem;

  @include md-screen-down {
    flex-direction: column;
    padding: 0;
  }

  .placeholder {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 72px;
    text-align: center;
    h3 {
      font-size: 2rem;
      font-weight: 600;
      line-height: 44px;
      margin-bottom: 1.5rem;
    }
    p {
      margin-bottom: 2.5rem;
      font-size: 1rem;
      line-height: 28px;
    }
    button {
      padding: 20px 40px;
      border-radius: 20px;
    }

    @include phone-only {
      padding: 50px 1rem;
      h3 {
        font-size: 20px;
        line-height: 28px;
      }
      p {
        font-size: 14px;
        line-height: 24px;
      }
      button {
        width: 100%;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }
  }
}

.voices-catalog {
  &__container {
    height: 100%;
    max-width: 100%;
    background-color: white;
    display: flex;
    padding: 2rem 0;
    justify-content: center;
    padding-bottom: 7.5rem;
    @include md-screen-down {
      padding-bottom: 4rem;
    }
    @include phone-only {
      padding-bottom: 2.5rem;
      padding-top: 1.5rem;
    }
  }
  &__side-filters {
    flex-basis: 336px;
    padding-right: 1.5rem;
    margin-right: 1rem;
    > div {
      margin-bottom: 1.5rem;
    }
    span {
      color: var(--gray-600);
    }
    &-mobile {
      position: sticky;
      top: -1px;
      left: 0;
      z-index: 11;
      background-color: white;
      width: 100%;
      .voices-catalog__side-filters__purpose {
        margin-top: 1rem;
      }
      &.sticky {
        z-index: 1000;
        box-shadow: 0px 1px 20px rgba(44, 46, 113, 0.12);
      }
      > div {
        gap: 1rem;
        display: flex;
        @include phone-only {
          gap: 0.5rem;
        }
      }

      button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        flex: 1;
        .active-filters {
          width: 20px;
          height: 18px;
          background-color: var(--gray-900);
          color: white;
          border-radius: 0.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          line-height: 1rem;
        }

        @include phone-only {
          gap: 0.25rem;
          justify-content: center;
          .active-filters {
            width: 18px;
            height: 18px;
            font-size: 10px;
            margin-top: -1px;
            margin-bottom: -1px;
          }
        }
      }

      @include md-screen-down {
        padding: 1rem 0;
        > div:first-child {
          padding: 0 36px;
        }
      }
      @include tablet-down {
        > div:first-child {
          padding: 0 1.5rem;
        }
      }
      @include phone-only {
        padding: 0.5rem 0;
        > div:first-child {
          padding: 0 0.5rem;
        }
        .purpose-filter:first-child {
          margin-left: 0.5rem;
        }
        .purpose-filter:last-child {
          margin-right: 0.5rem;
        }
      }
    }
    &__gender,
    &__language {
      p {
        margin-bottom: 0.5rem;
        font-weight: 600;
      }
    }
    &__header {
      h1 {
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 48px;
      }
      span {
        font-size: 1rem;
        line-height: 28px;
        color: var(--gray-600);
      }
      display: flex;

      align-items: baseline;
      gap: 10px;
      @include md-screen-down {
        gap: 1.5rem;
        padding-left: 36px;
        margin-bottom: 1rem;
      }
      @include tablet-down {
        padding-left: 1.5rem;
      }
      @include phone-only {
        gap: 0.5rem;
        padding-left: 1rem;
        h1 {
          font-size: 1.5rem;
          line-height: 32px;
        }
        span {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
    &__purpose {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      gap: 0.5rem;
      margin-top: -0.5rem;

      .purpose-filter {
        padding: 0.5rem 1.25rem;
        background-color: $greyblue-light;
        border-radius: 1.5rem;
        cursor: pointer;
        transition: background 0.15s ease-in-out;
        &.selected {
          background-color: var(--primary);
          color: white;
        }
        &:not(.selected):hover {
          background-color: $greyblue;
        }
      }
      @include md-screen-down {
        flex-direction: row;
        margin-top: 0;
        padding-left: 36px;
      }
      @include tablet-down {
        padding-left: 1.5rem;
      }
      @include phone-only {
        font-size: 12px;
        white-space: nowrap;
        overflow-x: scroll;
        > div {
          padding: 5px 1rem;
        }
        > div:first-child {
          margin-left: 0.5rem;
        }
        > div:last-child {
          margin-right: 0.5rem;
        }
      }
      &::-webkit-scrollbar {
        display: none !important;
      }
    }
    &__super {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      .custom-switch {
        margin-left: 0.5rem;
      }
      p {
        margin-bottom: 0;
      }

      .justui__icon-wrapper {
        display: flex;
      }
      svg {
        width: 0.75rem;
        height: 0.75rem;
      }

      @include md-screen-down {
        justify-content: space-between;
        align-items: center;
        > div:first-child {
          display: flex;
          align-items: center;
          gap: 6px;
        }
        p {
          font-weight: 600;
          line-height: 24px;
        }
      }
    }
    &__price {
      .price-inputs-block {
        display: flex;
        gap: 1rem;
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        > div {
          display: flex;
          position: relative;
          span {
            color: var(--secondary);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0.75rem;
          }
          input {
            padding: 0.5rem 0.75rem;
            text-align: right;
          }
        }
      }
    }
    .price-slider-container {
      height: 2rem;

      .slider-root-styles {
        width: calc(100% - 18px);
        margin: auto;
        position: relative;
      }
      .slider-handle:hover + div[role='slider'] {
        border-color: #1a1a1a !important;
        width: 18px !important;
        height: 18px !important;
      }
    }

    @include md-screen-down {
      flex-basis: auto;
      padding-right: 0;
      margin-right: 0;
      padding: 0 2rem;
    }

    @include phone-only {
      padding: 0;
    }
  }
  &__promo {
    position: relative;
    padding: 1.5rem;
    border-radius: 8px;
    background: radial-gradient(
      119.14% 123.02% at 80.67% 120%,
      rgba(255, 245, 0, 0.08) 0%,
      rgba(242, 74, 144, 0.08) 64.57%,
      rgba(255, 61, 0, 0.08) 100%
    );
    h4 {
      margin-bottom: 1.25rem;
    }
    p {
      margin-bottom: 2rem;
    }
    button {
      width: 100%;
      position: relative;
      z-index: 10;
    }
    .gradient-bg-1 {
      position: absolute;
      width: 100%;
      height: 62%;
      bottom: 0;
      left: 0;
      background: url('../../../../img/promo-bg-1.svg');
      background-size: cover;
    }
    .gradient-bg-2 {
      position: absolute;
      width: 100%;
      height: 42%;
      bottom: 0;
      left: 0;
      background: url('../../../../img/promo-bg-2.svg');
      background-size: cover;
    }

    @include md-screen-down {
      margin: 120px 36px 0 36px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      button {
        padding: 0.5rem 1.5rem;
      }
    }
    @include tablet-down {
      margin: 120px 1.5rem 0 1.5rem;
    }

    @include phone-only {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      margin-top: 80px;
      text-align: center;
      h1 {
        font-size: 20px;
      }
      a {
        width: 100%;
      }
    }
  }
  &__voices-list {
    flex: 1;
    .pagination__container {
      justify-content: flex-start;
      @include md-screen-down {
        margin-left: 2rem;
      }
      @include phone-only {
        margin-left: 0.5rem;
      }
    }
  }
  &__voices-block {
    display: flex;
    flex-wrap: wrap;
    .card-body {
      text-align: center;
    }
    margin-bottom: 2rem;
    @include md-screen-down {
      padding-top: 0.5rem;
    }
  }

  &__top-sorters {
    margin-bottom: 1rem;
    margin-top: 1.46rem;
    span {
      display: inline-block;
      font-size: 0.75rem;
      margin-right: 1rem;
      &.selected {
        font-weight: 600;
        color: var(--primary);
      }
      &:not(:first-child) {
        cursor: pointer;
      }
    }
  }

  &__side-filters-mobile {
    .justui_button {
      @include phone-only {
        font-size: 12px;
        letter-spacing: 0;
      }
    }
  }
}

.license-voice-modal__header {
  > p {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}
