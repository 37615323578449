@import '~@just-ai/just-ui/scss/basic/custom/functions';
@import '~@just-ai/just-ui/scss/basic/custom/variables';
@import '~bootstrap-scss/mixins/breakpoints';

.just-ui-card {
  display: flex;
  position: relative;
  background: var(--white);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.16), 0px 1px 3px rgba(12, 43, 66, 0.06), 0px 0px 1px rgba(17, 60, 92, 0.06);
  border-radius: 4px;
  padding: 16px;
  flex-direction: row;
  &--with-hover {
    transition: box-shadow 300ms ease-in-out;
    &:hover {
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 6px 12px rgba(12, 43, 66, 0.16),
        0px 0px 1px rgba(17, 60, 92, 0.06);
    }
  }

  &--force-hover {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 6px 12px rgba(12, 43, 66, 0.16), 0px 0px 1px rgba(17, 60, 92, 0.06);
  }

  &--dark {
    background: $greyblue-medium;
    border: 1px solid $greyblue-dark;
  }

  &.p {
    &-lg {
      padding: 24px;
    }
    &-md {
      padding: 16px;
    }
    &-sm {
      padding: 12px;
    }
    &-xs {
      padding: 8px;
    }
    &-y-lg {
      padding-top: 24px;
      padding-bottom: 24px;
    }
    &-x-lg {
      padding-left: 24px;
      padding-right: 24px;
    }
    &-y-md {
      padding-top: 16px;
      padding-bottom: 16px;
    }
    &-x-md {
      padding-left: 16px;
      padding-right: 16px;
    }
    &-y-sm {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    &-x-sm {
      padding-left: 12px;
      padding-right: 12px;
    }
    &-y-xs {
      padding-top: 8px;
      padding-bottom: 8px;
    }
    &-x-xs {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  &.just-ui-card_with-spoiler {
    padding: 0;
    .justui_spoiler-header-wrap {
      padding: 8px;
    }
    .justui_spoiler-collapse {
      padding: 16px;
    }
    .just-ui-card_icon-component {
      padding: 8px;
    }
  }

  &.noPadding {
    padding: 0 !important;
  }

  .card-body {
    padding: 0;
  }

  &.no-b {
    &-t {
      //border-top: none;
      border-top-color: transparent;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      //padding-top: 16px;
    }
    &-b {
      //border-bottom: none;
      border-bottom-color: transparent;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      //padding-bottom: 16px;
    }
    &-l {
      //border-left: none;
      border-left-color: transparent;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      //padding-left: 16px;
    }
    &-r {
      //border-right: none;
      border-right-color: transparent;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      //padding-top: 16px;
    }
    &-t-l {
      border-top-left-radius: 0;
    }
    &-t-r {
      border-top-right-radius: 0;
    }
    &-b-r {
      border-bottom-right-radius: 0;
    }
    &-b-l {
      border-bottom-left-radius: 0;
    }
  }
  &.no-border {
    //border: none;
    //padding: 16px;
    border-color: transparent;
  }
  &.no-border-radius {
    border-radius: 0;
  }

  &_icon-component {
    //display: flex;
    //align-items: center;
    & > button,
    & > div > button {
      width: 32px;
      height: 32px;
      padding: 0;
      & > svg {
        font-size: 16px;
      }
    }
  }
}
