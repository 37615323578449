.catalog-page__catalog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 2rem;
  .justui_tabs {
    gap: 1.5rem;
    &.nav {
      margin-bottom: 0;
    }
    li.active {
      border-color: var(--gray-900) !important;
    }
    a:not(.active) {
      span {
        color: var(--gray-500);
      }
    }
    a {
      padding: 1.2rem 0;
    }
  }
  .justui__tab:not(.active) {
    border-bottom: 0;
    &:hover {
      border-bottom: 0;
      .catalog-page__catalog-tab {
        color: var(--grey-950) !important;
      }
    }
  }
}

.catalog-page__catalog-buttons {
  margin-right: 0.75rem;
  display: flex;
  align-items: center;
  padding: 6px 0;
  button {
    height: 3rem;
    width: 3rem;
    svg {
      color: var(--gray-600);
    }
    &:hover {
      svg {
        color: black;
      }
    }

    &:disabled {
      opacity: 1 !important;
      svg {
        color: rgba(159, 172, 187, 1);
      }
      &:hover {
        background-color: transparent;
        color: #c3c6c9 !important;
        cursor: pointer;
      }
    }
  }
}
