@import '../../../../scss/custom/variables';
@import '../../../../scss/custom/mixins';

.voice-page__published {
  width: 100%;
  padding-bottom: 100px;
  @include md-screen-up {
    .voice-page__container & {
      margin-left: 4rem;
    }
  }
  @include md-screen-down {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @include phone-only {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .published {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2rem;
      @include md-screen-down {
        margin-bottom: 1rem;
        h1 {
          display: none;
        }
      }
    }
    &__token {
      position: relative;
      border-radius: 0.75rem;

      &:hover {
        background: var(--gray-100);
      }
      &-button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.25rem 0.75rem;
        border-radius: 0.75rem;
        cursor: pointer;
      }
      &.active {
        background: var(--gray-100);
      }
      &-popup {
        position: absolute;
        z-index: 100;
        padding: 1.5rem;

        box-shadow: 0px 1px 20px rgba(44, 46, 113, 0.12);
        background-color: white;
        border-radius: 8px;
        right: 0;
        top: 0;
        margin-top: 33px;
        cursor: auto;
        max-width: 90vw;
        > div {
          display: flex;
          align-items: stretch;
          gap: 0.5rem;
        }
        @include md-screen-down {
          left: 0;
          right: auto;
        }
        input {
          min-width: 16.25rem;
          &:read-only {
            background-color: white;
          }
          @include phone-only {
            min-width: 12rem;
          }
        }
        button {
          padding-left: 15px !important;
          padding-right: 15px !important;
          border-radius: 0.25rem;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
    &__empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid var(--gray-200);
      border-radius: var(--border-radius-lg);
      padding: 3rem 0;
      text-align: center;

      @include md-screen-down {
        border-color: transparent;
      }

      @include phone-only {
        a {
          width: 100%;
          button {
            width: 100%;
          }
        }
      }

      h3 {
        margin-bottom: 1rem;
      }

      p {
        max-width: 30rem;
        margin-bottom: 2rem;
      }

      button {
        a:hover {
          color: inherit;
        }
      }
    }
    &-card__container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2rem;
      padding-top: 1.75rem;
      background-color: var(--gray-100);
      border-radius: 1.5rem;

      @include md-screen-down {
        padding: 1.5rem;
      }

      @include phone-only {
        padding: 1rem;
        h4 {
          font-size: 1rem;
          line-height: 1.5rem;
        }
        .mobile-button-container {
          button {
            width: 100%;
          }
        }
      }

      &.justui_collapse__container-opened {
        background: white;
      }

      &.justui_collapse__container {
        transition: background 0.25s linear;
        &:not(.justui_collapse__container-opened) {
          border-color: transparent;
        }
      }

      &.actual {
        background: radial-gradient(164% 425.72% at -30.18% -224.67%, #fff6f4 53.35%, #f7f4ff 87.34%);
        h4:last-of-type {
          color: var(--primary);
        }
        .synth-area__textarea {
          background: rgba(255, 255, 255, 0.56);
          box-shadow: inset 1px 2px 5px rgba(255, 255, 255, 0.5);
          backdrop-filter: blur(16px);
          border: 1px solid transparent;
          &:hover {
            border: 1px solid transparent;
          }
          &:focus-within {
            border: 1px solid var(--grey-900);
          }
          textarea {
            background-color: transparent;
          }
          .textarea-container {
            background-color: transparent;
          }
        }
      }
      &:has(.show) {
        background-color: white;
      }
    }
    &-card__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include phone-only {
        flex-direction: column;
        align-items: stretch;
        gap: 1rem;
      }
      div:first-child {
        display: flex;
        gap: 1.5rem;
      }
      button {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
    &-card__time {
      display: flex;
      gap: 1rem;
      margin: 2.625rem 0 0.625rem;
      justify-content: flex-end;
      p {
        color: var(--gray-600);
      }
      @include md-screen-down {
        margin: 1.5rem 0 0.5rem;
      }
      @include phone-only {
        margin-top: 1rem;
        flex-direction: column;
        gap: 0;
      }
    }
    &-card__connected {
      color: var(--primary);
      display: flex;
      gap: 0.5rem;
      align-items: center;
      line-height: 1.5rem;
    }

    &-card__synth-area {
      padding-bottom: 2rem;
      border-bottom: 1px solid var(--grey-200);
      .synth-area__textarea-controls {
        max-width: 625px;
        min-height: 50px;
      }
      .synth-area__textarea {
        border-radius: 16px;
        padding: 1.5rem;
        margin-top: 1.5rem;
        padding-top: 1.25rem;
        border-color: var(--grey-200);
        &:focus-within {
          border: 1px solid var(--grey-900);
        }
        @include md-screen-down {
          padding-top: 1rem;
        }
        @include phone-only {
          padding: 1rem;
          .justui-audio-player .audio-progress-bar {
            width: 100%;
          }
        }
      }
    }

    &__actual {
      margin-bottom: 2.5rem;
    }
    &__not-actual {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .justui_collapse__header {
        height: 38px;
        .justui_collapse__header_collapseButton {
          @include phone-only {
            margin-top: 4px;
            margin-left: 1rem;
          }
        }
      }
      .justui_collapse__container {
        min-width: unset;
      }
    }
  }
}

.justui_collapse__container .justui_collapse__header_collapseButton svg path {
  fill: var(--grey-950);
}

.justui_collapse__header {
  span {
    display: flex;
    align-items: center;
    width: 94%;
    justify-content: space-between;
  }
}
