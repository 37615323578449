@import '~@just-ai/just-ui/scss/basic/custom/functions';
@import '~@just-ai/just-ui/scss/basic/custom/variables';
@import '~bootstrap-scss/mixins/breakpoints';

.modal {
  .just-ui-modal {
    &.dark-theme {
      .modal-content {
        background-color: $dark-bg;
        color: white;
      }
    }

    @include media-breakpoint-up(sm) {
      &.modal-dialog {
        margin: $modal-dialog-margin-y-sm-up auto 0;
      }
    }

    .modal-content {
      overflow: hidden;
      box-shadow: $modal-content-box-shadow-sm-up;
    }

    .modal-header {
      .modal-title {
        line-height: $modal-title-line-height;
        margin-right: 1rem;
        font-weight: bold;
        color: var(--gray-800);
      }

      .close {
        outline: none;
        line-height: 0.75rem;
        opacity: 1 !important;
        padding: $modal-close-icon-padding $modal-close-icon-padding 0 0;
        margin: -#{$modal-header-padding-x} -#{$modal-header-padding-x} -#{$modal-header-padding-x} auto;
        text-shadow: none;

        span {
          display: inline-flex;
        }
      }
    }

    .modal-body {
      padding: 0 0 0 $modal-header-padding-x;
      overflow: auto;

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--gray-200);
        border-radius: 5px;
        transition: 100ms;

        &:hover {
          background-color: var(--gray-300);
        }
      }

      .form-group {
        border: 3px solid transparent;
        margin-bottom: 1.5rem;
      }

      .modal-body-container {
        padding-right: #{$modal-header-padding-x * 0.5};

        &.natural-scrollbar {
          padding-right: 0;
          width: calc(100% - 1.5rem);
        }
      }

      & .scrollbar-container {
        padding-right: #{$modal-header-padding-x * 0.5};

        & .ps__rail-y {
          width: 0.875rem;
          background-color: transparent;
          cursor: pointer;
          border-left: 1px solid transparent;

          &:hover {
            border-color: lighten($black, 90%);
            background-color: lighten($black, 97%);
          }

          & .ps__thumb-y {
            left: 1px;
            right: auto;
            background-color: var(--primary);
            transition: background-color 0.2s linear, width 0.2s ease-in-out, left 0.2s ease-in-out;
          }

          &:hover,
          &.ps--clicking {
            background-color: lighten($black, 97%);

            & .ps__thumb-y {
              background-color: var(--primary);
            }
          }
        }
      }
    }

    .fixed-banner-bottom-container {
      padding-right: 1.5rem;
      padding-top: 1.5rem;
    }

    .fixed-banner-top-container {
      padding-right: 1.5rem;
      padding-bottom: 1.5rem;
    }

    .modal-footer {
      padding: $modal-header-padding;

      .modal-base-buttons {
        margin: -0.25rem;

        & > * {
          margin: 0.25rem;
          display: inline-block;
        }
      }
    }
  }
}
