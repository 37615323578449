@import './custom/variables';
@import './just-ui-styles.scss';

@font-face {
  font-family: Golos;
  src: url('./fonts/Golos-Text_Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Golos;
  src: url('./fonts/Golos-Text_Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: Golos;
  src: url('./fonts/Golos-Text_Black.ttf') format('truetype');
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: Golos;
  src: url('./fonts/Golos-Text_DemiBold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: Golos;
  src: url('./fonts/Golos-Text_Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

.justui_icon-button.justui_button.btn {
  &:focus {
    border-color: transparent;
  }
}
