@import "../../../../scss/custom/mixins";

.avatar {
  &__container {
    &:not(.fullscreen).modal-content {
      width: 456px;
      margin: auto;
      border-radius: 2.5rem;
      @include tablet-down {
        border-radius: 0;
      }
    }
    &.fullscreen {
      .avatar__header {
        .close {
          position: static;
        }
        @include tablet-down {
          align-items: center;
        }
        @include phone-only {
          h3 {
            font-size: 1rem;
            line-height: 1.5rem;
          }
          .avatar__crop-header-back {
            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
  &__modal {
    padding: 2rem;
    &-1 {
      @include tablet-down {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        height: 100% !important;
      }
    }
    &-2 {
      @include tablet-down {
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        height: 100% !important;
      }
      @include phone-only {
        padding: 1rem;
      }
    }
    &-3 {
      padding: 4rem 4rem 3.5rem 4rem;
      @include phone-only {
        padding: 30px;
        display: flex;
      }
    }
  }
  &__header {
    &.custom-modal__header {
      margin-bottom: 3.25rem;
    }
    .close:not(:disabled):not(.disabled):hover,
    .close:not(:disabled):not(.disabled):focus {
      opacity: 1;
    }
    h3.modal-title {
      line-height: 2rem;
    }
  }
  &__body {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include tablet-down {
      justify-content: space-between;
    }
  }
  &__step-wrapper {
    flex: 1;
  }
  &__upload-container {
    input {
      display: none;
    }
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='150' ry='150' stroke='%239FACBBFF' stroke-width='1' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 150px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    @include phone-only {
      width: 260px;
      height: 260px;
    }
  }
  &__upload {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    span.justui__icon-wrapper {
      display: block;
      margin-top: 8px;
      margin-bottom: 10px;
    }
    svg {
      color: var(--gray-200);
      width: 2.5rem !important;
      height: 2.5rem !important;
      font-size: 2.5rem !important;
    }
    p {
      width: 152px;
    }
  }

  &__upload-button {
    display: flex;
    flex-direction: column;
    width: 235px;
    text-align: center;
    p {
      margin-bottom: 1.5rem;
    }
  }

  &__crop-header {
    &.avatar__header {
      margin-bottom: 1rem;
    }
    .modal-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
    }
    &-back {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 0;
      border-radius: 50%;
      color: var(--gray-600);
      svg {
        font-weight: 400;
      }
      &:hover {
        background-color: var(--gray-100);
      }
    }
  }
  &__crop-body {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 2.5rem;
    margin-top: 1.5rem;
    &-loader {
      display: flex;
      height: 350px;
      justify-content: center;
      align-items: center;
    }

    @include tablet-down {
      margin-bottom: 1.5rem;
      margin-top: 0;
    }

    @include phone-only {
      min-height: calc(100vh - 11rem);
    }
  }
  &__crop-footer {
    width: 100%;
    padding: 0;
    justify-content: space-between;
    button {
      margin: 0;
      width: 100%;
    }

    @include tablet-down {
      gap: 0.5rem;
      button {
        display: flex;
        flex: 1;
        border-radius: 18px;
      }
    }
    @include phone-only {
      flex-direction: column-reverse;
      button {
        width: 100%;
      }
    }
  }
  &__crop-finishing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    img {
      padding: 10px;
      border-radius: 50%;
      height: 100%;
      @include tablet-down {
        height: 330px;
        width: 330px;
      }
      @include phone-only {
        height: 260px;
        width: 260px;
      }
    }
  }
  &__image-uploading {
    width: 100%;
    position: relative;
    margin-bottom: 2rem;
    height: 328px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include tablet-down {
      width: 330px;
      height: auto;
    }
    @include phone-only {
      width: 260px;
    }
  }
  &__border-uploading {
    position: absolute;
    top: 0;
    height: 328px;
    width: 328px;
    border: 2px solid var(--primary);
    border-radius: 50%;
    border-right-color: transparent;
    animation: spinning 5s linear infinite;
    animation-delay: 200ms;
    @include tablet-down {
      left: 0;
      height: 330px;
      width: 330px;
    }
    @include phone-only {
      left: 0;
      width: 260px;
      height: 260px;
    }
  }
  .fullscreen {
    display: flex;
    margin: 0;
    max-width: inherit;
    max-height: none;
    height: fit-content;
    border-radius: 0;
    .custom-modal {
      padding: 0;
      display: flex;
      flex: 1;
      flex-direction: column;
    }
    .modal-content {
      width: 100%;
      height: 100%;
      border-radius: 0 !important;
    }
  }
}
.ReactCrop__crop-selection {
  border-image-source: none;
  border-radius: 50%;
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.ReactCrop__crop-selection:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.ReactCrop__image {
  max-height: calc(65vh - 3.5rem);
  @include tablet-down {
    max-height: calc(65vh - 2rem);
  }
  @include phone-only {
    max-height: calc(65vh - 0.5rem);
  }
}

.ord-ne,
.ord-nw,
.ord-se,
.ord-sw {
  padding: 0.5rem;
  z-index: 2;
}
.ord-sw::after,
.ord-se::after,
.ord-ne::after,
.ord-nw::after {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #f4f5f8;
  opacity: 0.4;
  border: 1px solid #818f9e;
}

.ord-nw {
  margin-top: -0.5rem !important;
  margin-left: -0.5rem !important;
}
.ord-ne {
  margin-top: -0.5rem !important;
  margin-right: -0.5rem !important;
}
.ord-sw {
  margin-bottom: -0.5rem !important;
  margin-left: -0.5rem !important;
}
.ord-se {
  margin-bottom: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.animated-avatar-modal {
  transition: height ease-in-out 0.3s;
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(720deg);
  }
}
