@import '../../../../../scss/custom/mixins';

.project-link {
  cursor: pointer;
  &:hover {
    color: unset;
  }
}
.my-page__project-card.project-card {
  padding: 2rem;
  background-color: var(--gray-100);
  border-radius: 2rem;
  height: 240px;
  display: flex;
  flex-direction: column;
  transition: 0.2s;

  &:hover {
    background-color: var(--gray-200);
    .voice-placeholder {
      background-color: var(--gray-200) !important;
    }
  }

  &.editing {
    .project-card__title {
      flex-direction: column;
      align-items: flex-start;
      margin: -10px -1rem 0 -1rem;

      p {
        color: var(--primary-red);
      }
    }
  }

  .project-card {
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h4 {
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 28px;
        margin-bottom: 1rem;
        font-weight: 500;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      textarea {
        padding: 10px 1rem;
        background-color: transparent;
        border: 1px solid var(--gray-600);
        border-radius: 18px;
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        overflow: auto;
        outline: none;
        box-shadow: none;
        margin-bottom: 4px;
        overflow: scroll !important;

        &::-webkit-scrollbar {
          display: none;
        }

        &.error {
          border-color: var(--primary-red);
          background: rgba(255, 70, 58, 0.08);
        }
      }
      .dropdown {
        align-self: flex-start;
        .justui_dropdown-toggle {
          display: flex;
          gap: 0.25rem;
          align-items: center;
          padding: 0;
          background-color: transparent !important;
          outline: none !important;
          border: unset !important;
          padding: 0.25rem;
          transition: 0.2s;
          svg {
            width: 1.5rem;
            height: 1.5rem;
            transition: 0.2s;
          }
          svg,
          &:focus {
            color: var(--gray-500);
          }

          &:hover {
            svg {
              color: var(--gray-950);
            }
          }
        }
        .justui_dropdown-menu {
          transform: translate3d(-112px, 32px, 0) !important;
        }
      }
    }

    &__changed {
      color: var(--gray-600);
    }

    &__voices {
      margin-top: auto;
      display: flex;
      gap: 0.5rem;
      img,
      .voice-placeholder {
        width: 2rem;
        height: 2rem;
        border-radius: 12px;
        margin: 0;
        transition: 0.2s;
        background-color: var(--gray-100);
        min-width: unset;
      }
    }
  }

  @include md-screen-down {
    height: 184px;
    padding: 1.5rem;

    .project-card {
      &__title {
        h4 {
          @include p3;
          margin-bottom: 11px;
          -webkit-line-clamp: 2;
        }
        textarea {
          @include p3;
          margin-bottom: 0;
          padding: 0.5rem;
        }

        .dropdown {
          .justui_dropdown-toggle {
            svg {
              width: 1rem;
              height: 1rem;
            }
          }
          .justui_dropdown-menu {
            transform: translate3d(-125px, 20px, 0) !important;
          }
        }
      }

      &__changed {
        font-size: 0.75rem;
        line-height: 1.25rem;
      }
    }

    &.editing {
      .project-card__title {
        margin: -0.5rem -0.5rem 0 -0.5rem;
      }
    }
  }

  @include tablet-down {
    height: 140px;
    padding: 1rem;
    border-radius: 1.5rem;

    .project-card {
      &__title {
        h4 {
          font-size: 0.875rem;
          line-height: 1.25rem;
          margin-bottom: 0.25rem;
        }
      }
      &__voices {
        gap: 0.25rem;
        img,
        .voice-placeholder {
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 0.5rem;
          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    }
  }
}
