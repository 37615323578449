.catalog-page {
  &__placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 6.25rem;
    h2 {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }
    p {
      width: 25rem;
      font-size: 1rem;
      line-height: 28px;
      margin: auto;
    }

    &-buttons {
      margin-top: 2.5rem;
      display: flex;
      justify-content: center;
      gap: 1rem;
      button:first-child {
        border-radius: 1rem;
        padding: 13px 45px !important;
      }
      button:last-child {
        border-radius: 1rem;
        padding: 13px 38px;
      }
    }
  }
}
