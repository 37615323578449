@import '../../../../scss/custom/mixins';
.voice-page__usage {
  width: 100%;
  h1 {
    margin-bottom: 2rem;
  }
  .usage {
    &__empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid var(--gray-200);
      border-radius: 2rem;
      padding: 72px 0;
      text-align: center;

      h3 {
        margin-bottom: 1rem;
      }

      p {
        max-width: 26rem;
        margin-bottom: 2.5rem;
      }

      button {
        padding: 15px 2.5rem;
        border-radius: 1.25rem;
        a:hover {
          color: inherit;
        }
      }

      @include md-screen-down {
        border: 0;
        padding: 0 1.5rem;
        margin-top: 4rem;
      }
      @include phone-only {
        padding: 0rem 1rem;
        margin-top: 2.5rem;
        h4 {
          margin-bottom: 1rem;
        }
        a {
          width: 100%;
          button {
            width: 100%;
            padding-top: 11px;
            padding-bottom: 11px;
          }
        }
      }
    }
    &__table {
      padding: 1.5rem 0.75rem;
      border: 1px solid var(--gray-200);
      border-radius: 2rem;
      margin-bottom: 2rem;

      @include md-screen-down {
        padding: 0 1.5rem;
        border: 0;
      }
      @include phone-only {
        padding: 0 1rem;
      }

      &-header {
        padding-bottom: 1rem;
        border-bottom: 1px solid var(--gray-200);
        margin-bottom: 0.75rem;
        .usage__table-row {
          padding-top: 0;
          padding-bottom: 0;
        }
        p {
          font-weight: 600;
        }
      }
      &-row {
        padding: 0.75rem 1rem;

        display: flex;
        p {
          min-width: 125px;
          flex-basis: 270px;
          flex-shrink: 1;
        }
        p:last-child {
          text-align: right;
        }

        @include md-screen-down {
          gap: 0;
          padding: 0.75rem 0;
        }
      }

      &-body {
        .usage__table-row {
          &:hover {
            background-color: var(--gray-100);
            border-radius: 0.25rem;
          }
        }
        @include md-screen-down {
          border-bottom: 1px solid var(--gray-200);
        }
      }
    }
    &__banner {
      @include md-screen-down {
        padding: 0 1.5rem;
        button {
          border: 0;
        }
      }
      @include tablet-down {
        .banner__text {
          max-width: 340px !important;
        }
      }
      @include between-tablet-and-phone {
        .voice-page__banner {
          flex-direction: column;
          padding: 1.5rem;
          gap: 52px;
          button {
            width: 100%;
            padding: 1rem 0;
          }
          .banner__text {
            max-width: 100% !important;
            width: 100%;
            h3 {
              padding-bottom: 1rem;
            }
          }
        }
      }
      @include phone-only {
        padding: 0rem;
        h4 {
          padding-bottom: 1rem;
        }
      }
    }
  }
  .pagination__container {
    margin-bottom: 5.125rem;
    justify-content: flex-start;
    @include md-screen-down {
      margin-left: 1.5rem;
    }
    @include phone-only {
      margin-left: 1rem;
    }
  }
  .voice-page__banner {
    padding: 2.5rem;
    padding-right: 4rem;
  }
}
