@import '../../../../scss/custom/mixins';
.route-error {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  p:first-child {
    font-size: 240px;
    line-height: 240px;
    color: white;
    text-shadow: -1px -1px 0 var(--primary), 1px -1px 0 var(--primary), -1px 1px 0 var(--primary),
      1px 1px 0 var(--primary);
    margin-bottom: 2.5rem;
  }
  h1 {
    margin-bottom: 1.5rem;
  }
  .tp-2 {
    margin-bottom: 3rem;
    max-width: 518px;
  }
  button {
    padding: 1.25rem 2.5rem;
    border-radius: 1.25rem;
  }

  @include tablet-down {
    padding: 1rem;
    p:first-child {
      font-size: 150px;
      line-height: 150px;
      margin-bottom: 1.5rem;
    }
    h3 {
      margin-bottom: 1rem;
    }
    .tp-4 {
      margin-bottom: 2.5rem;
      max-width: 368px;
    }
    button {
      padding: 1rem 2rem;
    }
  }
}
