.just-ui-progressBar-container {
  .just-ui-progressBar {
    height: 100%;
    width: 33%;
    transform: translateX(-100%);
    background: var(--primary);
    animation-name: justUIprogressBarAnimation;
    animation-timing-function: linear;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    filter: blur(5px);
  }
}

@keyframes justUIprogressBarAnimation {
  0% {
    transform: translateX(-100%);
    background: #2EA9D6;
  }
  16% {
    background: #2EA9D6;
  }
  32% {
    background: #F66D6E;
  }
  49% {
    background: #F5CA09;
    transform: translateX(300%);
  }

  49.5% {
    background: transparent;
    transform: translateX(300%);
  }
  49.75% {
    background: transparent;
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(-100%);
    background: #F5CA09;
  }
  66% {
    background: #F5CA09;
  }
  82% {
    background: #52BC77;
  }
  100% {
    background: #2EA9D6;
    transform: translateX(300%);
  }
}

