@import '../../scss/custom/mixins';

.progress-bar {
  &__container {
    width: 22rem;
    margin-top: -1px;
    position: relative;
    .progress {
      height: 3px;
      border-radius: 2rem;
    }
    .progress-bar {
      border-radius: 2rem;
      &:not(.progress-bar--incoming) {
        margin-right: -1px;
        z-index: 10;
      }
      &--incoming {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background-color: var(--primary-300);
      }
    }
  }
  &__info {
    width: 91%;
    display: flex;
    justify-content: space-between;
    p {
      margin-bottom: 0.375rem;
    }

    @include md-screen-down {
      width: 96%;
    }
    @include tablet-down {
      width: 84%;
    }
    @include phone-only {
      width: 92%;
    }
  }
  &__limiter {
    width: 87%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    height: 7px;
    border-right: 1px solid black;
    bottom: -2px;
    z-index: 11;

    @include md-screen-down {
      width: 88%;
    }
    @include tablet-down {
      width: 80%;
    }
    @include phone-only {
      width: 83%;
    }
  }
}
