.justui-audio-player {
  width: 100%;

  &__custom-button {
    width: 2rem;
    height: 2rem;
    padding: 7px 8px 9px 8px!important;
    display: flex!important;
    align-items: center;
    justify-content: center;

    svg {
      width: 1rem!important;
      height: 1rem!important;
    }

    .svg-inline--fa {
      vertical-align: middle;
    }
  }

  &__custom-button_volume svg,
  &__custom-button_menu svg {
    color: var(--grey);
  }

  &__vol-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__container {
      display: flex;
      align-items: center;
      justify-content: center;
  }
  &__duration {
      white-space: nowrap;
      font-size: 0.75rem;
      margin: 0 0.5rem;
      color: var(--grey);
  }

  &__icon {
    height: 100%;
  }

  &__spinner-wrapper {
    position: relative;
    width: 32px;
    height: 32px;
  }

  .audio-progress-bar {
    position: relative;
    height: 0.25rem;
    width: calc(100% - 0.9375rem);
    background-color: var(--grey-200);
    border-radius: 1px;
    cursor: pointer;
    outline: none;

    &--histogram {
      height: 22px;
    }

    &:focus:not(:focus-visible) {
      outline: 0;
    }

    &__point {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        bottom: 0.625rem;
        height: 1rem;
        width: 0.5rem;
        background-color: var(--grey);
        border-radius: 2px;
        z-index: 1;
        transition: left 100ms linear;
        &--histogram {
          background-color: transparent;
        }
    }


    &__progress {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0.625rem;
        height: 100%;
        background-color: var(--grey-800);
        border-radius: 1px;
        transition: width 100ms linear;
    }
  }

  .progress-mouse-tracker {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .volume-progress-bar {
    position: relative;
    height: 100%;
    width: 0.25rem;
    background-color: var(--grey-200);
    border-radius: 1px;
    cursor: pointer;
    outline: none;

    &.horizontal {
      width: 100%;
      height: 0.25rem;
    }

    &:focus:not(:focus-visible) {
      outline: 0;
    }

    &__point {
        position: absolute;
        left: 50%;
        transform: translate(-50%, calc(-50% + 8px));
        height: 0.5rem;
        width: 1rem;
        background-color: var(--grey);
        border-radius: 2px;
        z-index: 1;
        &.horizontal {
          height: 1rem;
          width: 0.5rem;
          bottom: -150%;
        }
    }

    &__progress {
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: var(--grey-800);
        border-radius: 1px;
        width: 100%;
        &.horizontal {
          height: 100%;
        }
    }
  }

  .volume-container {
    position: absolute;
    height: 116px;
    bottom: 40px;
    left: 2px;
    background-color: #ffffff;
    box-shadow: 0px 0px 1px rgba(17, 60, 92, 0.06), 0px 6px 12px rgba(12, 43, 66, 0.16), 0px 0px 2px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    padding: 0.5rem 0.75rem;
    &.horizontal {
      width: 116px;
      height: unset;
      padding: 0.75rem 0.5rem;
    }
  }

  .hidden {
    display: none;
  }

  .audio-speed {
    &__menu {
      min-width: 100%;
      width: 40px;
      padding: 0;
      margin: 0;

      border-radius: 3px;
      border: none;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(12, 43, 66, 0.16), 0 0 1px rgba(17, 60, 92, 0.06);
    }

    &__value {
      padding: 7px 8px;

      text-align: center;
      color: var(--grey);
      box-shadow: none;

      font-size: 12px;
      line-height: 18px;

      &_current {
        color: var(--primary);
        font-weight: bold;
      }
    }

    &__button {
      padding-left: 13px;
      padding-right: 13px;

      &:hover {
        background-color: var(--black-light);
        border-radius: 0;
      }
    }
  }
}
