@import '../../../../scss/custom/mixins';

.header-navbar.project {
  background-color: #f9f9fb;
  height: 4rem;
  > div {
    padding: 0 0.5rem 0 1.5rem;
  }
  .project {
    &__header-back {
      display: flex;
      align-items: center;
      gap: 16px;
      color: var(--gray-600);
      margin-right: 330px;
      white-space: nowrap;
      transition: 0.2s;
      span {
        display: flex;
      }

      &:hover {
        gap: 20px;
        color: var(--gray-950);
        @include md-screen-up {
          margin-right: 326px;
        }
      }
    }
    &__header-breadcrumbs {
      max-width: 100%;
      display: flex;
      justify-content: center;
      overflow: hidden;
      margin-left: auto;

      ol {
        background-color: #f9f9fb;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-bottom: 0;
        max-width: 100%;
        padding: 0;
      }

      .breadcrumb-item:first-child {
        color: var(--gray-500);
        cursor: default;
      }
      .breadcrumb-item:last-child {
        display: flex;
        align-items: center;
        overflow: hidden;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &::before {
          color: var(--gray-500);
        }
      }
    }

    &__header-input {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-self: center;
      width: 100%;
      margin-left: -20px;
      > div {
        margin: 0 auto;
      }
      input {
        padding: 0.5rem 1.5rem 0.5rem 1rem;
        border-radius: 15px;
        height: 40px;
        font-size: 1rem;
        line-height: 1.5rem;
        background-color: transparent;
      }

      &.error {
        align-self: flex-end;
        p {
          color: var(--primary-red);
        }
        input {
          border-color: var(--primary-red) !important;
          background-color: rgba(255, 70, 58, 0.08);
        }
      }
    }

    &__header-support {
      font-size: 1rem;
      line-height: 1.5rem;
      position: absolute;
      visibility: hidden;
      z-index: -1;
      top: 0;
      left: 0;
      max-width: 100%;
      overflow: hidden;
      white-space: pre;
    }
  }

  .nav {
    margin-left: 32px;
  }

  .project__dropdown {
    &:hover,
    &.show {
      svg {
        transform: translateY(4px);
      }
    }

    .justui_dropdown-toggle {
      display: flex;
      gap: 0.25rem;
      align-items: center;
      padding: 0;
      background-color: transparent !important;
      outline: none !important;
      border: unset !important;
      padding: 0.5rem;

      svg {
        color: var(--gray-950);
        transition: transform 0.2s;
      }
    }
    .justui_dropdown-menu {
      transform: translate3d(-112px, 32px, 0) !important;
    }
  }

  @include md-screen-down {
    height: 3.5rem;
    .project__dropdown {
      &:not(.show):hover {
        svg {
          transform: translateY(0px);
        }
      }
    }
    > div {
      flex-direction: row;
      .header-navbar__wallet-link {
        order: 4;
        margin-left: 1.5rem;
      }

      .project {
        &__header-back {
          margin-right: 2rem;
        }
        &__header-breadcrumbs {
          margin-left: 0;
          p {
            font-size: 0.875rem;
            line-height: 1.25rem;
          }
        }

        &__header-mobile {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(2, 23, 48, 0.28);
          padding: 0.5rem;
          input {
            padding: 10px 1rem 10px 44px;
            height: 40px;
            border-radius: 15px;
            border: 1px solid var(--gray-600);
          }
          .IconButtons-wrap-prepend {
            button {
              margin: 12px 0 12px 12px;
              width: min-content;
              height: min-content;
            }
          }
          &.error {
            p {
              color: var(--primary-red);
            }
            input {
              border-color: var(--primary-red) !important;
            }
          }
        }
      }
      .project__dropdown {
        .justui_dropdown-menu {
          transform: translate3d(-65px, 32px, 0) !important;
        }
      }
    }
  }

  @include tablet-down {
    > div {
      .header-navbar__wallet-link {
        margin-left: 1rem;
        order: 5;
      }
      .project__header-help {
        order: 4;
        margin-left: 2rem;
        span {
          display: flex;
        }
      }
    }
  }

  @include phone-only {
    height: 2.5rem;
    &.offline-header {
      height: 4.5rem;
      > div {
        height: auto;
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
    > div:not(.offline-block--mobile) {
      padding-left: 1rem;
      .header-navbar__wallet-link {
        margin-left: 0.5rem;
      }
      .project {
        &__header-back {
          margin-right: 1rem;
        }

        &__header-breadcrumbs {
          .breadcrumb-item:first-child {
            color: var(--gray-950);
          }
        }
      }
    }
    .offline-block--mobile {
      padding: 6px 0;
      border-top: 1px solid var(--grey-200);
      display: flex;
      justify-content: center;
    }
  }
}
