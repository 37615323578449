.project-page {
  &__presets {
    margin-top: 25px;
    border-top: 1px solid var(--gray-200);
  }
}
.presets {
  &__header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    &-title {
      display: flex;
      gap: 0.625rem;
      align-items: center;
      .justui__icon-wrapper {
        display: flex;
      }
      svg {
        width: 0.75rem;
        height: 0.75rem;
      }
    }
  }
}
