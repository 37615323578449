@import '../../../../../scss/custom/mixins';
.add-manager {
  .custom-modal {
    &__radiogroup {
      p {
        padding-left: 1.5rem;
        color: var(--secondary);
        margin-bottom: 1rem;
      }
      .justui_input-radioButton-label {
        font-weight: 600;
      }
    }
    &__footer {
      button {
        margin: unset;
      }
    }
  }

  @include tablet-down {
    &__header {
      position: relative;
      display: flex;
    }
    .custom-modal {
      &__header {
        .modal-title {
          width: 100%;
        }
        margin-bottom: 2.5rem;
        .close {
          display: flex;
          position: static;
          color: var(--gray-600);
        }
      }
      &__body {
        margin-bottom: 0;
        input {
          border-radius: 18px;
          font-size: 1rem;
          line-height: 1.5rem;
          margin-bottom: 2rem;
        }
      }
      &__footer {
        padding: 1rem 0;
        button {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
      }
    }
  }

  @include phone-only {
    .custom-modal {
      &__body {
        padding: 1rem;
        input {
          margin-bottom: 1.5rem;
        }
      }
      &__header {
        margin-bottom: 0;
      }
      &__footer {
        padding: 0.5rem;
      }
    }
  }
}
