.placeholder-page__container {
  background-color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    margin-top: 70px;
  }
  h6 {
    margin-top: 0.5rem;
    font-weight: normal;
  }
}
