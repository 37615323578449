.justui_input-text {
  &.dark {
    color: var(--grey-100) !important;
    background-color: var(--grey-800) !important;
    border-color: transparent !important;
    border-radius: 4px;

    ::placeholder {
      color: var(--grey-500) !important;
    }

    &:focus {
      box-shadow: 0 0 0 1px var(--indigo-500) !important;
    }

    &:disabled {
      &::placeholder {
        color: var(--grey-700) !important;
      }
    }
  }

  &.error {
    border-color: var(--danger) !important;
  }

  ~ .loadable {
    position: absolute;
    top: 11px;
    right: 10px;

    &.fa-sm {
      top: 8px;
      width: 1em;
      height: 1em;
      font-size: 1em;
    }
    &.fa-lg {
      top: 13px;
      width: 1.25em;
      height: 1.25em;
      font-size: 1.25em;
    }
  }
  & + .clear {
    ~ .loadable {
      right: 36px;
      &.fa-lg {
        right: 44px;
      }
    }
  }
  &.compact {
    height: 1.5rem;
    padding: 2px 0.75rem;
    line-height: 1.25rem;

    & + .clear {
      padding-top: 0;
      padding-bottom: 0;
      height: unset;
      vertical-align: bottom;
    }
  }
  &.blur_inactive {
    background-color: var(--gray-100);
    border: 1px solid var(--gray-200);
    color: var(--gray-500);
  }
  &.form-control {
    color: var(--gray-950);
    &:focus {
      color: var(--gray-950);
    }
  }
  /* FIX: Number type of <Input /> from reactstrap has hidden arrows/spinners for webkit but has no it for Firefox */
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}
